import React, {useEffect} from 'react';
import styled, {ThemeProvider} from "styled-components";
import {Route, Router, Switch} from "react-router-dom";
import history from "./utils/history";
import {dark_theme} from "./themes/dark_theme";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import AppLayout from "./layouts/AppLayout";
import Header from "./components/Nav/Header";
import DashboardHeaderContent from "./components/Nav/DashboardHeaderContent";
import Cash from './pages/Cash';
import TriDotHeaderContent from "./components/Nav/TriDotHeaderContent";
import MainMenu from "./pages/MainMenu";
import Vouchers from "./pages/Vouchers";
import Journal from "./pages/Journal";
import Fuel from "./pages/Fuel";
import Reports from "./pages/Reports";
import Options from "./pages/Options";
import store from "./redux/store";
import {getStationsAction} from "./redux/actions/pageActions";
import {connect} from "react-redux";
import Settings from "./pages/Settings";
import {light_theme} from "./themes/light_theme";
import Registration from "./pages/Registration";
import Status from "./pages/Status";
import DashboardList from "./pages/DashboardList";
import WelcomePage from "./pages/WelcomePage";
import {getUser} from "./redux/actions/loginActions";

const StyledApp = styled.div(({theme}) => ({
    height: "100%",
    background: theme.background
}));

type AppProps = {
    userData?: any,
    loading?: boolean,
    loadingPage?: boolean,
    theme?: string
}

function App({userData, loading, theme, loadingPage}: AppProps) {
    useEffect(() => {
        store.dispatch(getUser(true));
    }, [])

    useEffect(() => {
        store.dispatch(getStationsAction())
        const stationsInterval = setInterval(() => {
            if (userData?.Login)
                store.dispatch(getStationsAction())
        }, 5000);

        return (() => {
            clearInterval(stationsInterval);
        })
    }, [userData])
    return (
        <ThemeProvider theme={theme === "light_theme" ? light_theme : dark_theme}>
            <Router history={history}>
                <Switch>
                    <Route path={"/signup"} exact>
                        <Registration/>
                    </Route>
                    <Route path={"/login"} exact>
                        <LoginPage/>
                    </Route>
                    <React.Fragment>
                        {(!loading && !loadingPage) ? <StyledApp>
                            <Route path={"/"} exact>
                                <DashboardList/>
                            </Route>
                            <Route path={"/dashboard"} exact>
                                <AppLayout Header={<Header back={"/menu"}>
                                    <DashboardHeaderContent/>
                                </Header>}>
                                    <Dashboard/>
                                </AppLayout>
                            </Route>
                            <Route path={"/cash"} exact>
                                <AppLayout Header={<Header back={"/menu"}>
                                    <TriDotHeaderContent title={"Касса"}/>
                                </Header>}>
                                    <Cash/>
                                </AppLayout>
                            </Route>
                            <Route path={"/fuel"} exact>
                                <AppLayout Header={<Header back={"/menu"}>
                                    <TriDotHeaderContent title={"Топливо"}/>
                                </Header>}>
                                    <Fuel/>
                                </AppLayout>
                            </Route>
                            <Route path={"/vouchers"} exact>
                                <AppLayout Header={<Header back={"/menu"}>
                                    <TriDotHeaderContent title={"Купоны на сдачу"}/>
                                </Header>}>
                                    <Vouchers/>
                                </AppLayout>
                            </Route>
                            <Route path={"/journal"} exact>
                                <AppLayout Header={<Header back={"/menu"}>
                                    <TriDotHeaderContent title={"Журнал"}/>
                                </Header>}>
                                    <Journal/>
                                </AppLayout>
                            </Route>
                            <Route path={"/reports"} exact>
                                <AppLayout Header={<Header back={"/menu"}>
                                    <TriDotHeaderContent title={"Отчеты"}/>
                                </Header>}>
                                    <Reports/>
                                </AppLayout>
                            </Route>
                            <Route path={"/options"} exact>
                                <AppLayout Header={<Header back={"/menu"}>
                                    <TriDotHeaderContent title={"Управление"}/>
                                </Header>}>
                                    <Options/>
                                </AppLayout>
                            </Route>
                            <Route path={"/settings"} exact>
                                <AppLayout Header={<Header back={"/menu"}>
                                    <TriDotHeaderContent title={"Настройки"}/>
                                </Header>}>
                                    <Settings/>
                                </AppLayout>
                            </Route>
                            <Route path={"/status"} exact>
                                <AppLayout Header={<Header back={"/menu"}>
                                    <TriDotHeaderContent title={"Статус оборудования"}/>
                                </Header>}>
                                    <Status/>
                                </AppLayout>
                            </Route>
                            <Route path={"/menu"} exact>
                                <MainMenu/>
                            </Route>
                        </StyledApp> : <WelcomePage/>}
                    </React.Fragment>
                </Switch>
            </Router>
        </ThemeProvider>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userData: state.loginReducer.userData,
        loading: state.loginReducer.loading,
        loadingPage: state.pageReducer.loading,
        theme: state.pageReducer.theme
    }
}

export default connect(mapStateToProps, null)(App);