import React, {useState} from 'react';
import styled, {css} from "styled-components";
import {useSelector} from "react-redux";
import {BurgerItem} from "../components/DataView/Burger";
import GreenStatus from "../svg-components/GreenStatus";
import RedStatus from "../svg-components/RedStatus";
import QuestionMarkSvg from "../svg-components/QuestionMarkSvg";

const Hidden = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 20px 20px;
  flex-wrap: wrap;
`;

const Tip = styled.div(({theme: {status}}) => css`
  display: flex;
  width: calc(50% - 10px);
  font-weight: 500;
  font-size: 10px;
  color: ${status.innerBlockColor};
`);

const Top = styled.div(({theme: {status}}) => css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  h1 {
    color: ${status.innerBlockColor};
  }

  svg {
    path {
      fill: ${status.innerBlockColor};
    }
  }

  &.hidden {
    h1 {
      color: #657092;
    }

    svg {
      path {
        fill: #657092;
      }
    }
  }
`);

const Block = styled.div(({theme: {status}}) => css`
  display: flex;
  background: ${status.blockBackground};
  border-radius: 8px;
  padding: 12px;
  flex-wrap: wrap;
  gap: 5px 8px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  h1 {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.01em;
    margin: 0;
  }

  svg {
    margin-right: 6px;
    flex: 0 0 auto;
  }
`);

const StatusItem = styled.div(({theme: {status}}) => css`
  display: flex;
  align-items: center;
  padding: 10px 16px 10px 8px;
  background: ${status.innerBlockBackground};
  border-radius: 8px;
  color: ${status.innerBlockColor};
  width: calc(50% - 4px);
  font-size: 10px;
  word-break: break-word;
  font-weight: 600;
`);

const StyledStatus = styled.div`
  ${BurgerItem} {
    justify-content: flex-start;
  }
`;

function Status() {

    const [tipVisable, setTipVisable] = useState<boolean>(false);

    const Station = useSelector(({
                                     pageReducer: {
                                         stations,
                                         selectedStation
                                     }
                                 }: any) => stations.find((el: any) => el.Id === selectedStation));
    return (
        <StyledStatus>
            <Block>
                {Station?.Status?.Devices?.map((device: any, index: number) => <StatusItem key={index}>{device.Ok ?
                    <GreenStatus/> : <RedStatus/>}{device.Name}
                </StatusItem>)}
            </Block>
            <Block>
                <Top className={tipVisable ? "hidden" : ""} onClick={() => setTipVisable(!tipVisable)}><h1>Обозначение
                    иконок</h1>
                    <QuestionMarkSvg/></Top>
                <Hidden style={{display: tipVisable ? "flex" : "none"}}>
                    <Tip>
                        <GreenStatus/> Эта иконка означает, что все хорошо
                    </Tip>
                    <Tip>
                        <RedStatus/> Эта иконка означает ошибку
                    </Tip>
                </Hidden>
            </Block>
        </StyledStatus>
    );
}

export default Status;