import React from 'react';
import styled from "styled-components";

const StyledAnimatedBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background: url("./Document_files/background.png");
  background-size: cover;
  top: 0;
  z-index: 0;

  .wrapper {
    position: relative;
    width: 375px;
    height: 100vh;
    overflow: hidden;
    margin: 0 auto;
  }

  .rectangle {
    position: absolute;
    width: 375px;
    height: 100vh;

    left: 0;
    top: 0px;
    background: #131723;
  }

  .backgrounds {
    opacity: 1;
  }


  .logo {
    position: absolute;
    width: 242.24px;
    height: 65.29px;
    left: 66px;
    top: 199px;
  }

  .title {
    position: absolute;
    width: 226px;
    height: 50px;
    left: 75px;
    top: 295px;
    text-align: center;
  }

  .box {

    position: absolute;
    width: 588px;
    height: 538px;
    left: -106px;
    bottom: -84px;
  }

  .iskry {
    opacity: 0;
    animation: iskry-in 2s 1 forwards;
    animation-delay: 2s;
  }

  @keyframes iskry-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }

  .iskry-1 {

    position: absolute;
    left: 39.82%;
    right: 51.3%;
    top: 5.84%;
    bottom: 74.93%;
    animation: iskry-1 2s infinite forwards linear;
  }

  @keyframes iskry-1 {
    0% {
      top: 20%;
      opacity: 0;
    }
    50% {
      opacity: 100%;
      top: 5.84%;
    }
    100% {
      top: -10%;
      opacity: 0;
    }
  }

  .iskry-2 {
    position: absolute;
    left: 49.09%;
    right: 42.04%;
    top: 23.57%;
    bottom: 57.2%;
    animation: iskry-2 1.5s infinite forwards linear;
    /*animation-delay: 1s;*/
  }

  @keyframes iskry-2 {
    0% {
      top: 38%;
      opacity: 0;
    }
    50% {
      opacity: 100%;
      top: 23.57%%;
    }
    100% {
      top: 9%;
      opacity: 0;
    }
  }

  .iskry-3 {
    position: absolute;
    left: 34.39%;
    right: 56.45%;
    top: 39.14%;
    bottom: 41.3%;
    animation: iskry-3 1.7s infinite forwards linear;
    /*animation-delay: 1s;*/
  }

  @keyframes iskry-3 {
    0% {
      top: 50%;
      opacity: 0;
    }
    50% {
      opacity: 100%;
      top: 39.14%%;
    }
    100% {
      top: 28%;
      opacity: 0;
    }
  }

  .iskry-4 {
    position: absolute;
    left: 45.51%;
    right: 33.73%;
    top: 48.83%;
    bottom: 30.78%;
    animation: iskry-4 1.3s infinite forwards linear;
    /*animation-delay: 1s;*/
  }

  @keyframes iskry-4 {
    0% {
      top: 60%;
      opacity: 0;
    }
    50% {
      opacity: 100%;
      top: 48.83%%;
    }
    100% {
      top: 36%;
      opacity: 0;
    }
  }

  .iskry-5 {
    position: absolute;
    left: 28.45%;
    right: 32.6%;
    top: 59.09%;
    bottom: 27.96%;
    animation: iskry-5 2.4s infinite forwards linear;
    /*animation-delay: 1s;*/
  }

  @keyframes iskry-5 {
    0% {
      top: 59.09%;
      opacity: 0;
      width: 200px;
      left: 28.45;
    }
    50% {
      opacity: 100%;
      top: 59.09%%;
    }
    100% {
      top: 30%;
      opacity: 0;
      width: 100px;
      left: 40%;
    }
  }

  .light-1 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: 36.33%;
    right: 35.28%;
    top: 1.03%;
    bottom: 17.5%;

  }

  .light-1 img {
    display: block;
    animation: light-1 2s infinite alternate;
    animation-delay: 1s;
    opacity: 0;
  }

  @keyframes light-1 {
    0% {
      opacity: 0;
      height: 0;
      width: 0;
      padding-bottom: 80px;
    }
    100% {
      opacity: 100%;
      height: 100%;
      width: 100%;
      padding-bottom: 0;
    }
  }


  .light-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 36.33%;
    right: 35.28%;
    top: 62.19%;
    bottom: 17.5%;
  }

  .light-2 img {
    display: block;
    opacity: 0;
    animation: light-2 2s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes light-2 {
    0% {
      opacity: 0;
      width: 0;
    }
    100% {
      opacity: 1;
      width: 166.94px;
    }
  }


  .light-3 {
    position: absolute;
    left: 36.33%;
    right: 35.28%;
    top: 62.19%;
    bottom: 17.5%;
    opacity: 0;
    animation: light-3 2s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes light-3 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .light-4 {
    position: absolute;
    left: 33.78%;
    right: 32.74%;
    top: 61.33%;
    bottom: 16.64%;

    opacity: 0;
    animation: light-4 2s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes light-4 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.6;
    }
  }

  .light-5 {
    position: absolute;
    left: 33.78%;
    right: 32.74%;
    top: 61.33%;
    bottom: 16.64%;

    opacity: 0;

    animation: light-5 2s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes light-5 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.6;
    }
  }

  .light-6 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: 26.73%;
    right: 25.69%;
    top: -1.11%;
    bottom: 13.44%;
  }

  .light-6 img {
    display: block;
    animation: backlight 2s infinite alternate;
    animation-delay: 1s;
    opacity: 0;
  }

  @keyframes backlight {
    0% {
      opacity: 0;
      /*height: 0;*/
      /*padding-bottom: 80px;*/
    }
    25% {
      opacity: 0;
      /*height: 0;*/
      /*padding-bottom: 80px;*/
    }
    100% {
      opacity: 100%;
      /*height: 471.67px;*/
      /*padding-bottom: 0;*/
    }
  }


  .ring-1 {
    position: absolute;
    left: 26.73%;
    right: 25.69%;
    top: 59.84%;
    bottom: 13.44%;
    opacity: 0;

    animation: ring-1 1.5s 1 forwards;
  }

  @keyframes ring-1 {
    0% {
      top: 20%;
      opacity: 0;
    }
    75% {
      opacity: 0.4;
    }
    100% {
      top: 59.84%;
      opacity: 1;
    }
  }

  .ring-2 {
    position: absolute;
    left: 18%;
    right: 7.28%;
    top: 55.99%;
    bottom: 5.31%;
    opacity: 0;

    animation: ring-2 2s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes ring-2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.7;
    }
  }

  .ring-3 {
    position: absolute;
    left: 18%;
    right: 13.94%;
    top: 57.27%;
    bottom: 7.02%;
    opacity: 0;

    animation: ring-3 2s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes ring-3 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .ring-4 {
    position: absolute;
    left: 18%;
    right: 5.71%;
    top: 56.41%;
    bottom: 1.25%;

    opacity: 0;
    animation: ring-4 2s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes ring-4 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.7;
    }
  }

  .ring-5 {
    position: absolute;
    left: 29.87%;
    right: 28.82%;
    top: 60.26%;
    bottom: 15.15%;
    opacity: 0;
    animation: ring-5 2.5s 1 forwards;
    /*animation-delay: 2s;*/
  }

  @keyframes ring-5 {
    0% {
      top: 0%;
      opacity: 0;
    }
    75% {
      opacity: 0.4;
    }
    100% {
      top: 60.26%;
      opacity: 1;
    }
  }

  .ring-6 {
    position: absolute;
    left: 32.12%;
    right: 30.97%;
    top: 67.53%;
    bottom: 20%;
    opacity: 0;
    animation: ring-6 3s 1 forwards;
    /*animation-delay: 1s;*/
  }

  @keyframes ring-6 {
    0% {
      top: -50%;
      opacity: 0;
    }
    75% {
      opacity: 0.4;
    }
    100% {
      top: 67.53%;
      opacity: 1;
    }
  }

  .ring-7 {
    position: absolute;
    left: 24.97%;
    right: 23.92%;
    top: 59.84%;
    bottom: 12.15%;
    opacity: 0;
    animation: ring-7 1.5s 1 forwards;
    /*animation-delay: 1s;*/
  }

  @keyframes ring-7 {
    0% {
      top: -50%;
      opacity: 0;
    }
    75% {
      opacity: 0.4;
    }
    100% {
      top: 59.84%;
      opacity: 1;
    }
  }

  .ring-8 {
    position: absolute;
    left: 24.97%;
    right: 23.92%;
    top: 59.84%;
    bottom: 12.15%;
    opacity: 0;
    animation: ring-8 1.8s 1 forwards;
    /*animation-delay: 1s;*/
  }

  @keyframes ring-8 {
    0% {
      top: -50%;
      opacity: 0;
    }
    75% {
      opacity: 0.4;
    }
    100% {
      top: 59.84%;
      opacity: 1;
    }
  }

  .ring-9 {
    position: absolute;
    left: 18%;
    right: -1.53%;
    top: 55.99%;
    bottom: -1.11%;

    opacity: 0.3;
    animation: ring-9 2s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes ring-9 {
    0% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.7;
    }
  }


  .electric {
    position: absolute;
    left: 19.88%;
    right: 23.73%;
    top: 10.66%;
    bottom: 33.11%;
  }

  .electric-glow-2, .electric-glow-1 {
    position: absolute;
    left: 19.88%;
    right: 23.73%;
    top: 10.66%;
    bottom: 33.11%;
  }

  .pins {
    position: absolute;
    left: 27.01%;
    right: 30.84%;
    top: 18.46%;
    bottom: 40.95%;
  }

  .pin-1 {
    position: absolute;
    left: 55.77%;
    right: 30.84%;
    top: 34.26%;
    bottom: 40.95%;

    /*background: linear-gradient(0deg, rgba(35, 120, 253, 0) 0.07%, #2378FD 67.06%, #23CCFD 100%);*/
  }

  .pin-2 {
    position: absolute;
    left: 27.01%;
    right: 61.28%;
    top: 38.82%;
    bottom: 40.98%;

    /*background: linear-gradient(0deg, rgba(35, 120, 253, 0) -0.02%, #2378FD 67.04%, #23CCFD 100.02%);*/
  }

  .pin-3 {
    position: absolute;
    left: 50.5%;
    right: 46.56%;
    top: 18.95%;
    bottom: 63.24%;

    /*background: linear-gradient(0deg, rgba(35, 120, 253, 0) -0.01%, #2378FD 67.04%, #23CCFD 100.02%);*/
  }

  .pin-4 {
    position: absolute;
    left: 58.63%;
    right: 34.81%;
    top: 45.25%;
    bottom: 40.98%;

    /*background: linear-gradient(0deg, rgba(35, 120, 253, 0) -0.03%, #2378FD 67.05%, #23CCFD 100.04%);*/
  }

  .pin-5 {
    position: absolute;
    left: 51.15%;
    right: 45.52%;
    top: 31.14%;
    bottom: 40.98%;

    /*background: linear-gradient(0deg, rgba(35, 120, 253, 0) -0.01%, #2378FD 67.01%, #23CCFD 99.98%);*/
  }

  .pin-6 {
    position: absolute;
    left: 53.46%;
    right: 39.98%;
    top: 25.22%;
    bottom: 40.96%;

    /*background: linear-gradient(0deg, rgba(35, 120, 253, 0) 0.05%, #2378FD 67.04%, #23CCFD 99.98%);*/
  }

  .pin-7 {
    position: absolute;
    left: 37.87%;
    right: 57.97%;
    top: 23.78%;
    bottom: 54.94%;

    /*background: linear-gradient(0deg, rgba(35, 120, 253, 0) -0.08%, #2378FD 66.95%, #23CCFD 99.93%);*/
  }

  .pin-8 {
    position: absolute;
    left: 42.63%;
    right: 53.2%;
    top: 18.46%;
    bottom: 62.38%;

    /*background: linear-gradient(0deg, rgba(35, 120, 253, 0) 0.01%, #2378FD 66.98%, #23CCFD 99.92%);*/
  }

  .pin-9 {
    position: absolute;
    left: 45.78%;
    right: 51.24%;
    top: 26.22%;
    bottom: 40.98%;

    /*background: linear-gradient(0deg, rgba(35, 120, 253, 0) -0.01%, #2378FD 67.01%, #23CCFD 99.98%);*/
  }

  .pin-10 {
    position: absolute;
    left: 40.24%;
    right: 53.65%;
    top: 25.22%;
    bottom: 40.98%;

    /*background: linear-gradient(0deg, rgba(35, 120, 253, 0) -0.01%, #2378FD 67.02%, #23CCFD 99.98%);*/
  }

  .current-1 {
    position: absolute;
    left: 55.44%;
    right: 43.88%;
    top: 59.67%;
    bottom: 39.59%;

    background: radial-gradient(50% 50% at 50% 50%, #9CFFFF 0%, rgba(217, 217, 217, 0) 100%);
  }
`;

function AnimatedBackground() {
    return (
        <StyledAnimatedBackground>
            <div className="wrapper">

                <div className="box">

                    <div className="light-6">
                        <img alt={"svg"} src="./Document_files/light-6.png"/>
                    </div>


                    <img alt={"svg"} src="./Document_files/ring-1.svg" className="ring-1"/>
                    <img alt={"svg"} src="./Document_files/ring-2.svg" className="ring-2"/>
                    <img alt={"svg"} src="./Document_files/ring-3.svg" className="ring-3"/>
                    <img alt={"svg"} src="./Document_files/ring-4.svg" className="ring-4"/>
                    <img alt={"svg"} src="./Document_files/ring-5.svg" className="ring-5"/>
                    <img alt={"svg"} src="./Document_files/ring-6.svg" className="ring-6"/>
                    <img alt={"svg"} src="./Document_files/ring-7.png" className="ring-7"/>
                    <img alt={"svg"} src="./Document_files/ring-8.png" className="ring-8"/>
                    <img alt={"svg"} src="./Document_files/ring-9.svg" className="ring-9"/>


                    <img alt={"svg"} src="./Document_files/glow-1.png"
                         className="electric-glow-2"/>
                    <img alt={"svg"} src="./Document_files/glow-2.png"
                         className="electric-glow-1"/>


                    <svg width="249" height="219" viewBox="0 0 249 219"
                         fill="none" xmlns="http://www.w3.org/2000/svg"
                         className="pins">
                        <path
                            d="M82.7752 44.4902C83.6963 44.3752 84.6173 44.0301 85.3082 43.3398C85.999 42.6496 86.4595 41.6142 86.4595 40.5789C86.4595 39.5435 85.999 38.5081 85.3082 37.8179C84.6173 37.1276 83.5811 36.6675 82.5449 36.6675C81.5087 36.6675 80.4725 37.1276 79.7817 37.8179C79.0909 38.5081 78.6304 39.5435 78.6304 40.5789C78.6304 41.6142 79.0909 42.6496 79.7817 43.3398C80.4725 44.0301 81.3936 44.3752 82.3147 44.4902V92.3472L114.092 124.444V218.547H114.552V124.328L82.7752 92.2321V44.4902V44.4902ZM80.1271 42.9947C79.4363 42.3045 79.0909 41.4992 79.0909 40.5789C79.0909 40.5789 79.0909 40.5789 79.0909 40.4638C79.0909 39.5435 79.4363 38.7382 80.1271 38.163C80.8179 37.4728 81.6239 37.1276 82.5449 37.1276C83.466 37.1276 84.2719 37.4728 84.9627 38.163C85.6536 38.8533 85.9989 39.6585 85.9989 40.5789C85.9989 41.4992 85.6536 42.3045 84.9627 42.9947C84.3871 43.5699 83.5811 43.915 82.7752 43.915V43.8H82.3147V43.915C81.5087 43.915 80.7028 43.5699 80.1271 42.9947Z"
                            fill="url(#paint0_linear_1220_787)"></path>
                        <path
                            d="M115.358 49.8975C116.279 49.7824 117.2 49.4373 117.891 48.7471C118.582 48.0568 119.043 47.0215 119.043 45.9861C119.043 44.9507 118.582 43.9154 117.891 43.2251C117.2 42.5349 116.164 42.0747 115.128 42.0747H115.013C113.977 42.0747 113.171 42.5349 112.365 43.2251C111.674 43.9154 111.213 44.9507 111.213 45.9861C111.213 47.0215 111.674 48.0568 112.365 48.7471C113.056 49.4373 113.977 49.7824 114.898 49.8975L114.552 77.8524L128.253 91.5422V218.547H128.714V91.4272L115.013 77.7373L115.358 49.8975ZM112.71 48.4019C112.019 47.7117 111.674 46.9064 111.674 45.9861C111.674 45.0658 112.019 44.2605 112.71 43.5702C113.401 42.88 114.207 42.5349 115.128 42.5349C115.243 42.5349 115.243 42.5349 115.243 42.5349C116.049 42.5349 116.855 42.88 117.431 43.4552L117.546 43.5702C118.237 44.2605 118.582 45.0658 118.582 45.9861V46.1011C118.582 47.0215 118.237 47.8267 117.546 48.4019C116.97 48.9771 116.164 49.3223 115.358 49.4373C114.322 49.4373 113.401 49.0922 112.71 48.4019Z"
                            fill="url(#paint1_linear_1220_787)"></path>
                        <path
                            d="M110.753 96.604L96.8216 82.6841L96.7065 8.13771C97.7427 8.13771 98.6637 7.67755 99.3545 6.98731C100.045 6.29706 100.506 5.26169 100.506 4.22633C100.506 3.306 100.16 2.38567 99.5848 1.69543C99.4697 1.58039 99.4697 1.58039 99.3545 1.46535C98.6637 0.775104 97.6275 0.314941 96.5913 0.314941C95.5551 0.314941 94.634 0.775104 93.8281 1.46535C93.1373 2.15559 92.6768 3.19096 92.6768 4.22633C92.6768 5.26169 93.1373 6.29706 93.8281 6.98731C93.8281 6.98731 93.8281 6.9873 93.9432 7.10234C94.634 7.67755 95.44 8.02267 96.2459 8.13771L96.361 82.7991V82.9142L110.407 96.9491C109.832 97.6394 109.371 98.5597 109.371 99.48C109.371 100.515 109.832 101.551 110.523 102.241C111.328 103.046 112.25 103.391 113.286 103.391C114.322 103.391 115.243 103.046 116.049 102.241C116.855 101.436 117.2 100.515 117.2 99.48C117.2 98.4447 116.855 97.5243 116.049 96.7191C114.552 95.2235 112.25 95.2235 110.753 96.604ZM93.1373 4.34137V4.22633C93.1373 3.42104 93.4827 2.73079 93.9432 2.15559C93.9432 2.04055 94.0584 2.04055 94.1735 1.92551C94.8643 1.23527 95.6703 0.890145 96.5913 0.890145C97.5124 0.890145 98.3183 1.23527 99.0091 1.92551C99.6999 2.61576 100.045 3.42104 100.045 4.34137C100.045 5.26169 99.6999 6.06698 99.0091 6.75722C98.3183 7.44746 97.5124 7.79259 96.5913 7.79259C95.6703 7.79259 94.8643 7.44746 94.1735 6.75722C93.4827 6.06698 93.1373 5.26169 93.1373 4.34137ZM116.625 99.48C116.625 100.4 116.279 101.206 115.588 101.896C115.128 102.356 114.667 102.586 114.092 102.701C112.94 103.046 111.674 102.701 110.753 101.781C109.486 100.515 109.371 98.3296 110.753 96.9491C112.134 95.5686 114.207 95.5686 115.588 96.9491C116.394 97.7544 116.625 98.5597 116.625 99.48Z"
                            fill="url(#paint2_linear_1220_787)"></path>
                        <path
                            d="M69.7651 100.171V36.7832C70.6862 36.6682 71.6073 36.323 72.2981 35.6328C72.9889 34.9426 73.4494 33.9072 73.4494 32.8718C73.4494 31.8365 72.9889 30.8011 72.2981 30.1109C71.6073 29.4206 70.5711 28.9604 69.5349 28.9604C68.4987 28.9604 67.4624 29.4206 66.7716 30.1109C66.0808 30.8011 65.6203 31.8365 65.6203 32.8718C65.6203 33.9072 66.0808 34.9426 66.7716 35.6328C67.4624 36.323 68.3835 36.6682 69.3046 36.7832V100.286L88.4168 119.382L71.1467 136.639C70.4559 136.063 69.5349 135.603 68.6138 135.603C67.5776 135.603 66.5414 136.063 65.8506 136.754C65.0446 137.559 64.6992 138.479 64.6992 139.515C64.6992 140.55 65.0446 141.47 65.8506 142.276C66.6565 143.081 67.5776 143.426 68.6138 143.426C69.65 143.426 70.5711 143.081 71.377 142.276C72.1829 141.47 72.5283 140.55 72.5283 139.515C72.5283 138.594 72.1829 137.674 71.4921 136.984L89.1077 119.382L69.7651 100.171ZM66.0808 32.9869C66.0808 32.0665 66.4262 31.2613 67.117 30.571C67.8078 29.8808 68.6138 29.5357 69.5349 29.5357C70.4559 29.5357 71.2619 29.8808 71.9527 30.571C72.6435 31.2613 72.9889 32.0665 72.9889 32.9869C72.9889 33.9072 72.6435 34.7125 71.9527 35.4027C71.2619 36.093 70.4559 36.4381 69.5349 36.4381C68.6138 36.4381 67.8078 36.093 67.117 35.4027C66.4262 34.7125 66.0808 33.9072 66.0808 32.9869ZM70.9165 142.045C69.5349 143.426 67.4625 143.426 66.0808 142.045C65.39 141.355 65.0446 140.55 65.0446 139.63C65.0446 138.709 65.39 137.904 66.0808 137.214C66.7716 136.524 67.5776 136.178 68.4986 136.178C69.4197 136.178 70.2257 136.524 70.9165 137.214C71.6073 137.904 71.9527 138.709 71.9527 139.63C71.9527 140.55 71.6073 141.355 70.9165 142.045Z"
                            fill="url(#paint3_linear_1220_787)"></path>
                        <path
                            d="M191.232 102.241V44.4902C192.153 44.3752 193.074 44.0301 193.765 43.3398C194.456 42.6496 194.916 41.6142 194.916 40.5789C194.916 39.5435 194.456 38.5081 193.765 37.8179C193.074 37.1276 192.038 36.6675 191.002 36.6675C189.965 36.6675 188.929 37.1276 188.238 37.8179C187.548 38.5081 187.087 39.5435 187.087 40.5789C187.087 41.6142 187.548 42.6496 188.238 43.3398L188.353 43.4549C189.044 44.0301 189.85 44.3752 190.771 44.4902V102.011L156.461 123.638L156.346 123.753V218.662H156.807V123.983L191.232 102.241ZM188.469 42.9947C187.778 42.3045 187.432 41.4992 187.432 40.5789C187.432 39.6585 187.778 38.8533 188.469 38.163C189.159 37.4728 189.965 37.1276 190.886 37.1276C191.807 37.1276 192.613 37.4728 193.304 38.163C193.995 38.8533 194.34 39.6585 194.34 40.5789C194.34 41.4992 193.995 42.3045 193.304 42.9947C192.729 43.5699 191.923 43.915 191.117 43.915V43.8H190.656V43.915C189.85 43.915 189.159 43.5699 188.469 42.9947Z"
                            fill="url(#paint4_linear_1220_787)"></path>
                        <path
                            d="M158.994 104.312L158.649 76.357C159.57 76.2419 160.491 75.8968 161.182 75.2065C161.873 74.5163 162.333 73.4809 162.333 72.4456C162.333 71.4102 161.873 70.3748 161.182 69.6846C160.491 68.9943 159.455 68.5342 158.418 68.5342C158.303 68.5342 158.303 68.5342 158.303 68.5342C157.382 68.5342 156.461 68.9943 155.77 69.6846C155.08 70.3748 154.619 71.4102 154.619 72.4456C154.619 73.4809 155.08 74.5163 155.77 75.2065C156.461 75.8968 157.382 76.2419 158.303 76.357L158.649 104.082L142.875 114.781L142.76 114.896V218.547H143.221V115.126L158.994 104.312ZM154.964 72.4456C154.964 71.5252 155.31 70.72 156.001 70.0297C156.691 69.3395 157.497 68.9943 158.418 68.9943C159.34 68.9943 160.145 69.3395 160.836 70.0297C161.527 70.72 161.872 71.5252 161.872 72.4456C161.872 73.3659 161.527 74.1712 160.836 74.8614C160.145 75.5517 159.34 75.8968 158.418 75.8968C157.497 75.8968 156.691 75.5517 156.001 74.8614C155.31 74.1712 154.964 73.3659 154.964 72.4456Z"
                            fill="url(#paint5_linear_1220_787)"></path>
                        <path
                            d="M221.512 144.461C221.397 144.461 221.397 144.461 221.512 144.461C220.476 144.461 219.439 144.921 218.749 145.611C218.058 146.302 217.597 147.337 217.597 148.372C217.597 149.408 218.058 150.443 218.749 151.133L218.864 151.248C219.554 151.824 220.36 152.169 221.281 152.284V170.575L186.856 192.088L186.741 192.203V218.547H187.202V192.433L221.512 170.805L221.627 170.69V152.169C222.548 152.054 223.469 151.708 224.16 151.018C224.851 150.328 225.311 149.293 225.311 148.257C225.311 147.222 224.851 146.187 224.16 145.496C223.469 144.806 222.548 144.461 221.512 144.461ZM223.814 150.788C223.239 151.363 222.433 151.709 221.627 151.709V151.593H221.166V151.709C220.36 151.593 219.554 151.363 218.979 150.788C218.288 150.098 217.943 149.293 217.943 148.372C217.943 147.452 218.288 146.647 218.979 145.956C219.67 145.266 220.476 144.921 221.397 144.921C222.318 144.921 223.124 145.266 223.814 145.956C224.505 146.647 224.851 147.452 224.851 148.372C224.851 149.293 224.505 150.098 223.814 150.788Z"
                            fill="url(#paint6_linear_1220_787)"></path>
                        <path
                            d="M143.105 98.7899C143.911 98.7899 144.717 98.4447 145.408 97.8695C145.523 97.7545 145.523 97.7545 145.638 97.6394C145.984 97.2943 146.214 96.9492 146.444 96.6041C146.674 96.1439 146.79 95.5687 146.79 94.8785C146.79 93.8431 146.329 92.8077 145.638 92.1175C144.947 91.4272 143.911 90.9671 142.875 90.9671H142.76V16.6508L149.668 9.74833C150.359 10.4386 151.395 10.7837 152.316 10.7837C153.352 10.7837 154.273 10.4386 155.079 9.63329C155.77 8.94305 156.231 7.90768 156.231 6.87231C156.231 5.83695 155.77 4.80159 155.079 4.11134C154.388 3.4211 153.352 2.96094 152.316 2.96094C151.28 2.96094 150.244 3.4211 149.553 4.11134C148.862 4.80159 148.401 5.83695 148.401 6.87231C148.401 7.79264 148.747 8.71297 149.323 9.40322L142.299 16.4207V91.0821C141.493 91.1971 140.687 91.5423 140.112 92.1175C139.421 92.8077 138.96 93.8431 138.96 94.8785C138.96 95.9138 139.421 96.9492 140.112 97.6394C140.803 98.3297 141.839 98.7899 142.875 98.7899C142.99 98.7899 143.105 98.7899 143.105 98.7899ZM149.898 4.45646C150.589 3.76622 151.395 3.42109 152.316 3.42109C153.237 3.42109 154.043 3.76622 154.734 4.45646C155.425 5.14671 155.77 5.95199 155.77 6.87231C155.77 7.79264 155.425 8.59793 154.734 9.28818C153.467 10.5536 151.28 10.6687 149.898 9.28818L149.783 9.17313C149.207 8.48288 148.862 7.6776 148.862 6.75728C148.977 5.95199 149.323 5.03166 149.898 4.45646ZM139.536 94.8785V94.7634C139.536 93.9581 139.882 93.2679 140.342 92.6927C140.342 92.5776 140.457 92.5777 140.572 92.4626C141.148 91.8874 141.724 91.6573 142.53 91.5423H142.875H142.99C143.911 91.5423 144.717 91.8874 145.408 92.5776C146.099 93.2679 146.444 94.0732 146.444 94.9935C146.444 95.5687 146.329 96.0289 146.099 96.489C145.984 96.8342 145.753 97.1793 145.408 97.4093C144.717 98.0996 143.911 98.4447 142.99 98.4447C142.069 98.4447 141.263 98.0996 140.572 97.4093C139.882 96.7191 139.536 95.7988 139.536 94.8785Z"
                            fill="url(#paint7_linear_1220_787)"></path>
                        <path
                            d="M69.65 178.283L7.59273 116.276C8.1684 115.586 8.62893 114.666 8.62893 113.745C8.62893 112.71 8.16839 111.675 7.47759 110.984C6.78678 110.294 5.75058 109.834 4.71437 109.834C3.67817 109.834 2.64195 110.294 1.95115 110.984C1.26034 111.675 0.799805 112.71 0.799805 113.745C0.799805 114.781 1.26034 115.701 1.95115 116.506C2.64195 117.197 3.67817 117.657 4.71437 117.657C5.63545 117.657 6.55652 117.312 7.24732 116.621L69.1895 178.513V218.547H69.65V178.398H69.4197L69.65 178.283ZM2.29655 116.161C1.60575 115.471 1.26035 114.666 1.26035 113.745C1.26035 112.825 1.60575 112.02 2.29655 111.33C2.98736 110.639 3.7933 110.294 4.71437 110.294C5.63545 110.294 6.44138 110.639 7.13218 111.33C7.82299 112.02 8.1684 112.825 8.1684 113.745C8.1684 114.666 7.82299 115.471 7.13218 116.161C6.44138 116.851 5.63545 117.197 4.71437 117.197C3.7933 117.082 2.98736 116.736 2.29655 116.161Z"
                            fill="url(#paint8_linear_1220_787)"></path>
                        <path
                            d="M247.417 86.4805C246.611 85.6752 245.575 85.3301 244.539 85.3301C243.617 85.3301 242.696 85.7902 242.006 86.4805C241.315 87.1707 240.854 88.2061 240.854 89.2415C240.854 90.1618 241.2 91.0821 241.775 91.7724L170.047 134.222L170.162 134.452H169.932V218.662H170.392V134.568L242.121 92.1175C242.812 92.8077 243.733 93.1528 244.769 93.1528C245.805 93.1528 246.841 92.6927 247.532 92.0024L247.647 91.8874C248.338 91.1972 248.683 90.2768 248.683 89.2415C248.568 88.2061 248.108 87.1707 247.417 86.4805ZM247.071 91.6573C246.381 92.3476 245.575 92.6927 244.654 92.6927C243.733 92.6927 242.927 92.3476 242.236 91.6573C241.545 90.9671 241.2 90.1618 241.2 89.2415C241.2 88.3211 241.545 87.5159 242.236 86.8256C242.927 86.1354 243.733 85.7903 244.654 85.7903C245.575 85.7903 246.381 86.1354 247.071 86.8256C247.762 87.5159 248.108 88.3211 248.108 89.2415C248.108 90.1618 247.762 90.9671 247.071 91.6573Z"
                            fill="url(#paint9_linear_1220_787)"></path>
                        <defs>
                            <linearGradient id="paint0_linear_1220_787"
                                            x1="96.6569" y1="218.566"
                                            x2="96.6569" y2="36.6983"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#1A3E61"
                                      stopOpacity="0.25">
                                </stop>
                                <stop offset="-0.4" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="-0.4.0.8;1" dur="1s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="-0.2" stopColor="#23CCFD">
                                    <animate attributeName="offset"
                                             values="-0.2;1;1.2" dur="1s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="0" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="0;1.2;1.4" dur="1s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="1" stopColor="#1A3E61">
                                </stop>
                            </linearGradient>
                            <linearGradient id="paint1_linear_1220_787"
                                            x1="119.979" y1="218.567"
                                            x2="119.979" y2="42.1121"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#1A3E61"
                                      stopOpacity="0.25">
                                </stop>
                                <stop offset="-0.4" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="-0.4.0.8;1" dur="1.2s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="-0.2" stopColor="#23CCFD">
                                    <animate attributeName="offset"
                                             values="-0.2;1;1.2" dur="1.2s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="0" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="0;1.2;1.4" dur="1.2s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="1" stopColor="#1A3E61">
                                </stop>
                            </linearGradient>
                            <linearGradient id="paint2_linear_1220_787"
                                            x1="104.904" y1="103.384"
                                            x2="104.904" y2="0.394437"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#1A3E61"
                                      stopOpacity="0.25">
                                </stop>
                                <stop offset="-0.4" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="-0.4.0.8;1" dur="1.4s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="-0.2" stopColor="#23CCFD">
                                    <animate attributeName="offset"
                                             values="-0.2;1;1.2" dur="1.4s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="0" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="0;1.2;1.4" dur="1.4s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="1" stopColor="#1A3E61">
                                </stop>
                            </linearGradient>
                            <linearGradient id="paint3_linear_1220_787"
                                            x1="76.8253" y1="143.523"
                                            x2="76.8253" y2="29.0437"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#1A3E61"
                                      stopOpacity="0.25">
                                </stop>
                                <stop offset="-0.4" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="-0.4.0.8;1" dur="1.5s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="-0.2" stopColor="#23CCFD">
                                    <animate attributeName="offset"
                                             values="-0.2;1;1.2" dur="1.5s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="0" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="0;1.2;1.4" dur="1.5s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="1" stopColor="#1A3E61">
                                </stop>
                            </linearGradient>
                            <linearGradient id="paint4_linear_1220_787"
                                            x1="175.541" y1="218.566"
                                            x2="175.541" y2="36.6983"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#1A3E61"
                                      stopOpacity="0.25">
                                </stop>
                                <stop offset="-0.4" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="-0.4.0.8;1" dur="1.4s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="-0.2" stopColor="#23CCFD">
                                    <animate attributeName="offset"
                                             values="-0.2;1;1.2" dur="1.4s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="0" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="0;1.2;1.4" dur="1.4s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="1" stopColor="#1A3E61">
                                </stop>
                            </linearGradient>
                            <linearGradient id="paint5_linear_1220_787"
                                            x1="152.473" y1="218.567"
                                            x2="152.473" y2="68.561"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#1A3E61"
                                      stopOpacity="0.25">
                                </stop>
                                <stop offset="-0.4" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="-0.4.0.8;1" dur="1.3s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="-0.2" stopColor="#23CCFD">
                                    <animate attributeName="offset"
                                             values="-0.2;1;1.2" dur="1.3s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="0" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="0;1.2;1.4" dur="1.3s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="1" stopColor="#1A3E61">
                                </stop>
                            </linearGradient>
                            <linearGradient id="paint6_linear_1220_787"
                                            x1="206.063" y1="218.567"
                                            x2="206.063" y2="144.431"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#1A3E61"
                                      stopOpacity="0.25">
                                </stop>
                                <stop offset="-0.4" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="-0.4.0.8;1" dur="1.2s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="-0.2" stopColor="#23CCFD">
                                    <animate attributeName="offset"
                                             values="-0.2;1;1.2" dur="1.2s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="0" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="0;1.2;1.4" dur="1.2s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="1" stopColor="#1A3E61">
                                </stop>
                            </linearGradient>
                            <linearGradient id="paint7_linear_1220_787"
                                            x1="147.639" y1="98.7949"
                                            x2="147.639" y2="2.94242"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#1A3E61"
                                      stopOpacity="0.25">
                                </stop>
                                <stop offset="-0.4" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="-0.4.0.8;1" dur="1.1s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="-0.2" stopColor="#23CCFD">
                                    <animate attributeName="offset"
                                             values="-0.2;1;1.2" dur="1.1s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="0" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="0;1.2;1.4" dur="1.1s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="1" stopColor="#1A3E61">
                                </stop>
                            </linearGradient>
                            <linearGradient id="paint8_linear_1220_787"
                                            x1="35.2811" y1="218.567"
                                            x2="35.2811" y2="109.811"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#1A3E61"
                                      stopOpacity="0.25">
                                </stop>
                                <stop offset="-0.4" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="-0.4.0.8;1" dur="1s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="-0.2" stopColor="#23CCFD">
                                    <animate attributeName="offset"
                                             values="-0.2;1;1.2" dur="1s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="0" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="0;1.2;1.4" dur="1s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="1" stopColor="#1A3E61">
                                </stop>
                            </linearGradient>
                            <linearGradient id="paint9_linear_1220_787"
                                            x1="209.185" y1="218.567"
                                            x2="209.185" y2="85.3239"
                                            gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#1A3E61"
                                      stopOpacity="0.25">
                                </stop>
                                <stop offset="-0.4" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="-0.4.0.8;1" dur="1.3s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="-0.2" stopColor="#23CCFD">
                                    <animate attributeName="offset"
                                             values="-0.2;1;1.2" dur="1.3s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="0" stopColor="#2378FD">
                                    <animate attributeName="offset"
                                             values="0;1.2;1.4" dur="1.3s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="1" stopColor="#1A3E61">
                                </stop>
                            </linearGradient>
                        </defs>
                    </svg>


                    <div className="iskry">
                        <img alt={"svg"} src="./Document_files/spark2.svg"
                             className="iskry-2"/>
                        <img alt={"svg"} src="./Document_files/spark3.svg"
                             className="iskry-3"/>
                        <img alt={"svg"} src="./Document_files/spark4.svg"
                             className="iskry-4"/>
                        <img alt={"svg"} src="./Document_files/spark1.svg"
                             className="iskry-1"/>
                        <img alt={"svg"} src="./Document_files/spark5.svg"
                            className="iskry-5"/>
                    </div>

                    <div className="light-2">
                        <img alt={"svg"} src="./Document_files/light-2.svg"/>
                    </div>

                    <img alt={"svg"} src="./Document_files/light-3.png"
                         className="light-3"/>
                    <img alt={"svg"} src="./Document_files/light-4.svg"
                         className="light-4"/>
                    <img alt={"svg"} src="./Document_files/light-5.png"
                         className="light-5"/>
                    <div className="light-1">
                        <img alt={"svg"} src="./Document_files/light-1.png"/>
                    </div>
                </div>
            </div>
        </StyledAnimatedBackground>
    );
}

export default AnimatedBackground;