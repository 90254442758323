import React from 'react';
import styled, {css} from "styled-components";
import OptionsMenuSvg from "../../images/OptionsMenuSvg";
import History from "../../utils/history";

const Title = styled.div(({theme: {tridot}}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${tridot.color};
  font-size: 22px;
  font-weight: bold;
`);

const Action = styled.div(({theme: {tridot}}) => css`
  display: flex;
  align-items: center;
`);

const StyledTriDotHeaderContent = styled.div(({theme: {tridot}}) => css`
  display: flex;
  align-items: stretch;
  width: 100%;

  ${Title} {
    flex: 1 1 auto;
  }
`);

type TriDotHeaderContentProps = {
    title: any
}

function TriDotHeaderContent({title}: TriDotHeaderContentProps) {
    return (
        <StyledTriDotHeaderContent>
            <Title>{title}</Title>
            <Action onClick={() => History.push("/settings")}>
                <OptionsMenuSvg/>
            </Action>
        </StyledTriDotHeaderContent>
    );
}

export default TriDotHeaderContent;