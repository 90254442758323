const palette = {
    color1: "#343A50",
    color2: "#131723",
    color3: "#EFEFEF",
    color4: "#0D6BFF",
    color5: "#FFFFFF",
    color6: "#64BDFF",
    color7: "#132318",
    color8: "#4AFB5C",
    color9: "#50421b",
    color10: "#441d1d",
    color11: "#ff8383",
    color12: "#66749D",
    color13: "#9AA5CD",
    color14: "#191D2B",
    color15: "#212638",
    color16: "#C6CEE8",
    color17: "#9CABC0",
    color18: "#25292D",
    color19: "#502121",
    color20: "#202433",
    color21: "#e82121",
    color22: "#E82121",
    color23: "#EDEFF5",
    color24: "#9fa3b0",
    color25: "#D3D3D3",
    color26: "#3E3C54",
    color27: "#111535",
    color28: "#2B3F6C",
    color29: "#37D500",
    color30: "#5D4B1C",
    color31: "#F18A11",
    color32: "#2E3949",
    color33: "#F0AD00"
}

export const dark_theme = {
    name: "dark_theme",
    background: palette.color1,
    login: {
        background: palette.color2,
        support_color: palette.color5,
        support_color2: palette.color13,
        link_color: palette.color6,
        color: palette.color5
    },
    form: {
        input: {
            background: palette.color1,
            color: palette.color3
        },
        button: {
            background: palette.color4,
            color: palette.color5
        }
    },
    layout: {
        content: {
            background: palette.color2
        }
    },
    menu: {
        color: palette.color17,
        active_color: palette.color6,
        background: palette.color1
    },
    dashboard: {
        color: palette.color5
    },
    dashboard_header_content: {
        color: palette.color5,
        title_background: palette.color20
    },
    capsule: {
        background: palette.color2,
        redBackground: palette.color19,
        yellowBackground: palette.color9,
        greenBackground: palette.color7,
        color: palette.color5,
        greenColor: palette.color8,
        redColor: palette.color22,
        yellowColor: palette.color33
    },
    header: {
        color: palette.color5
    },
    fuel_level: {
        boxShadow: "2px 3px 15px #000000",
    },
    cash: {
        titleColor: palette.color5,
        capsuleBackground: palette.color1
    },
    textslider: {
        background: palette.color1,
        color: palette.color5
    },
    titled_date_picker: {
        color: palette.color5,
        activeColor: palette.color6
    },
    mainmenu: {
        background: palette.color2,
        item_background: palette.color14,
        item_color: palette.color5,
        active_color: palette.color6,
        boxShadow: "5px 10px 15px rgba(0, 0, 0, 0.69)"
    },
    vouchers: {
        modalBorderColor: palette.color32,
        modalBackground: palette.color15,
        modalColor: palette.color5,
        modalInputBg: palette.color2,
        svgBackground: palette.color27,
        svgFill: palette.color28,
        additionalColor: palette.color5,
        greenColor: palette.color29,
        redColor: palette.color22
    },
    terminals: {
        background: palette.color2,
        terminal_background: palette.color1,
        boxShadow: "5px 10px 15px rgba(0, 0, 0, 0.69)",
        color: palette.color5
    },
    tridot: {
        color: palette.color5
    },
    round_block: {
        background: palette.color15,
        capsuleBackground: palette.color2,
        color: palette.color5,
        boxShadow: "5px 10px 15px rgba(0, 0, 0, 0.69)"
    },
    progress_line: {
        lastColor: palette.color23,
        background: palette.color23,
        boxShadow: "2px 3px 7px rgba(0, 0, 0, 0.25)",
        backgroundInner: "linear-gradient(90deg, #83BBFF 0%, #357BF5 57.91%)",
        backgroundRedInner: "linear-gradient(90deg,#ff8383 0%,#ff5858 57.91%)",
        status_color: "#E1E1E1",
        active_color: palette.color6,
        active_red_color: palette.color11,
        title_color: palette.color5
    },
    reports: {
        areaTextColor: palette.color5,
        count_color: palette.color6,
        tableColor: palette.color24
    },
    select: {
        color: palette.color5
    },
    options: {
        color: palette.color5,
        buttonBackground: palette.color1,
        buttonColor: palette.color5
    },
    burger: {
        color: palette.color5,
        background: palette.color1,
        border: "0.5px solid #939393",
        additional_color: palette.color16,
        boxShadow: "5px 10px 15px rgba(0, 0, 0, 0.69)",
    },
    terminalList: {
        accentColor1: palette.color1,
        accentColor2: palette.color2,
        textColor: palette.color5,
        greenColor: palette.color8,
        greenBackground: palette.color7,
        yellowBackground: palette.color9,
        yellowColor: palette.color33,
        redBackground: palette.color10,
        h2Color: palette.color12,
        errorColor: palette.color5
    },
    journal: {
        blockColor: palette.color5
    },
    settings: {
        background: palette.color15,
        inputBackground: palette.color2,
        inputColor: palette.color25,
        borderColor: palette.color26,
        blockColor: palette.color25
    },
    status: {
        blockBackground: palette.color15,
        innerBlockBackground: palette.color2,
        innerBlockColor: palette.color5
    }
}