import styled, {css} from "styled-components";
import {InputNumber as antInput} from "antd";

const InputNumber = styled(antInput)(({theme: {vouchers, form: {input}}}) => css`
  padding: 0 11px;
  background: ${input.background};
  border-radius: 4px;
  border: 1px solid #2E3949;
  width: 100%;

  border: 1px solid ${vouchers.modalBorderColor};
  background: ${vouchers.modalInputBg};
  color: ${vouchers.modalColor};
  display: flex;

  input {
    height: auto;
    text-align: center;
  }

  // font-size: 14px;
  // max-height: 55px;
    // color: ${input.color};
    // box-shadow: ${input.boxShadow};
  //
  //   padding: 18px;
    //   background: ${input.background};
  //   font-size: 14px;
  //   max-height: 55px;
    //   color: ${input.color};
  font-size: 34px;

  &:hover {
    background: ${input.background};
  }
`);

export default InputNumber;
