import React, {useEffect, useRef} from 'react';
import styled, {useTheme} from "styled-components";
import font from "../../fonts/Montserrat/Montserrat-Bold.woff";

const StyledPieDiagram = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 154px;
  //overflow: hidden;
  border-radius: 100%;
  position: relative;

  canvas {
    position: absolute;
  }
`;

type PieDiagramProps = {
    values: number[],
    colors?: string[],
    textColors?: string[],
}

function PieDiagram({values, textColors, colors}: PieDiagramProps) {
    const theme: any = useTheme();
    const canvasRef = useRef<any>();
    const isFirst = useRef<any>(true);

    const draw = () => {
        let ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        let lastend = 0;
        let data = values.map(el => Math.round(el));
        let myTotal = 0;
        let myColor = colors || ['#78C6FF', '#2776FF', '#0400D9'];

        for (let value of data) {
            myTotal += value;
        }

        let off = 0
        let w = (canvasRef.current.width - off) / 2
        let h = (canvasRef.current.height - off) / 2

        /*Рисуем дольки диаграммы*/
        for (let i = 0; i < data.length; i++) {
            if (data[i] === 0) {
                continue;
            }
            ctx.fillStyle = "black";
            ctx.fillStyle = myColor[i];
            ctx.strokeStyle = theme.name === "light_theme" ? "white" : '#343A50';
            ctx.lineWidth = 3;
            ctx.beginPath();
            ctx.moveTo(w, h);
            let len = (data[i] / myTotal) * 2 * Math.PI
            let r = h - off / 2
            ctx.arc(w, h, r, lastend, lastend + len, false);
            // ctx.lineTo(w, h);
            ctx.fill();
            // ctx.stroke();
            ctx.closePath();
            lastend += len;
        }
        /*Рисуем линии диаграммы*/
        for (let i = 0; i < data.length; i++) {
            if (data[i] === 0 || data.filter(el => el !== 0).length === 1) {
                continue;
            }
            let len = (data[i] / myTotal) * 2 * Math.PI
            let r = h - off / 2

            ctx.closePath();
            ctx.beginPath();
            ctx.strokeStyle = "gray";
            ctx.moveTo(Math.cos(lastend) * r + w, Math.sin(lastend) * r + h);
            ctx.lineTo(w, h)
            ctx.fill();
            ctx.stroke();
            lastend += len;
        }

        /*Рисуем текст на диаграмме*/
        for (let i = 0; i < data.length; i++) {
            if (data[i] === 0) {
                continue;
            }
            let len = (data[i] / myTotal) * 2 * Math.PI
            let r = h - off / 2
            ctx.fillStyle = textColors !== undefined ? textColors[i] : 'white';
            ctx.font = "bold 12px Montserrat";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            let mid = lastend + len / 2
            ctx.fillText(Math.round((data[i] / myTotal) * 100) + "%", w + Math.cos(mid) * (r / 2), h + Math.sin(mid) * (r / 2));
            lastend += Math.PI * 2 * (data[i] / myTotal);
        }
    }

    useEffect(() => {
        let cancelToken = false
        if (document.fonts.check('bold 14px Montserrat')) {
            draw()
        } else {
            new FontFace('Montserrat', `url(${font})`).load().then((font_) => {
                document.fonts.add(font_);
            }).then(() => {
                if (cancelToken) {
                    return
                }
                draw()
            })
        }
        return () => {
            cancelToken = true
        }
        // eslint-disable-next-line
    }, [values])

    useEffect(() => {
        isFirst.current = false;
    }, [])
    return (
        <StyledPieDiagram>
            <canvas ref={canvasRef} id="can" width="158" height="158"/>
        </StyledPieDiagram>
    );
}

export default PieDiagram;