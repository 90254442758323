import React, {useEffect} from 'react';
import styled, {css, useTheme} from "styled-components";
import * as _ from "lodash";
import {remap} from "../../utils/remap";

const Separator = styled.div<any>(({
                                       theme: {fuel_level},
                                       value,
                                       stripeColor,
                                       forceOffset,
                                       offsetTop,
                                       textColor,
                                       color,
                                       height,
                                       isMax,
                                       percent
                                   }) => css`
  width: 50%;
  height: ${height}%;
  border-top: 1px solid ${stripeColor || color};
  display: flex;
  position: absolute;
  top: ${offsetTop}%;

  &::before {
    content: "${value}";
    font-size: 8px;
    text-align: right;
    z-index: 999;
    margin-top: ${isMax ? "-90" : "-30"}%;
    margin-left: 115%;
    color: ${textColor || stripeColor || color};
    font-weight: bold;
  }

  &::after {
    content: "";
    z-index: 999;
    height: 100%;
    top: 1px;
    position: absolute;
    width: 70%;
    background: repeating-linear-gradient(to bottom, transparent, transparent 5px, ${stripeColor || color} 5px, ${stripeColor || color} 6px);
  }
`);

const Separators = styled.div<any>(({theme: {fuel_level}, color, percent}) => css`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 4px 10px 4px 0;
  min-width: 15px;
  position: absolute;
  height: 100%;
  top: -4px;

  ${Separator}:not(:last-child) {
    margin-bottom: 4px;
  }

  &::after {
    content: "0";
    color: #D3D3D3;
    font-size: 8px;
    font-weight: bold;
    bottom: 4px;
    left: -8px;
    position: absolute;
    margin-left: 115%;
  }
`);

const BarsWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 100px;
  position: relative;
`;

const Bar = styled.div<any>(({theme: {fuel_level}, color, percent}) => css`
  flex: 1 1 auto;
  box-shadow: ${fuel_level.boxShadow};
  min-width: 43px;
  border-radius: 100px;
  padding: 4px 3px;
  position: relative;


  ${FuelItem}:first-child {
    border-radius: 100px 100px 0 0;
  }

  ${FuelItem}:last-child {
    border-radius: 0 0 100px 100px;
  }
`);

const FuelItem = styled.div<any>(({theme: {fuel_level}, color, percent}) => css`
  display: flex;
  flex: 0 1 ${percent}%;
  background: ${color};
`);

const StyledFuelLevel = styled.div(({theme: {fuel_level}}) => css`
  display: flex;
  //max-height: 200px;
  min-height: 100%;
`);

type FuelLevelProps = {
    values: number[],
    volume: number
}

function FuelLevel({values, volume}: FuelLevelProps) {
    values = [volume - _.sum(values), ...values];
    const theme: any = useTheme();
    const colors = [theme.name === "light_theme" ? "#EAEAEF" : "#2E3448", "#78C6FF", "#850000"];
    const stripeColors = [theme.name === "light_theme" ? "#EAEAEF" : "#2E3448", "#2776FF", "#510707"];
    const textColors = [theme.name === "light_theme" ? "#36CF00" : "#36CF00", "#2776FF", "#D3D3D3"];


    const getPercentFromNumber = (number1: number, number2: number) => {
        return (number1 / number2) * 100;
    }

    // const getSeparators = () => {
    //     let array = [];
    //
    //     for (let i = 0; i < values.length - 1; i++) {
    //         array.push(<Separator key={i}
    //                               color={stripeColors[i + 1]}
    //                               textColor={textColors[i + 1]}
    //                               height={getPercentFromNumber(values[i + 1], volume) + (i === (values.length - 2) ? 10 : 0)}
    //                               offsetTop={getPercentFromNumber(_.sum(values.slice(0, i + 1)), volume) - 10}/>)
    //     }
    //
    //     return array;
    // }

    // let separators = getSeparators();

    // console.log([getPercentFromNumber(values[0], volume), getPercentFromNumber(values[1], volume), getPercentFromNumber(values[2], volume)]);

    const value3 = getPercentFromNumber(values[2], volume) + 10;
    const value2 = remap(values[1], 0, volume - values[2], 0, 100 - 10 - value3);
    const value1 = 100 - value2 - value3;

    useEffect(() => {
        // console.log("values[1]", values[1]);
        // console.log(values);
        // console.log([value1, value2, value3], _.sum([value1, value2, value3]))
    })

    return (
        <StyledFuelLevel>
            <Bar>
                <BarsWrap>
                    <FuelItem percent={value1} color={colors[0]}/>
                    <FuelItem percent={value2} color={colors[1]}/>
                    <FuelItem percent={value3} color={colors[2]}/>

                    <Separators>


                        <Separator
                            color={stripeColors[1]}
                            textColor={textColors[1]}
                            height={value2}
                            offsetTop={Math.max(value1 - 10, 0) + 10}/>
                        <Separator
                            color={stripeColors[2]}
                            textColor={textColors[2]}
                            height={value3}
                            offsetTop={value2 + Math.max(value1 - 10, 0) + 10}/>
                        <Separator isMax value={volume}
                                   textColor={textColors[0]}
                                   stripeColor={"#D3D3D3"}
                                   height={Math.max(value1 - 10, 0)}
                                   color={colors[0]} offsetTop={10}/>
                        {/*{separators}*/}
                    </Separators>
                </BarsWrap>
            </Bar>
        </StyledFuelLevel>
    );
}

export default FuelLevel;