import React from 'react';
import styled, {css} from "styled-components";
import {Tooltip} from "antd";

export const StyledCapsule = styled.div(({theme: {capsule}}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  background: ${capsule.background};
  color: ${capsule.color};
  border-radius: 72px;
  max-height: 24px;
  font-size: 12px;
  overflow: hidden;
  min-height: 24px;
  min-width: 70px;
  max-width: 150px;
  width: 100%;

  &.green {
    background: ${capsule.greenBackground};
    color: ${capsule.greenColor};
  }

  &.red {
    background: ${capsule.redBackground};
    color: ${capsule.redColor};
  }

  &.yellow {
    background: ${capsule.yellowBackground};
    color: ${capsule.yellowColor};
  }

  p {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    font-weight: 600;

    span {
      font-weight: 600;
    }
  }
`);

type CapsuleProps = {
    children: any
} & any

function Capsule({children, ...props}: CapsuleProps) {
    return (
        <StyledCapsule {...props}>
            <Tooltip trigger={['hover', 'click']} title={children}>
                <p>{children}</p>
            </Tooltip>
        </StyledCapsule>
    );
}

export default Capsule;