import React from 'react';
import styled, {css} from "styled-components";
import Menu from "../components/Nav/Menu";

const Content = styled.div(({theme: {layout: {content}}}) => css`
  flex: 1 1 auto;
  background: ${content.background};
  border-radius: 22px 22px 0 0;
  padding: 27px 16px 100px 16px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
`);

const StyledAppLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

type AppLayoutProps = {
    children: any,
    hideNav?: boolean,
    Header: any
}

function AppLayout({children, Header, hideNav = false}: AppLayoutProps) {
    return (
        <StyledAppLayout>
            {Header}
            <Content>{children}</Content>
            {!hideNav && <Menu/>}
        </StyledAppLayout>
    );
}

export default AppLayout;