import React, {useState} from 'react';
import {message, Modal, Select as AntSelect} from "antd";
import Input from "../Form/Input";
import {Button} from "../Form/Button";
import styled, {css} from "styled-components";
import Select from "../DataView/Select";
import InputNumber from "../Form/InputNumber";
import axios from "axios";
import {api} from "../../config";

const {Option} = AntSelect;

const ModalTop = styled.div`
  max-width: 214px;
`;

const CreateModal = styled.div(({theme: {vouchers}}) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${vouchers.modalBackground};
  padding: 30px;
  border-radius: 8px;

  .ant-select {
    width: 100%;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 21px;
    font-weight: 700;
    text-align: center;
    color: ${vouchers.additionalColor};
  }

  ${Button} {
    margin-top: 34px;
  }

  ${Input} {
    padding: 0 18px;
    border-radius: 4px;
    border: 1px solid ${vouchers.modalBorderColor};
    background: ${vouchers.modalInputBg};
    color: ${vouchers.modalColor};

    input {
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    input, span {
      font-size: 34px;
      background: ${vouchers.modalInputBg};
      text-align: center;
      color: ${vouchers.modalColor};
    }
  }
`);

function TechnologicalModal({visible, station, setVisible, parentClass}: any) {
    const [volumeValue, setVolumeValue] = useState<number>(0);
    const [sumValue, setSumValue] = useState<number>(0);

    const [fuelValue, setFuelValue] = useState<any>(null);

    const submitOperation = () => {
        if (fuelValue === null) {
            message.error("Вы не указали тип топлива!")
            return
        }
        if (volumeValue <= 0) {
            message.error("Объем должен быть больше 0")
            return
        }
        // if (sumValue <= 0) {
        //     message.error("Сумма должна быть больше 0")
        //     return
        // }
        // console.log(fuelValue, Number((volumeValue || 0) * 100).toFixed(0));
        axios.put(`${api}/user/stations/${station.Id}/openings/${fuelValue}/${Number((volumeValue || 0) * 100).toFixed(0)}/${Number((sumValue || 0) * 100).toFixed(0)}`).then(() => {
            setVolumeValue(0);
            setSumValue(0);
            setVisible(false);
            message.success("Отпуск успешно произведен!")
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    return (
        <Modal getContainer={`.${parentClass}`}
               closable={false}
               visible={visible}
               onCancel={() => setVisible(false)} footer={null}>
            <CreateModal>
                <ModalTop>
                    <h1>Укажите данные</h1>
                    <Select value={fuelValue} onChange={(value: any) => setFuelValue(value)}
                            placeholder={"Тип топлива"}>
                        {station.Status.Volumes.map((el: any) => <Option value={el.Fuel}>
                            {el.Fuel}
                        </Option>)}
                    </Select>
                    {/*<Input*/}
                    {/*    suffix={"л"}*/}
                    {/*    value={volumeValue}*/}
                    {/*    onChange={(value) => {*/}
                    {/*        console.log(value);*/}
                    {/*        setVolumeValue(value)*/}
                    {/*    }}/>*/}
                    <InputNumber
                        min={0}
                        defaultValue={100}
                        value={volumeValue}
                        formatter={(value) => `${Number(value).toFixed(2)} л.`}
                        parser={(value) => Number(value?.replace(/л.\s?|(,*)/g, '') || 0).toFixed(2)}
                        onChange={(value: any) => setVolumeValue(value)}
                        // onChange={(value) => {
                        //     console.log(value);
                        //     setVolumeValue(value)
                    />
                    <InputNumber
                        style={{marginTop: 20}}
                        min={0}
                        defaultValue={100}
                        value={sumValue}
                        formatter={(value) => `${Number(value).toFixed(2)} ₽`}
                        parser={(value) => Number(value?.replace(/₽\s?|(,*)/g, '') || 0).toFixed(2)}
                        onChange={(value: any) => setSumValue(value)}
                        // onChange={(value) => {
                        //     console.log(value);
                        //     setVolumeValue(value)
                    />
                </ModalTop>
                <Button onClick={() => {
                    submitOperation();
                }}>Произвести отпуск</Button>
            </CreateModal>
        </Modal>
    );
}

export default TechnologicalModal;