import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import PieDiagram from "../components/DataView/PieDiagram";
import TitledDatePicker, {StyledTitledDatePicker} from "../components/DataView/TitledDatePicker";
import Capsule, {StyledCapsule} from "../components/DataView/Capsule";
import Burger, {BurgerBlock, BurgerItem} from "../components/DataView/Burger";
import StorageLineSvg from "../images/StorageLineSVG";
import axios from "axios";
import {api} from "../config";
import {connect} from "react-redux";
import moment from "moment";
import * as _ from "lodash";
import getTimeZone from "../utils/getTimeZone";
import {message, Select as AntSelect} from "antd";
import Select from "../components/DataView/Select";
import {ruCurrencyFormat} from "../utils/ruCurrencyFormat";
import OldTextSlider from "../components/DataView/OldTextSlider";

const Total = styled.div(({theme: {cash, textslider}}) => css`
    display: flex;
    flex-direction: column;
    color: ${cash.titleColor};
    font-size: 16px;
    font-weight: bold;

    ${StyledCapsule} {
        margin-top: 5px;
        background: ${cash.capsuleBackground};
        font-size: 14px;
        font-weight: bold;
        min-height: 30px;
        color: ${textslider.color};

        &:first-child {
            margin-bottom: 20px;
        }
    }
`);
const Diagram = styled.div(({theme: {cash}}) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
`);


const Text = styled.div(({theme: {cash}}) => css`
    flex: 1 1 auto;
    max-width: 50%;
    font-weight: 600;
`);

const StyledCash = styled.div(({theme: {cash}}) => css`
    ${StyledTitledDatePicker} {
        margin-bottom: 25px;
    }
`);

const {Option} = AntSelect;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

type CashProps = {
    station?: any
}

function Cash({station}: CashProps) {
    const [dates, setDates] = useState([moment(), moment()]);
    const [data, setData] = useState([]);
    // const [vouchers, setVouchers] = useState([]);
    const [fuels, setFuels] = useState<any>([]);
    const totalString = "Реализация";


    const [sliderValue, setSliderValue] = useState(totalString);
    const [request] = useState("def");

    const onRequestChange = () => {
        axios(`${api}/user/stations/${station.Id}/openings/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}/email?zone=${getTimeZone()}`).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const getData = () => {
        axios(`${api}/user/stations/${station.Id}/openings/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}?zone=${getTimeZone()}`).then(({data}) => {
            setData(data);
        })
    }

    // const getVouchers = () => {
    //     axios(`${api}/user/stations/${station.Id}/vouchers/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}?zone=${getTimeZone()}`).then(({data}) => {
    //         // axios(`${api}/user/stations/${station.Id}/vouchers?zone=${getTimeZone()}`).then(({data}) => {
    //         console.log("data", data);
    //         setVouchers(data || []);
    //     }).catch(({response}) => {
    //         message.error(response?.data || "Error")
    //     })
    // }

    useEffect(() => {
        setFuels(_.uniq(_.map(data, "Fuel")));
    }, [data]);

    useEffect(() => {
        setSliderValue(totalString);
    }, [fuels]);


    useEffect(() => {
        getData();
        // getVouchers();
        // eslint-disable-next-line
    }, [dates])

    return (
        <StyledCash>
            <Top>
                <Select style={{marginLeft: "auto"}} className={"blue"} value={request} onChange={onRequestChange}>
                    <Option style={{display: "none"}} value={"def"} disabled>Экспорт</Option>
                    <Option value={"test"}>На почту</Option>
                    {/*<Option value={"test2"}>Скачать</Option>*/}
                </Select>
            </Top>
            <TitledDatePicker values={dates} onChange={setDates} title={"РЕАЛИЗАЦИЯ:"}/>
            {(sliderValue && fuels.length > 0) &&
                <OldTextSlider value={sliderValue} onChange={value => setSliderValue(value)}
                               values={[totalString, ...fuels]}/>}
            {(sliderValue && fuels.length > 0) && <Diagram>
                <Total>
                    СР. ЦЕНА:
                    <Capsule>{ruCurrencyFormat(sliderValue === totalString ? ((_.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment !== 2 && el.Payment !== 3), "Price") || 0) / (data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment !== 2 && el.Payment !== 3).length || 1)) : ((_.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment !== 2 && el.Payment !== 3), "Price") || 0) / (data.filter((el: any) => el.Fuel === sliderValue && el.Payment !== 2 && el.Payment !== 3).length || 1))).replace(",", ".")} ₽/л.</Capsule>
                    ИТОГО:
                    <Capsule>{ruCurrencyFormat(sliderValue === totalString ? (_.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment !== 2 && el.Payment !== 3), "OrderTotal")) : (_.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment !== 2 && el.Payment !== 3), "OrderTotal"))).replace(",", ".")} ₽</Capsule>
                </Total>
                <PieDiagram
                    colors={['#2776FF', '#78C6FF', '#924E7D', '#BEBD7F']}
                    values={sliderValue === totalString ? [
                        _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment === 0), "OrderTotal"),
                        _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && (el.Payment === 1 || el.Payment === 5 || el.Payment === 6)), "OrderTotal"),
                        _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment === 4), "OrderTotal"),
                        // _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment === 5), "OrderTotal"),

                    ] : [
                        _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 0), "OrderTotal"),
                        _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && (el.Payment === 1 || el.Payment === 5 || el.Payment === 6)), "OrderTotal"),
                        _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 4), "OrderTotal"),
                        // _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 5), "OrderTotal"),

                    ]}
                    textColors={[
                        "white",
                        "black",
                        "white",
                        // "white"
                    ]}/>
            </Diagram>}
            <Burger>
                <BurgerItem>
                    <BurgerBlock>
                        <StorageLineSvg color={"#2776FF"}/> <Text>Наличный расчет</Text>
                        <Capsule>{ruCurrencyFormat(sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment === 0), "OrderTotal") : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 0), "OrderTotal")).replace(",", ".")} ₽</Capsule>
                    </BurgerBlock>
                </BurgerItem>
                <BurgerItem>
                    <BurgerBlock>
                        <StorageLineSvg color={"#78C6FF"}/> <Text>Безналичный расчет</Text>
                        <Capsule>{ruCurrencyFormat(sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && (el.Payment === 1 || el.Payment === 5 || el.Payment === 6)), "OrderTotal") : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && (el.Payment === 1 || el.Payment === 5 || el.Payment === 6)), "OrderTotal"))} ₽</Capsule>
                    </BurgerBlock>
                </BurgerItem>
                <BurgerItem>
                    <BurgerBlock>
                        <StorageLineSvg color={"#924E7D"}/> <Text>Топливные
                        карты</Text>
                        <Capsule>{ruCurrencyFormat(sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment === 4), "OrderTotal") : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 4), "OrderTotal"))} ₽</Capsule>
                    </BurgerBlock>
                </BurgerItem>
                {/*<BurgerItem>*/}
                {/*    <BurgerBlock>*/}
                {/*        <StorageLineSvg color={"#BEBD7F"}/> <Text>Оплата вне*/}
                {/*        системы</Text>*/}
                {/*        <Capsule>{_.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 5), "OrderTotal")} ₽</Capsule>*/}
                {/*    </BurgerBlock>*/}
                {/*</BurgerItem>*/}
                <BurgerItem>
                    <BurgerBlock>
                        <StorageLineSvg color={"#826C34"}/> <Text>Итого</Text>
                        <Capsule>{ruCurrencyFormat(sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && [
                            0,
                            1,
                            4,
                            6,
                            5
                        ].includes(el.Payment)), "OrderTotal") : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && [
                            0,
                            1,
                            4,
                            6,
                            5
                        ].includes(el.Payment)), "OrderTotal"))} ₽</Capsule>
                    </BurgerBlock>
                </BurgerItem>
                {/*<BurgerItem>*/}
                {/*    <BurgerBlock>*/}
                {/*        <StorageLineSvg color={"#0400D9"}/> <Text>Выданные купоны</Text>*/}
                {/*        <Capsule>{_.sumBy(vouchers.filter((el: any) => el.Fuel === sliderValue), "OrderTotal")?} ₽</Capsule>*/}
                {/*    </BurgerBlock>*/}
                {/*</BurgerItem>*/}
            </Burger>
        </StyledCash>
    );
}

const mapStateToProps = (store: any) => {
    return {
        station: store.pageReducer.stations.find((el: any) => el.Id === store.pageReducer.selectedStation)
    }
}
export default connect(mapStateToProps, null)(Cash);