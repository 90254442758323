import React from 'react';

function JerrycanSvg(props) {
    return (
        <svg {...props} width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.99954 20.7055C9.03831 20.7098 10.0676 20.508 11.0279 20.1117C11.9881 19.7155 12.8602 19.1327 13.5937 18.3971C15.0572 16.9339 15.7969 15.0449 15.7324 12.9344C15.6738 11.0135 14.9309 8.9992 13.6402 7.26225L8.38902 0.195684C8.34386 0.134966 8.28513 0.085649 8.21752 0.0516721C8.14991 0.0176951 8.07529 0 7.99962 0C7.92395 0 7.84933 0.0176951 7.78171 0.0516721C7.7141 0.085649 7.65537 0.134966 7.61022 0.195684L2.35895 7.26233C1.06805 8.99928 0.325317 11.0136 0.266557 12.9345C0.202257 15.045 0.941913 16.9341 2.40549 18.3972C3.13896 19.1328 4.01105 19.7155 4.97128 20.1117C5.9315 20.508 6.96078 20.7098 7.99954 20.7055ZM9.79582 17.7881C9.74172 17.6714 9.73621 17.5379 9.78051 17.4171C9.8248 17.2962 9.91528 17.198 10.032 17.1438C11.4515 16.4854 12.4062 15.2189 12.5859 13.7562C12.5933 13.6926 12.6132 13.6311 12.6444 13.5752C12.6756 13.5193 12.7176 13.4702 12.7679 13.4306C12.8183 13.391 12.8759 13.3618 12.9376 13.3446C12.9992 13.3274 13.0637 13.3225 13.1272 13.3303C13.1908 13.3382 13.2521 13.3585 13.3078 13.3901C13.3634 13.4217 13.4123 13.4641 13.4515 13.5146C13.4907 13.5652 13.5196 13.6231 13.5363 13.6849C13.5531 13.7467 13.5575 13.8111 13.5492 13.8746C13.3287 15.6722 12.1664 17.2236 10.4405 18.0243C10.3827 18.0512 10.3201 18.0664 10.2564 18.0691C10.1927 18.0718 10.129 18.0619 10.0692 18.04C10.0093 18.018 9.9543 17.9845 9.9074 17.9413C9.8605 17.8981 9.82258 17.846 9.79582 17.7881Z" fill="#9CABC0"/>
        </svg>
    );
}

export default JerrycanSvg;