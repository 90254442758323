import React from 'react';
import styled, {css} from "styled-components";
import {StyledCapsule} from "./Capsule";

export const BurgerItem = styled.div(({theme: {burger}}) => css`
  padding: 16px;
  line-height: 16px;
  color: ${burger.color};
  background: ${burger.background};
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  position: relative;
`);

export const BurgerBlock = styled.div(({theme: {cash}}) => css`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    margin-right: 10px;
  }

  ${StyledCapsule} {
    margin-left: auto;
  }
`);

export const StyledBurger = styled.div(({theme: {burger}}) => css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: ${burger.boxShadow};

  ${BurgerItem} {
    border-radius: 0;

    &:first-child {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:not(:last-child) {
      border-bottom: ${burger.border};
    }
  }
`);

type BurgerProps = {
    children: any
}

function Burger({children}: BurgerProps) {
    return (
        <StyledBurger>
            {children}
        </StyledBurger>
    );
}

export default Burger;