import React from 'react';
import styled, {css} from "styled-components";
import {Select as AntSelect} from "antd";
import PolygonArrow from "../../images/PolygonArrow";

const StyledSelect = styled(AntSelect)(({theme: {select}}) => css`
  color: ${select.color};

  .ant-select-selector {
    padding: 0 10px 0 0 !important;
  }
`);

type SelectProps = {
    children: any,
    value: any,
    onChange: (value: any) => any
} & any

function Select({children, value, onChange, ...props}: SelectProps) {
    return (
        <StyledSelect dropdownMatchSelectWidth={false} {...props} value={value} onChange={onChange} bordered={false}
                      suffixIcon={(value: any) => <div>{value.open ? <PolygonArrow/> :
                          <PolygonArrow style={{transform: "rotate(180deg)"}}/>}</div>}>
            {children}
        </StyledSelect>
    );
}

export default Select;