import React from 'react';
import styled, {css} from "styled-components";

const StyledFuelMenuSvg = styled.svg(({theme}: any) => css`
  circle {
    fill: ${theme.name === "light_theme" ? "#FFF2D1" : "#57471f"};
  }
`);

function FuelMenuSvg(props:any) {
    return (
        <StyledFuelMenuSvg {...props} width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="42" cy="42" r="42" fill="#FFF2D1"/>
            <g clipPath="url(#clip0_1234_722)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M41.9999 63C44.107 63.0087 46.1949 62.5993 48.1427 61.7955C50.0905 60.9918 51.8595 59.8097 53.3473 58.3176C56.3161 55.3496 57.8165 51.5178 57.6857 47.2368C57.5668 43.3403 56.0598 39.2544 53.4417 35.7311L42.79 21.3969C42.6984 21.2738 42.5792 21.1737 42.4421 21.1048C42.3049 21.0359 42.1536 21 42.0001 21C41.8466 21 41.6952 21.0359 41.5581 21.1048C41.4209 21.1737 41.3018 21.2738 41.2102 21.3969L30.5583 35.7312C27.9398 39.2546 26.4332 43.3405 26.314 47.2369C26.1836 51.518 27.6839 55.3498 30.6527 58.3177C32.1405 59.8098 33.9095 60.9919 35.8573 61.7956C37.805 62.5993 39.8929 63.0087 41.9999 63ZM45.6436 57.0822C45.5338 56.8454 45.5227 56.5747 45.6125 56.3296C45.7024 56.0845 45.8859 55.8851 46.1227 55.7753C49.002 54.4398 50.9386 51.8706 51.3032 48.9037C51.3181 48.7747 51.3584 48.6499 51.4218 48.5366C51.4852 48.4232 51.5703 48.3235 51.6724 48.2432C51.7744 48.163 51.8914 48.1037 52.0164 48.0688C52.1415 48.0339 52.2723 48.024 52.4011 48.0399C52.53 48.0557 52.6545 48.0969 52.7674 48.1611C52.8803 48.2253 52.9794 48.3111 53.0589 48.4137C53.1385 48.5164 53.197 48.6337 53.231 48.759C53.265 48.8843 53.2739 49.0152 53.2572 49.1439C52.8098 52.7903 50.4522 55.9371 46.9512 57.5613C46.834 57.6159 46.707 57.6468 46.5778 57.6522C46.4485 57.6577 46.3195 57.6376 46.198 57.5931C46.0766 57.5486 45.9651 57.4805 45.8699 57.3929C45.7748 57.3052 45.6979 57.1997 45.6436 57.0822Z"
                      fill="#F18A11"/>
            </g>
            <defs>
                <clipPath id="clip0_1234_722">
                    <rect width="42" height="42" fill="white" transform="translate(21 21)"/>
                </clipPath>
            </defs>
        </StyledFuelMenuSvg>
    );
}

export default FuelMenuSvg;