import styled, {css} from "styled-components";
import {Input as antInput} from "antd";

const Input = styled(antInput)(({theme: {form: {input}}}) => css`
  padding: 18px;
  background: ${input.background};
  border-radius: 10px;
  font-size: 14px;
  max-height: 55px;
  color: ${input.color};
  box-shadow: ${input.boxShadow};

  input {
    padding: 18px;
    background: ${input.background};
    font-size: 14px;
    max-height: 55px;
    color: ${input.color};
  }

  &:hover {
    background: ${input.background};
  }
`);

Input.Password = styled(antInput.Password)(({theme: {form: {input}}}) => css`
  padding: 18px;
  background: ${input.background};
  border-radius: 10px;
  font-size: 14px;
  max-height: 55px;
  box-shadow: ${input.boxShadow};

  input {
    color: ${input.color};
  }

  &:hover {
    background: ${input.background};
  }
`);

export default Input;
