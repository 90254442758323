import React from 'react';

function OptionsMenuSvg(props) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="4.57143" fill="#6E758E"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24.4023 17.5801C24.76 17.7701 25.036 18.0701 25.2301 18.3701C25.6083 18.9901 25.5776 19.7501 25.2097 20.4201L24.4943 21.6201C24.1162 22.2601 23.411 22.6601 22.6855 22.6601C22.3278 22.6601 21.9292 22.5601 21.6022 22.3601C21.3365 22.1901 21.0299 22.1301 20.7029 22.1301C19.6911 22.1301 18.8429 22.9601 18.8122 23.9501C18.8122 25.1001 17.872 26.0001 16.6968 26.0001H15.3069C14.1215 26.0001 13.1813 25.1001 13.1813 23.9501C13.1608 22.9601 12.3126 22.1301 11.3009 22.1301C10.9636 22.1301 10.657 22.1901 10.4015 22.3601C10.0745 22.5601 9.66572 22.6601 9.31825 22.6601C8.58245 22.6601 7.87729 22.2601 7.49917 21.6201L6.79402 20.4201C6.4159 19.7701 6.39546 18.9901 6.77358 18.3701C6.93709 18.0701 7.24368 17.7701 7.59115 17.5801C7.87729 17.4401 8.06125 17.2101 8.23498 16.9401C8.74596 16.0801 8.43937 14.9501 7.57071 14.4401C6.55897 13.8701 6.23194 12.6001 6.81446 11.6101L7.49917 10.4301C8.09191 9.44012 9.35913 9.09012 10.3811 9.67012C11.2702 10.1501 12.425 9.83012 12.9462 8.98012C13.1097 8.70012 13.2017 8.40012 13.1813 8.10012C13.1608 7.71012 13.2732 7.34012 13.4674 7.04012C13.8455 6.42012 14.5302 6.02012 15.2763 6.00012H16.7172C17.4735 6.00012 18.1582 6.42012 18.5363 7.04012C18.7203 7.34012 18.8429 7.71012 18.8122 8.10012C18.7918 8.40012 18.8838 8.70012 19.0473 8.98012C19.5685 9.83012 20.7233 10.1501 21.6226 9.67012C22.6344 9.09012 23.9118 9.44012 24.4943 10.4301L25.179 11.6101C25.7718 12.6001 25.4447 13.8701 24.4228 14.4401C23.5541 14.9501 23.2475 16.0801 23.7687 16.9401C23.9322 17.2101 24.1162 17.4401 24.4023 17.5801ZM13.1097 16.0101C13.1097 17.5801 14.4076 18.8301 16.0121 18.8301C17.6165 18.8301 18.8838 17.5801 18.8838 16.0101C18.8838 14.4401 17.6165 13.1801 16.0121 13.1801C14.4076 13.1801 13.1097 14.4401 13.1097 16.0101Z"
                  fill="white"/>
        </svg>
    );
}

export default OptionsMenuSvg;