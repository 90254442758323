import React from 'react';
import styled, {css} from "styled-components";

const StyledProductsMenuSvg = styled.svg(({theme}: any) => css`
  circle {
    &:first-child {
      fill: ${theme.name === "light_theme" ? "#FED2FF" : "#341035"};
    }
  }
`);


function ProductsMenuSvg(props: any) {
    return (
        <StyledProductsMenuSvg {...props} width="84" height="84" viewBox="0 0 84 84" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
            <circle cx="42" cy="42" r="42" fill="#FED2FF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M23.2132 25.9924C23.3554 25.2816 24.0469 24.8206 24.7577 24.9628L26.594 25.33C28.6682 25.7449 30.2284 27.4654 30.4388 29.5702L30.588 31.0623H54.3451C57.8176 31.0623 60.3655 34.3256 59.5233 37.6943L57.5584 45.5536C56.7307 48.8646 53.7558 51.1873 50.343 51.1873H34.6054C32.1693 51.1873 30.1174 49.3669 29.8271 46.9482L28.0956 32.5183L27.8269 29.8314C27.7312 28.8747 27.022 28.0926 26.0792 27.9041L24.2429 27.5368C23.5321 27.3946 23.0711 26.7032 23.2132 25.9924ZM36.75 43.3121C36.0251 43.3121 35.4375 43.8997 35.4375 44.6246C35.4375 45.3494 36.0251 45.9371 36.75 45.9371H43.75C44.4749 45.9371 45.0625 45.3494 45.0625 44.6246C45.0625 43.8997 44.4749 43.3121 43.75 43.3121H36.75Z"
                  fill="#A251AF"/>
            <circle cx="35.875" cy="56" r="2.625" fill="#A251AF"/>
            <circle cx="51.625" cy="56" r="2.625" fill="#A251AF"/>
        </StyledProductsMenuSvg>
    );
}

export default ProductsMenuSvg;