import React from 'react';
import {useTheme} from "styled-components";

function PolygonArrow(props) {
    const theme = useTheme();

    return (
        <svg {...props} width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 0L7.4641 4.5H0.535898L4 0Z" fill={theme.name === "light_theme" ? "black" : "white"}/>
        </svg>
    );
}

export default PolygonArrow;