import React from 'react';
import styled, {css} from "styled-components";
import {useSelector} from "react-redux";
import light_bg from "../images/background2.svg";
import Logo from "../components/Nav/Logo";
import AnimatedBackground from "../components/AnimatedBackground";

// @ts-ignore
const StyledWelcomePage = styled.div<any>(({selectedTheme}: any) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: black;
  background: ${selectedTheme === "light_theme" ? `url(${light_bg})` : "black"};
  background-position-x: center;
  background-size: cover;
  height: 100%;
  padding-top: 20vh;
  position: relative;

  img {
    margin-bottom: 30px;
    z-index: 1;
  }

  h1 {
    z-index: 1;
    position: relative;
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #7D84A2;
  }
`);

function WelcomePage() {
    const selected_theme = useSelector(({pageReducer: {theme}}: any) => theme);

    return (
        <StyledWelcomePage selectedTheme={selected_theme}>
            <Logo/>
            <h1>Добро пожаловать<br/>
                в мир PlexPoint</h1>
            {selected_theme !== "light_theme" && <AnimatedBackground/>}
        </StyledWelcomePage>
    );
}

export default WelcomePage;