import React from 'react';
import styled, {css, useTheme} from "styled-components";
import {remap} from "../../utils/remap";
import * as _ from "lodash";

export const StyledProgressLine = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
const InnerLine = styled.div<any>(({theme: {progress_line}, currentValue, percent}) => css`
  border-radius: 37px;
  width: ${percent}%;
  height: 100%;
  background: ${progress_line.backgroundInner};
  position: relative;
  font-weight: 600;
  min-width: 35px;
`);

const SecondInnerLine = styled(InnerLine)<any>(({theme: {progress_line}, currentValue, percent}) => css`
  display: flex;
  width: ${percent}%;
  background: #850000;
  position: absolute;
  height: 100%;
  min-width: 33px;
`);
const Title = styled.div(({theme: {progress_line}}) => css`
  font-size: 12px;
  color: ${progress_line.title_color};
  margin-bottom: 20px;
  font-weight: 600;

  span {
    font-weight: 600;
    color: #37D500;
  }
`);

const Line = styled.div(({theme: {progress_line}}) => css`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background: ${progress_line.background};
  box-shadow: ${progress_line.boxShadow};
  border-radius: 37px;
  height: 37px;
  border: 2px solid #FFFFFF;
`);

const Separator = styled.div<any>(({
                                       theme: {fuel_level},
                                       value,
                                       stripeColor,
                                       forceOffset,
                                       offsetTop,
                                       textColor,
                                       color,
                                       height,
                                       isMax,
                                       percent
                                   }) => css`
  width: ${height}%;
  border-right: 1px solid ${stripeColor || color};
  display: flex;
  position: absolute;
  right: ${offsetTop}%;
  height: 10px;


  &::after {
    content: "";
    z-index: 999;
    position: absolute;
    width: 100%;
    height: 8px;
    background: repeating-linear-gradient(to right, transparent, transparent 5px, ${stripeColor || color} 5px, ${stripeColor || color} 6px);
  }
`);

const Tips = styled.div<any>(({theme: {fuel_level, progress_line}, color, percent, value}) => css`
  display: flex;
  width: 100%;
  position: relative;
  margin-top: 15px;

  &.values {
    margin-top: 5px;
  }

  div {
    display: flex;
    font-weight: 600;
    font-size: 10px;
    position: absolute;
  }

  .min {
    position: absolute;
    top: -45px;
    left: 0;
    color: #850000;
    transform: translate(-50%, 0);
  }

  .first {
    color: #C73C3C;
    top: -60px;
    left: 0;
  }

  .value {
    color: #7EB6FE;
    position: relative;
    transform: translate(-50%, 0);

    &:after {
      content: "${value}";
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;
      top: -16px;
    }
  }

  .last {
    color: ${progress_line.lastColor};
    top: -60px;
    right: 0;
  }
`);

const Separators = styled.div<any>(({theme: {fuel_level, progress_line}, color, percent, value}) => css`
  display: flex;
  flex: 1 1 auto;
  margin: 34px 10px 4px 0;
  min-width: 15px;
  position: relative;
  width: 100%;
  height: 10px;

  ${Separator}:not(:last-child) {
    margin-bottom: 4px;
  }

  &::before {
    content: "0";
    color: #D3D3D3;
    font-size: 8px;
    font-weight: bold;
    bottom: -15px;
    left: 5px;
    position: absolute;
  }

  &::after {
    content: "${value}";
    color: ${progress_line.lastColor};
    font-size: 10px;
    font-weight: bold;
    top: -16px;
    right: 0;
    position: absolute;
  }
`);


type ProgressLineProps = {
    values: any,
    total: any,
    min: any,
    current: any
}

function ProgressLine({values, total = 0, current = 0, min = 0}: ProgressLineProps) {
    values = [total - _.sum(values), ...values];
    const theme: any = useTheme();

    const getPercentFromNumber = (number1: number, number2: number) => {
        return (number1 / number2) * 100;
    }

    const colors = [theme.name === "light_theme" ? "#EAEAEF" : "#2E3448", "#78C6FF", "#850000"];
    const stripeColors = [theme.name === "light_theme" ? "#EAEAEF" : "#2E3448", "#2776FF", "#510707"];
    const textColors = [theme.name === "light_theme" ? "#36CF00" : "#36CF00", "#2776FF", "#D3D3D3"];

    const value3 = getPercentFromNumber(values[2], total) + 10;
    const value2 = remap(values[1], 0, total - values[2], 0, 100 - 10 - value3);
    const value1 = 100 - value2 - value3;

    return (
        <StyledProgressLine>
            {/*<Title>К отпуску <span>{current}л.</span> из <span>{total}л.</span></Title>*/}
            <Title>Общий объем: <span>{total} л.</span></Title>
            <Line>
                <InnerLine currentValue={current} percent={(current / total) * 100}/>
                <SecondInnerLine currentValue={min} percent={(min / total) * 100}/>
            </Line>
            {/*<Status>*/}
            {/*    <span>0л.</span>*/}
            {/*    <span>{total}л.</span>*/}
            {/*</Status>*/}

            <Separators value={total - min - current}>
                <Separator
                    color={stripeColors[1]}
                    textColor={textColors[1]}
                    height={value2}
                    offsetTop={Math.max(value1 - 10, 0) + 10}/>
                <Separator
                    color={stripeColors[2]}
                    textColor={textColors[2]}
                    height={value3}
                    offsetTop={value2 + Math.max(value1 - 10, 0) + 10}/>
                <Separator isMax value={total}
                           textColor={textColors[0]}
                           stripeColor={"#D3D3D3"}
                           height={Math.max(value1, 0)}
                           color={colors[0]} offsetTop={0}/>
                {/*{separators}*/}
            </Separators>
            <Tips value={current}>
                <div style={{left: `${value3/2}%`}} className="min">{min}</div>
                <div className="first">
                    Мертвый остаток
                </div>
                <div style={{left: `${value3 + value2}%`}} className="value">К отпуску</div>
                <div className="last">К приему</div>
            </Tips>
        </StyledProgressLine>
    );
}

export default ProgressLine;