import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {DatePicker} from "antd";
import 'moment/locale/ru';
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";

const Dates = styled.div(({theme: {titled_date_picker}}) => css`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  font-weight: 600;
`);

const Date = styled.div(({theme: {titled_date_picker}}) => css`
  margin: 0 .2em;
  cursor: pointer;
  font-weight: 600;
  color: ${titled_date_picker.activeColor};
`);

const Title = styled.div(({theme: {titled_date_picker}}) => css`
  font-weight: bold;
  letter-spacing: 0.03em;
`);

const StyledDatePicker = styled(DatePicker)(({theme: {titled_date_picker}}) => css`
  opacity: 0;
  position: absolute;
  z-index: -100;
  bottom: 0;
`);

export const StyledTitledDatePicker = styled.div(({theme: {titled_date_picker}}) => css`
  color: ${titled_date_picker.color};
`);

type TitledDatePickerProps = {
    title?: any,
    values?: any[],
    onChange?: (values: any[]) => any
}

function TitledDatePicker({title, values = [moment(), moment()], onChange}: TitledDatePickerProps) {
    const [pickerOpen1, setPickerOpen1] = useState<boolean>(false);
    const [pickerOpen2, setPickerOpen2] = useState<boolean>(false);
    const [pickerValues, setPickerValues] = useState<any>(values);

    const disabledDate1 = (current: any) => {
        return current > pickerValues[1];
    }

    const disabledDate2 = (current: any) => {
        return current < pickerValues[0];
    }

    useEffect(() => {
        onChange && onChange(pickerValues);
        // eslint-disable-next-line
    }, [pickerValues])

    return (
        <StyledTitledDatePicker>
            <Title>{title}</Title>
            <Dates>
                <StyledDatePicker
                    disabledDate={disabledDate1}
                    value={pickerValues[0]} locale={locale} open={pickerOpen1} onChange={value => {
                    setPickerOpen1(false)
                    setPickerValues([value, pickerValues[1]])
                }}/>
                <StyledDatePicker
                    disabledDate={disabledDate2}
                    value={pickerValues[1]} locale={locale} open={pickerOpen2} onChange={value => {
                    setPickerOpen2(false)
                    setPickerValues([pickerValues[0], value])
                }}/>
                с <Date
                onClick={() => {
                    setPickerOpen1(!pickerOpen1)
                    setPickerOpen2(false)
                }}>{moment(pickerValues[0]).format("DD.MM.YYYY")}</Date> по <Date
                onClick={() => {
                    setPickerOpen2(!pickerOpen2)
                    setPickerOpen1(false)
                }}>{moment(pickerValues[1]).format("DD.MM.YYYY")}</Date>
            </Dates>
        </StyledTitledDatePicker>
    );
}

export default TitledDatePicker;