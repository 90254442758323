import React from 'react';
import styled, {css} from "styled-components";

const StyledGreenStatus = styled.svg(({theme}: any) => css`
  circle {
    fill: ${theme.name === "light_theme" ? "#effff4" : "#182e1f"};
  }
`);

function GreenStatus(props: any) {
    return (
        <StyledGreenStatus {...props} width="28" height="28" viewBox="0 0 28 28" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="14" fill={"rgba(8,98,36,0.5)"}/>
            <path
                d="M16.04 11.5925L12.8225 14.8175L11.585 13.58C11.5178 13.5015 11.435 13.4377 11.342 13.3927C11.2489 13.3477 11.1476 13.3224 11.0443 13.3184C10.941 13.3144 10.838 13.3318 10.7418 13.3695C10.6455 13.4072 10.5581 13.4644 10.485 13.5375C10.4119 13.6106 10.3547 13.698 10.317 13.7943C10.2793 13.8905 10.2619 13.9935 10.2659 14.0968C10.2699 14.2001 10.2952 14.3014 10.3402 14.3945C10.3852 14.4875 10.449 14.5703 10.5275 14.6375L12.29 16.4075C12.3601 16.477 12.4432 16.532 12.5346 16.5693C12.626 16.6067 12.7238 16.6256 12.8225 16.625C13.0193 16.6242 13.2078 16.5461 13.3475 16.4075L17.0975 12.6575C17.1678 12.5878 17.2236 12.5048 17.2617 12.4134C17.2998 12.322 17.3194 12.224 17.3194 12.125C17.3194 12.026 17.2998 11.928 17.2617 11.8366C17.2236 11.7452 17.1678 11.6622 17.0975 11.5925C16.957 11.4528 16.7669 11.3744 16.5688 11.3744C16.3706 11.3744 16.1805 11.4528 16.04 11.5925ZM14 6.5C12.5166 6.5 11.0666 6.93987 9.83323 7.76398C8.59986 8.58809 7.63856 9.75943 7.07091 11.1299C6.50325 12.5003 6.35472 14.0083 6.64411 15.4632C6.9335 16.918 7.64781 18.2544 8.6967 19.3033C9.7456 20.3522 11.082 21.0665 12.5368 21.3559C13.9917 21.6453 15.4997 21.4968 16.8701 20.9291C18.2406 20.3614 19.4119 19.4001 20.236 18.1668C21.0601 16.9334 21.5 15.4834 21.5 14C21.5 13.0151 21.306 12.0398 20.9291 11.1299C20.5522 10.2199 19.9997 9.39314 19.3033 8.6967C18.6069 8.00026 17.7801 7.44781 16.8701 7.0709C15.9602 6.69399 14.9849 6.5 14 6.5ZM14 20C12.8133 20 11.6533 19.6481 10.6666 18.9888C9.67989 18.3295 8.91085 17.3925 8.45673 16.2961C8.0026 15.1997 7.88378 13.9933 8.11529 12.8295C8.3468 11.6656 8.91825 10.5965 9.75736 9.75736C10.5965 8.91824 11.6656 8.3468 12.8295 8.11529C13.9933 7.88378 15.1997 8.0026 16.2961 8.45672C17.3925 8.91085 18.3295 9.67988 18.9888 10.6666C19.6481 11.6533 20 12.8133 20 14C20 15.5913 19.3679 17.1174 18.2426 18.2426C17.1174 19.3679 15.5913 20 14 20Z"
                fill="#37D500"/>
        </StyledGreenStatus>
    );
}

export default GreenStatus;