import React from 'react';
import styled, {css} from "styled-components";

const StyledReportMenuSvg = styled.svg(({theme}: any) => css`
  circle {
    fill: ${theme.name === "light_theme" ? "#C5DBFF" : "#1d3252"};
  }
`);

function ReportMenuSvg(props:any) {
    return (
        <StyledReportMenuSvg {...props} width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="42" cy="42" r="42" fill="#C5DBFF"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M23.1875 33.25C23.1875 27.6926 27.6926 23.1875 33.25 23.1875H50.75C56.3074 23.1875 60.8125 27.6926 60.8125 33.25V50.75C60.8125 56.3074 56.3074 60.8125 50.75 60.8125H33.25C27.6926 60.8125 23.1875 56.3074 23.1875 50.75V33.25ZM33.6875 50.75C33.6875 51.4749 34.2751 52.0625 35 52.0625C35.7249 52.0625 36.3125 51.4749 36.3125 50.75V45.5C36.3125 44.7751 35.7249 44.1875 35 44.1875C34.2751 44.1875 33.6875 44.7751 33.6875 45.5V50.75ZM42 52.0625C41.2751 52.0625 40.6875 51.4749 40.6875 50.75V33.25C40.6875 32.5251 41.2751 31.9375 42 31.9375C42.7249 31.9375 43.3125 32.5251 43.3125 33.25V50.75C43.3125 51.4749 42.7249 52.0625 42 52.0625ZM47.6875 50.75C47.6875 51.4749 48.2751 52.0625 49 52.0625C49.7249 52.0625 50.3125 51.4749 50.3125 50.75V38.5C50.3125 37.7751 49.7249 37.1875 49 37.1875C48.2751 37.1875 47.6875 37.7751 47.6875 38.5V50.75Z"
                  fill="#0D6BFF"/>
        </StyledReportMenuSvg>
    );
}

export default ReportMenuSvg;