import React from 'react';
import styled, {css} from "styled-components";
import MainSvg from "../../images/MainSVG";
import JerrycanSvg from "../../images/JerrycanSVG";
import CashboxSvg from "../../images/CashboxSVG";
import ReportSvg from "../../images/ReportSVG";
import JournalSvg from "../../images/JournalSVG";
import {Link, useHistory} from "react-router-dom";

const NavItem = styled<any>(Link)(({theme: {menu}, isactive}) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 10px;
  color: ${isactive ? menu.active_color : menu.color} !important;
  padding: 10px 0 4px 0;
  margin-bottom: 33px;

  &:first-child {
    border-radius: 20px 0 0 0;
  }

  &:last-child {
    border-radius: 0 20px 0 0;
  }

  svg {
    margin-bottom: 4px;

    path {
      fill: ${isactive ? menu.active_color : menu.color} !important;
    }
  }
`);

const StyledMenu = styled.div<any>(({theme: {menu}, isactive}) => css`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background: ${menu.background};
  border-radius: 20px 20px 0 0;
`);

function Menu() {
    const history = useHistory();
    return (
        <StyledMenu>
            <NavItem isactive={history.location.pathname === "/dashboard" ? 1 : 0} to={"/dashboard"}><MainSvg/> Главная</NavItem>
            <NavItem isactive={history.location.pathname.includes("fuel") ? 1 : 0}
                     to={"/fuel"}><JerrycanSvg/> Топливо</NavItem>
            <NavItem isactive={history.location.pathname.includes("cash") ? 1 : 0}
                     to={"/cash"}><CashboxSvg/> Касса</NavItem>
            <NavItem isactive={history.location.pathname.includes("reports") ? 1 : 0}
                     to={"/reports"}><ReportSvg/> Отчеты</NavItem>
            <NavItem isactive={history.location.pathname.includes("journal") ? 1 : 0}
                     to={"/journal"}><JournalSvg/> Журнал</NavItem>
        </StyledMenu>
    );
}

export default Menu;