import React from 'react';
import {SwipeAction} from "antd-mobile";
import RoundBlock from "../DataView/RoundBlock";
import VoucherSvg from "../../svg-components/VoucherSvg";
import Capsule from "../DataView/Capsule";
import {BurgerAdditional} from "../DataView/BurgerAdditional";
import moment from "moment/moment";
import styled, {css} from "styled-components";
import {ruCurrencyFormat} from "../../utils/ruCurrencyFormat";

const BlockWrap = styled.div(({theme: {round_block}}) => css`
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: ${round_block.boxShadow};
`);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Text = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 700;
`;

const Status = styled.div(({theme: {vouchers}}) => css`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${vouchers.greenColor};
  justify-content: center;
  padding: 4px 10px;
  color: ${vouchers.greenColor};

  &.used {
    color: ${vouchers.redColor};
    border: 1px solid ${vouchers.redColor};
  }
`);


const Statuses = styled.div(({theme: {vouchers}}) => css`
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

`);

const Additional = styled.div(({theme: {vouchers}}) => css`
  display: flex;
  font-weight: 500;
  font-size: 10px;
  flex-direction: column;
  color: ${vouchers.additionalColor};
  margin-top: 4px;
`);


function VoucherList({data, deleteVoucher}: any) {

    return (
        <div>
            {data?.map((el: any, index: number) => (
                <BlockWrap key={index}>
                    <SwipeAction rightActions={[
                        {
                            key: 'delete',
                            text: 'Удалить',
                            color: 'danger',
                            onClick: async () => {
                                deleteVoucher(el.Id)
                            },
                        },
                    ]}>
                        <RoundBlock>
                            <VoucherSvg/>
                            <Content>
                                <Text>Купон на сдачу</Text>
                                <Additional>
                                    №{el.Id}
                                    <Statuses>
                                        <Status className={!el.Available ? "used" : ""}>
                                            {!el.Available ? "Использован" : "Выдан"}
                                        </Status>
                                        {el.Type !== 0 && <Status className={"used"}>
                                            Технический
                                        </Status>}
                                    </Statuses>
                                </Additional>
                            </Content>
                            <Capsule>{ruCurrencyFormat(el.Total)} ₽</Capsule>
                            <BurgerAdditional>{moment(el.Date).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                        </RoundBlock>
                    </SwipeAction>
                </BlockWrap>
            ))}
        </div>
    );
}

export default VoucherList;