import React from 'react';
import styled, {css} from "styled-components";

const StyledCouponMenuSvg = styled.svg(({theme}: any) => css`
  circle {
    fill: ${theme.name === "light_theme" ? "#D2D6FB" : "#111535"};
  }
  path {
    fill: ${theme.name === "light_theme" ? "#0013BA" : "#9cbdf0"};
  }
`);

function CouponMenuSvg(props:any) {
    return (
        <StyledCouponMenuSvg {...props} width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="42" cy="42" r="42" fill="#D2D6FB"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M31.5 26.6861C26.9091 26.6861 23.1875 30.4077 23.1875 34.9986L23.1875 37.1389C23.1875 38.1287 23.8775 38.8449 24.6239 39.1023C25.8265 39.517 26.6875 40.6589 26.6875 41.9986C26.6875 43.3382 25.8265 44.4802 24.6239 44.8948C23.8775 45.1522 23.1875 45.8685 23.1875 46.8582V48.9986C23.1875 53.5894 26.9091 57.3111 31.5 57.3111H52.5C57.0909 57.3111 60.8125 53.5894 60.8125 48.9986L60.8125 46.8582C60.8125 45.8685 60.1225 45.1522 59.3761 44.8948C58.1735 44.4802 57.3125 43.3382 57.3125 41.9986C57.3125 40.6589 58.1735 39.517 59.3761 39.1023C60.1225 38.8449 60.8125 38.1287 60.8125 37.1389L60.8125 34.9986C60.8125 30.4077 57.0909 26.6861 52.5 26.6861L31.5 26.6861ZM37.1677 39.1256C37.7087 39.6666 38.5857 39.6666 39.1267 39.1256C39.6676 38.5847 39.6676 37.7076 39.1267 37.1667C38.5857 36.6257 37.7087 36.6257 37.1677 37.1667C36.6268 37.7076 36.6268 38.5847 37.1677 39.1256ZM46.8316 46.8306C46.2907 47.3715 45.4136 47.3715 44.8727 46.8306C44.3317 46.2896 44.3317 45.4126 44.8727 44.8716C45.4136 44.3306 46.2907 44.3306 46.8316 44.8716C47.3726 45.4126 47.3726 46.2896 46.8316 46.8306ZM46.8453 39.009C47.3579 38.4964 47.3579 37.6654 46.8453 37.1528C46.3327 36.6403 45.5017 36.6403 44.9891 37.1528L37.1533 44.9887C36.6407 45.5012 36.6407 46.3322 37.1533 46.8448C37.6659 47.3574 38.4969 47.3574 39.0095 46.8448L46.8453 39.009Z"
                  fill="#0013BA"/>
        </StyledCouponMenuSvg>
    );
}

export default CouponMenuSvg;