import React from 'react';
import ArrowDownSvg from "../../images/ArrowDownSVG";
import styled, {css} from "styled-components";
import history from "../../utils/history";
import History from "../../utils/history";
import {connect} from "react-redux";
import OptionsMenuSvg from "../../images/OptionsMenuSvg";
import {Tooltip} from "antd";

const StyledDashboardHeaderContent = styled.div(({theme: {dashboard_header_content}}) => css`
  display: flex;
  align-items: center;
  line-height: 16px;
  color: ${dashboard_header_content.color};
  overflow: hidden;

  .title_wrap {
    display: flex;
    overflow: hidden;
    align-items: center;
    background: ${dashboard_header_content.title_background};
    padding: 10px 18px;
    border-radius: 8px;
    width: 100%;
    margin: 0 18px;
  }

  .title {
    font-weight: 600;
    width: 100%;
    overflow: hidden;
  }

  .terminal {
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
  }
`);

const Action = styled.div(({theme: {tridot}}) => css`
  display: flex;
  align-items: center;
`);

type DashboardHeaderContentProps = {
    station?: any
}

const PopoverContent = styled.div`
  overflow-wrap: anywhere;
  display: flex;
`;

function DashboardHeaderContent({station}: DashboardHeaderContentProps) {
    return (
        <StyledDashboardHeaderContent>
            <div className="title_wrap" onClick={() => history.push("/")}>
                <div className="title">
                    Терминал:<br/>
                    <Tooltip trigger={['hover']} title={<PopoverContent>
                        {station ? (station.Name || station.Address || station.Id) : "NOT_FOUND"}
                    </PopoverContent>}>
                        <div className="terminal">
                            {station ? (station.Name || station.Address || station.Id) : "NOT_FOUND"}
                        </div>
                    </Tooltip>
                </div>
                <ArrowDownSvg/>
            </div>
            <Action onClick={() => History.push("/settings")}>
                <OptionsMenuSvg/>
            </Action>
        </StyledDashboardHeaderContent>
    );
}

const mapStateToProps = (store: any) => {
    return {
        station: store.pageReducer.stations.find((el: any) => el.Id === store.pageReducer.selectedStation)
    }
}

export default connect(mapStateToProps, null)(DashboardHeaderContent);