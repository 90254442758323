import React from 'react';

function FuelBackArrowSvg(props: any) {
    return (
        <svg {...props} width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.75743 10L10.9524 3.805C11.2784 3.47712 11.4613 3.03358 11.4613 2.57125C11.4613 2.10893 11.2784 1.66539 10.9524 1.3375C10.7897 1.17348 10.5962 1.04329 10.3829 0.954441C10.1697 0.865596 9.94095 0.819855 9.70993 0.819855C9.47891 0.819855 9.25018 0.865596 9.03692 0.954441C8.82367 1.04329 8.63012 1.17348 8.46743 1.3375L1.04743 8.7575C0.883407 8.92019 0.753218 9.11374 0.664373 9.32699C0.575528 9.54025 0.529785 9.76898 0.529785 10C0.529785 10.231 0.575528 10.4598 0.664373 10.673C0.753218 10.8863 0.883407 11.0798 1.04743 11.2425L8.46743 18.75C8.63095 18.9122 8.82488 19.0405 9.0381 19.1276C9.25131 19.2147 9.47962 19.2588 9.70993 19.2575C9.94024 19.2588 10.1686 19.2147 10.3818 19.1276C10.595 19.0405 10.7889 18.9122 10.9524 18.75C11.2784 18.4221 11.4613 17.9786 11.4613 17.5163C11.4613 17.0539 11.2784 16.6104 10.9524 16.2825L4.75743 10Z"
                fill="#959595"/>
        </svg>
    );
}

export default FuelBackArrowSvg;