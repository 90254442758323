import React from 'react';
import styled, {css} from "styled-components";
import {StyledCapsule} from "./Capsule";

export const StyledRoundBlock = styled.div(({theme: {round_block}}) => css`
  background: ${round_block.background};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-weight: 600;
  min-height: 60px;
  color: ${round_block.color};
  position: relative;
  box-shadow: ${round_block.boxShadow};
  overflow: hidden;
  font-size: 12px;

  ${StyledCapsule} {
    background: ${round_block.capsuleBackground};
    font-size: 12px;
    min-height: 24px;
  }
`);

type RoundBlockProps = {
    children: any,
    onClick?: () => any,
    className?: string
}

function RoundBlock({children, onClick, ...props}: RoundBlockProps) {
    return (
        <StyledRoundBlock {...props} onClick={onClick}>
            {children}
        </StyledRoundBlock>
    );
}

export default RoundBlock;