import React from 'react';

function PlusSvg(props) {
    return (
        <svg {...props} width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 0.708984V6.63784M5 12.5667V6.63784M5 6.63784H0H10" stroke="#0D6BFF" strokeWidth="1.5"/>
        </svg>
    );
}

export default PlusSvg;