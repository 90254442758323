import React, {useEffect, useState} from 'react';
import TitledDatePicker from "../components/DataView/TitledDatePicker";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import styled, {css} from "styled-components";
import Select from "../components/DataView/Select";
import {message, Modal, Select as AntSelect, Spin} from "antd";
import axios from "axios";
import moment from "moment";
import {api} from "../config";
import {connect} from "react-redux";
import * as _ from "lodash";
import {StyledBurger} from "../components/DataView/Burger";
import getTimeZone from "../utils/getTimeZone";
import {Button} from "../components/Form/Button";
import CollectorSVG from "../images/CollectorSVG";
import {ruCurrencyFormat} from "../utils/ruCurrencyFormat";
import OldTextSlider from "../components/DataView/OldTextSlider";
import {StyledTextSlider} from '../components/styled/StyledTextSlider';

const {Option} = AntSelect;

const ReportText = styled.div(({theme: {reports}}) => css`
    margin-top: 25px;
    font-weight: 500;
    text-align: right;
    font-size: 10px;
    color: #959595;
`);

const Count = styled.div(({theme: {reports}}) => css`
    color: ${reports.count_color};
    font-size: 16px;
    font-weight: bold;
`);

const Top = styled.div(({theme: {reports}}) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 25px;
`);

const StyledReports = styled.div(({theme: {reports}}) => css`
    display: flex;
    flex-direction: column;

    .ant-modal-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-modal {
        top: 0;

        .ant-modal-content {
            border-radius: 8px;
        }

        .ant-modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        h1 {
            color: #757575;
            font-size: 14px;
            font-style: normal;
            text-align: center;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.28px;
            margin-bottom: 15px;
        }

        p {
            color: #202020;
            text-align: center;
            font-size: 19px;
            max-width: 170px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.57px;
            text-transform: uppercase;
        }
    }

    ${StyledTextSlider} {
        margin-top: 24px;
    }

    table {
        border-radius: 10px;
        border-collapse: collapse;
        margin-top: 35px;

        thead {
            th {
                background: #1C74FF;
                overflow: hidden;
                text-align: left;
                color: #bfcbf1;
                padding: 10px 10px 10px 0;
                font-size: 10px;

                &:first-child {
                    padding-left: 8px;
                    border-radius: 4px 0 0 4px;
                }

                &:last-child {
                    border-radius: 0 4px 4px 0;
                }
            }
        }

        tr {
            td {
                font-size: 10px;
                padding: 10px 10px 10px 0;
                color: ${reports.tableColor};
                overflow-wrap: anywhere;

                &:first-child {
                    padding-left: 8px;
                }
            }
        }
    }

    ${StyledBurger} {
        max-width: 80%;
    }

    text {
        fill: ${reports.areaTextColor};
    }
`);

type ReportsProps = {
    station?: any
}

function Reports({station}: ReportsProps) {

    const [type, setType] = useState<"type1" | "type2" | "type3" | "type4">("type1");
    const [request] = useState("def");
    const [dates, setDates] = useState([moment(), moment()]);

    const [data, setData] = useState<any>([]);
    const [data2, setData2] = useState<any>([]);

    const onRequestChange: any = (value: any) => {

        let name = "";
        switch (type) {
            case "type1":
                name = "checkouts"
                break;
            case "type2":
                name = "fillings"
                break;
            case "type3":
                name = "detail"
                break;
            case "type4":
                name = "service"
                break;
        }

        if (value === "download") {
            window.open(`${api}/user/stations/${station.Id}/${name}/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}/email?zone=${getTimeZone()}&pdf=true`, "_blank")
        } else {
            axios(`${api}/user/stations/${station.Id}/${name}/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}/email?zone=${getTimeZone()}`).then(() => {
                message.success("Отчет успешно отправлен!")
            }).catch(({response}) => {
                message.error(response?.data || "Error")
            })
        }
    }

    const getData = () => {
        axios(`${api}/user/stations/${station.Id}/checkouts/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}?zone=${getTimeZone()}`).then(({data}) => {
            setData(data?.sort((el1: any, el2: any) => {
                return new Date(el1.Date).getTime() - new Date(el2.Date).getTime()
            }));
        }).catch(({response: {data: error}}) => {
            message.error(error);
        })
    }

    const getData2 = () => {
        axios(`${api}/user/stations/${station.Id}/fillings/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}?zone=${getTimeZone()}`).then(({data}) => {
            setData2(data?.sort((el1: any, el2: any) => {
                return new Date(el1.Date).getTime() - new Date(el2.Date).getTime()
            }).map((el: any) => {
                return {...el, Color: "#" + ('00000' + (Math.random() * (1 << 24) | 0).toString(16)).slice(-6)}
            }));
        }).catch(({response: {data: error}}) => {
            message.error(error);
        })
    }

    const [availableTanks, setAvailableTanks] = useState<any[]>([]);
    const [selectedTank, setSelectedTank] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    // const [iframeLink, setIframeLink] = useState<string>("")

    const [collectorVisible, setCollectorVisible] = useState<boolean>(false);

    const [collectorName, setCollectorName] = useState<string>("");

    useEffect(() => {
        if (collectorName === "")
            setCollectorVisible(false)
        else
            setCollectorVisible(true)
    }, [collectorName, collectorVisible])


    useEffect(() => {
        switch (type) {
            case "type1":
                getData()
                break;
            case "type2":
                getData2()
                break;
            case "type3":
                setLoading(true)
                axios(`${api}/user/stations/${station.Id}/detail/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}`).then(() => {
                    setLoading(false)
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                })
                break;
            case "type4":
                setLoading(true)
                axios(`${api}/user/stations/${station.Id}/service/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}`).then(() => {
                    setLoading(false)
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                })
                break;
        }
        // eslint-disable-next-line
    }, [dates, type])

    useEffect(() => {
        // console.log(data2);
        // console.log(data2.map((el: any) => moment(el.Date).format("DD.MM.YYYY")));
        // console.log(_.uniq(data2.map((el: any) => moment(el.Date).format("DD.MM.YYYY"))));
        // console.log(_.uniqBy(data2, "Tank"));
        // console.log(_.map(availableTanks, "Tank"));
        setAvailableTanks(_.uniqBy(data2, "Tank"))
        setSelectedTank(0);
    }, [data2])

    return (
        <StyledReports>
            <Modal
                onCancel={() => setCollectorName("")}
                visible={collectorVisible}
                footer={null}
                getContainer={() => document.querySelector(`.${StyledReports.styledComponentId}`)!}>
                <h1>Инкассацию провел</h1>
                <p>{collectorName}</p>
            </Modal>
            <Top>
                <Select value={type} onChange={setType}>
                    <Option value={"type1"}>Инкассации</Option>
                    <Option value={"type2"}>Поступления НП</Option>
                    <Option value={"type3"}>Детальный</Option>
                    <Option value={"type4"}>Служебный</Option>
                </Select>
                <Select className={"blue"} value={request} onChange={onRequestChange}>
                    <Option style={{display: "none"}} value={"def"} disabled>Экспорт</Option>
                    <Option value={"test"}>Отправить</Option>
                    <Option value={"download"}>Скачать</Option>
                    {/*<Option value={"test2"}>Скачать</Option>*/}
                </Select>
            </Top>
            <TitledDatePicker values={dates} onChange={setDates}
                              title={(() => {
                                  switch (type) {
                                      case "type1":
                                          return "ИНКАССАЦИИ"
                                      case "type2":
                                          return "ПОСТУПЛЕНИЯ НП"
                                      case "type3":
                                          return "ДЕТАЛЬНЫЙ"
                                      case "type4":
                                          return "СЛУЖЕБНЫЙ"
                                  }
                              })()}/>
            {((!!data?.length && type === "type1") || (!!data2.length && type === "type2")) && <ReportText>
                {type === "type1" ? "В сумме за период:" : "В сумме за период:"}
                <Count>{type === "type1" ?
                    `${ruCurrencyFormat(_.sumBy(data, ((el: any) => el.Total)))} ₽` :
                    `${(_.sumBy(data2.filter((el: any) => availableTanks?.[selectedTank]?.Tank === el.Tank), ((el: any) => el.VolumeTTN === 0 ? el.VolumeCalculated : el.VolumeTTN)))} л.`}
                    {/*`${(_.sumBy(data2.filter((el: any) => availableTanks?.[selectedTank]?.Tank === el.Tank), ((el: any) => el.VolumeTTN === 0 ? el.VolumeCalculated : el.VolumeTTN)) / (Math.abs(moment(dates[0].format("YYYY-MM-DD")).diff(dates[1].format("YYYY-MM-DD"), 'days')) + 1))?.toFixed(0)}л.`}*/}
                </Count>
            </ReportText>}
            {type === "type1" && !!data?.length ? <ResponsiveContainer width="100%" height={150}>
                <AreaChart data={data} margin={{
                    left: -25
                }}>
                    <defs>
                        <linearGradient id="MyGradient" gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#6DB9FF"/>
                            <stop offset="100%" stopColor="transparent"/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeWidth={.5}/>
                    <XAxis tick={{fontSize: 6}} dataKey="Date" tickFormatter={value => moment(value).format("DD.MM")}
                           strokeWidth={.5}/>
                    <YAxis tick={{fontSize: 6}} unit={"₽"} strokeWidth={.5}/>
                    <Tooltip
                        labelFormatter={((label) => (["Дата: " + moment(label).format("DD.MM")]))}
                        formatter={(value: any, name: any, props: any) => ([ruCurrencyFormat(value) + "₽", "Инкассация"])}/>
                    <Area type="linear" dataKey="Total" stroke="#2e86fe" fill="url(#MyGradient)"/>
                </AreaChart>
            </ResponsiveContainer> : (!!data2?.length && type === "type2" &&
                <ResponsiveContainer width="100%" height={150}>
                    <AreaChart data={data2?.filter((el: any) => availableTanks?.[selectedTank]?.Tank === el.Tank)}
                               margin={{
                                   left: -25
                               }}>
                        <defs>
                            <linearGradient id="MyGradient" gradientTransform="rotate(90)">
                                <stop offset="0%" stopColor="#6DB9FF"/>
                                <stop offset="100%" stopColor="#131723"/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeWidth={.5}/>
                        <XAxis tick={{fontSize: 6}} dataKey="Date"
                               tickFormatter={value => moment(value).format("DD.MM")}
                               strokeWidth={.5}/>
                        <YAxis tick={{fontSize: 6}} unit={"л."} strokeWidth={.5}/>
                        <Tooltip
                            labelFormatter={((label) => (["Дата: " + moment(label).format("DD.MM")]))}
                            formatter={(value: any, name: any, props: any) => ([value + "л.", `Резервуар`])}/>
                        <Area animationDuration={500} type="linear" dataKey={(value) => {
                            return value.VolumeTTN === 0 ? value.VolumeCalculated : value.VolumeTTN
                        }} stroke="#2e86fe"
                              fill="url(#MyGradient)"/>

                        {/*<Bar type="linear" dataKey="Volume" stroke="#2e86fe" fill="url(#MyGradient)">*/}
                        {/*    {data2.map((entry: any, index: number) => (*/}
                        {/*        <Cell key={`cell-${index}`} fill={entry.Color}/>*/}
                        {/*    ))}*/}
                        {/*</Bar>*/}
                    </AreaChart>
                </ResponsiveContainer>)}
            {availableTanks.length > 0 && type === "type2" &&
                <OldTextSlider additional={`Резервуар ${availableTanks[selectedTank]?.Tank}`}
                               value={availableTanks[selectedTank]?.Tank}
                               displayValue={availableTanks[selectedTank]?.Fuel}
                               onChange={value => setSelectedTank(availableTanks?.findIndex((el: any) => el.Tank === value))}
                               values={_.map(availableTanks, "Tank")}/>}
            {/*{type !== "type1" && <Burger>*/}
            {/*    {data2.map((el: any, index: number) => (*/}
            {/*        <BurgerItem key={index}>*/}
            {/*            <div><StorageLineSvg color={el.Color} style={{marginRight: 10}}/>{el.Tank} ({el.Fuel})</div>*/}
            {/*        </BurgerItem>*/}
            {/*    ))}*/}
            {/*</Burger>}*/}
            {['type1', 'type2'].includes(type) && <table>
                <thead>
                {type === "type1" ? <tr>
                    <th>Номер</th>
                    <th>Дата</th>
                    <th>Сумма</th>
                    <th>ФИО</th>
                </tr> : <tr>
                    <th>Дата</th>
                    <th>Вид топлива</th>
                    <th>Литры</th>
                    <th>Тн</th>
                    <th>Плот.</th>
                    <th>Поставщик</th>
                </tr>}
                </thead>
                <tbody>
                {type === "type1" ? data?.map((el: any, index: number) => <tr key={index}>
                    <td>№ {el.Id}</td>
                    <td>от {moment(el.Date).format("DD.MM.YYYY")} <span
                        style={{color: "#50576A"}}>{moment(el.Date).format("HH:mm")}</span></td>
                    <td>{ruCurrencyFormat(el.Total)} ₽</td>
                    <td><CollectorSVG onClick={() => setCollectorName(el.Collector)}
                                      style={{top: 2, position: "relative"}}/></td>
                </tr>) : data2?.filter((el: any) => availableTanks?.[selectedTank]?.Tank === el.Tank)?.map((el: any, index: number) =>
                    <tr key={index}>
                        <td>{moment(el.Date).format("DD.MM.YYYY")}</td>
                        <td>{el.Fuel}</td>
                        <td>{el.VolumeTTN === 0 ? el.VolumeCalculated : el.VolumeTTN}</td>
                        <td>{((el.WeightTTN === 0 ? el.WeightCalculated : el.WeightTTN) / 1000).toFixed(1)}</td>
                        <td>{(el.DensityTTN === 0 ? el.DensityCalculated : el.DensityTTN).toFixed(3)}</td>
                        <td style={{width: "20%"}}>{el.Provider}</td>
                        {/*<td>4,2 Тн</td>*/}
                        {/*<td>ООО “УТК”</td>*/}
                        {/*<td>0,845</td>*/}
                    </tr>)}
                </tbody>
            </table>}
            {/*{["type3", "type4"].includes(type) &&*/}
            {/*    <iframe color={"#fff"} frameBorder={0} title={type} src={iframeLink}></iframe>}*/}
            {["type3", "type4"].includes(type) && <span style={{marginTop: 10}}>
                {loading ? <Spin/> :
                    <Button>Отчет сформирован</Button>}
            </span>}
        </StyledReports>
    );
}

const mapStateToProps = (store: any) => {
    return {
        station: store.pageReducer.stations.find((el: any) => el.Id === store.pageReducer.selectedStation)
    }
}
export default connect(mapStateToProps, null)(Reports);