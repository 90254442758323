import React from 'react';

function MainSvg(props) {
    return (
        <svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.50024 9.93839C1.50024 8.7142 2.06082 7.55742 3.02166 6.79886L8.52165 2.45676C9.97489 1.30948 12.0256 1.30948 13.4788 2.45677L18.9788 6.79886C19.9396 7.55742 20.5002 8.7142 20.5002 9.93839V16.5C20.5002 18.7091 18.7093 20.5 16.5002 20.5H15.0002C14.4479 20.5 14.0002 20.0522 14.0002 19.5V16.5C14.0002 15.3954 13.1048 14.5 12.0002 14.5H10.0002C8.89566 14.5 8.00023 15.3954 8.00023 16.5V19.5C8.00023 20.0522 7.55251 20.5 7.00023 20.5H5.50024C3.2911 20.5 1.50024 18.7091 1.50024 16.5L1.50024 9.93839Z"
                fill="#9CABC0"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.0141 3.04542C11.8333 2.11325 10.1671 2.11325 8.98638 3.04542L3.48639 7.38752C2.70571 8.00385 2.25024 8.94373 2.25024 9.93838V16.5C2.25024 18.2949 3.70531 19.75 5.50023 19.75H7.00023C7.1383 19.75 7.25023 19.638 7.25023 19.5V16.5C7.25023 14.9812 8.48144 13.75 10.0002 13.75H12.0002C13.519 13.75 14.7502 14.9812 14.7502 16.5V19.5C14.7502 19.638 14.8621 19.75 15.0002 19.75H16.5002C18.2951 19.75 19.7502 18.2949 19.7502 16.5V9.93838C19.7502 8.94373 19.2947 8.00385 18.514 7.38752L13.0141 3.04542ZM8.05692 1.8681C9.78263 0.505696 12.2178 0.505698 13.9435 1.8681L19.4435 6.2102C20.5845 7.11099 21.2502 8.48466 21.2502 9.93838V16.5C21.2502 19.1233 19.1236 21.2499 16.5002 21.2499H15.0002C14.0337 21.2499 13.2502 20.4664 13.2502 19.5V16.5C13.2502 15.8096 12.6906 15.25 12.0002 15.25H10.0002C9.30987 15.25 8.75023 15.8096 8.75023 16.5V19.5C8.75023 20.4664 7.96673 21.2499 7.00023 21.2499H5.50023C2.87689 21.2499 0.750244 19.1233 0.750244 16.5V9.93838C0.750244 8.48466 1.41593 7.11099 2.55693 6.2102L8.05692 1.8681Z"
                  fill="#9CABC0"/>
        </svg>
    );
}

export default MainSvg;