import React, {useState} from 'react';
import styled, {css} from "styled-components";
import RoundBlock, {StyledRoundBlock} from '../components/DataView/RoundBlock';
import store from "../redux/store";
import {logoutAction} from "../redux/actions/loginActions";
import axios from "axios";
import {api} from "../config";
import {connect} from "react-redux";
import {Button as AntdButton, message, Modal} from "antd";
import History from "../utils/history";
import {getStationsAction} from "../redux/actions/pageActions";
import TechnologicalModal from "../components/Modals/TechnologicalModal";
import RelayModal from "../components/Modals/RelayModal";

const Button = styled(AntdButton)(({theme: {options}}: any) => css`
    border: none;
    color: ${options.buttonColor} !important;
    font-size: 14px;
    width: 100%;
    border-radius: 10px;
    min-height: 55px;
    box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.69);
    background: ${options.buttonBackground} !important;

    span {
        font-weight: 600;
    }

    &:not(:last-child) {
        margin-bottom: 12px;
    }

    &.cancel {
        background: #64BDFF !important;
        color: #343a50 !important;
    }
`);

const StyledOptions = styled.div(({theme: {options}}: any) => css`
    padding-top: 20px;

    .ant-modal-content {
        background: transparent;
        box-shadow: none;
    }

    > h1 {
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        color: ${options.color};
        margin-bottom: 55px;
    }

    ${StyledRoundBlock} {
        font-size: 14px;
        padding: 25px 10px;

        svg {
            margin-left: 10px;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
`);

type OptionsProps = {
    station?: any
}

function Options({station}: OptionsProps) {

    const [technologicalModalVisible, setTechnologicalModalVisible] = useState<boolean>(false);
    const [relayModalVisible, setRelayModalVisible] = useState<boolean>(false);

    const doAction = (action: string, text: string) => {
        axios.put(`${api}/user/stations/${station.Id}/${action}`).then(() => {
            message.success(text + " прошла успешно!")
        })
    }

    const removeTerminal = () => {
        new (Modal as any).confirm({
            title: `Удаление терминала ${station.Id}`,
            onOk: () => axios.delete(`${api}/user/stations/${station.Id}`).then(() => {
                message.success("Терминал успешно удален!");
                store.dispatch(getStationsAction());
                History.push("/");
            }).catch(({response}) => {
                message.error(response?.data || "Error")
            }),
            okText: "Удалить",
            okType: 'danger',
            cancelText: 'Отмена'
        })
    }

    const [confirmation, setConfirmation] = useState<any>(false);

    const [relayStatus, setRelayStatus] = useState<boolean>(false);

    return (
        <StyledOptions>
            <TechnologicalModal
                station={station}
                visible={technologicalModalVisible}
                parentClass={StyledOptions.styledComponentId}
                setVisible={setTechnologicalModalVisible}
            />
            <RelayModal
                changeStatus={setRelayStatus}
                station={station}
                visible={relayModalVisible}
                parentClass={StyledOptions.styledComponentId}
                setVisible={setRelayModalVisible}
            />
            {confirmation === false ? <>
                <RoundBlock onClick={() => setConfirmation("reboot")}>Перезагрузка
                    терминала</RoundBlock>
                {/*<RoundBlock onClick={() => doAction("reboot", "Перезагрузка терминала")}>Перезагрузка*/}
                {/*    терминала</RoundBlock>*/}
                {/*<RoundBlock onClick={() => doAction("block", "Блокировка терминала")}>Блокировка терминала</RoundBlock>*/}
                <RoundBlock onClick={() => setConfirmation("block")}>Блокировка терминала</RoundBlock>
                <RoundBlock onClick={() => doAction("unblock", "Разблокировка терминала")}>Разблокировка
                    терминала</RoundBlock>
                <RoundBlock onClick={() => History.push("/status")}>Статус оборудования</RoundBlock>
                <RoundBlock onClick={removeTerminal}>Удалить терминал</RoundBlock>
                <RoundBlock onClick={() => setTechnologicalModalVisible(true)}>Технологический отпуск</RoundBlock>
                {relayStatus && <RoundBlock onClick={() => setRelayModalVisible(true)}>Реле</RoundBlock>}
                <RoundBlock onClick={() => store.dispatch(logoutAction())}>Выход</RoundBlock>
            </> : <>
                <h1>Подтвердить {confirmation === "block" ? "блокировку" : "перезагрузку"} терминала?</h1>
                <Button onClick={() => {
                    doAction(confirmation, confirmation === "block" ? "Блокировка терминала" : "Перезагрузка терминала");
                    setConfirmation(false);
                }}>Да</Button>
                <Button className={"cancel"} onClick={() => setConfirmation(false)}>Отмена</Button>
            </>}
        </StyledOptions>
    );
}

const mapStateToProps = (store: any) => {
    return {
        station: store.pageReducer.stations.find((el: any) => el.Id === store.pageReducer.selectedStation)
    }
}
export default connect(mapStateToProps, null)(Options);
