import React, {useCallback, useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import TitledDatePicker, {StyledTitledDatePicker} from "../components/DataView/TitledDatePicker";
import Burger, {BurgerItem} from "../components/DataView/Burger";
import Capsule, {StyledCapsule} from "../components/DataView/Capsule";
import ProgressLine, {StyledProgressLine} from "../components/DataView/ProgressLine";
import moment from "moment";
import axios from "axios";
import {api} from "../config";
import {connect} from "react-redux";
import * as _ from "lodash";
import {sumBy} from "lodash";
import getTimeZone from "../utils/getTimeZone";
import {BurgerAdditional} from "../components/DataView/BurgerAdditional";
import OldTextSlider from "../components/DataView/OldTextSlider";
import {StyledTextSlider} from "../components/styled/StyledTextSlider";

const Block = styled.div(({theme: {dashboard}}) => css`
    &:not(:last-child) {
        margin-bottom: 13px;
    }

    h1 {
        color: ${dashboard.color};
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: bold;
    }

    ${StyledCapsule} {
        margin-bottom: 5px;
    }
`);

const StyledFuel = styled.div`
    ${StyledTitledDatePicker} {
        margin-bottom: 25px;
    }

    ${StyledTextSlider} {
        margin: 0 0 25px 0;
    }

    ${StyledProgressLine} {
        margin-bottom: 35px;
    }
`;

type FuelProps = {
    station?: any
}

function Fuel({station}: FuelProps) {
    const [dates, setDates] = useState([moment(), moment()]);
    const [data, setData] = useState([]);

    const totalString = "Реализация";

    const [fuels, setFuels] = useState<string[]>([]);
    const [sliderValue, setSliderValue] = useState(totalString);

    const {Volumes} = station?.Status;

    const currentVolume = Volumes?.find((el: any) => el.Fuel === sliderValue);
    const getData = () => {
        axios(`${api}/user/stations/${station.Id}/openings/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}?zone=${getTimeZone()}`).then(({data}) => {
            setData(data);
        })
    }

    useEffect(() => {
        setFuels(_.uniq(_.map(_.sortBy(data, "Fuel"), "Fuel")))
    }, [data])

    useEffect(() => {
        setSliderValue(totalString);
    }, [fuels])

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, [dates])

    const getTotalVolumesBy = useCallback((string: string) => {
        return Math.round(sumBy(Volumes?.filter((el: any) => fuels.includes(el.Fuel)), string))
    }, [fuels])

    return (
        <StyledFuel>
            <TitledDatePicker values={dates} onChange={setDates} title={"ПРОДАЖА"}/>
            {sliderValue &&
                <OldTextSlider value={sliderValue} onChange={setSliderValue} values={[totalString, ...fuels]}/>}
            {(currentVolume || (fuels.length > 0 && sliderValue === totalString)) &&
                <ProgressLine
                    values={sliderValue === totalString ? [getTotalVolumesBy("VolumeOut"), getTotalVolumesBy("VolumeMin")] : [Math.round(currentVolume?.VolumeOut), Math.round(currentVolume?.VolumeMin)]}
                    min={sliderValue === totalString ? getTotalVolumesBy("VolumeMin") : currentVolume?.VolumeMin}
                    current={sliderValue === totalString ? getTotalVolumesBy("VolumeOut") : Math.round(currentVolume.VolumeOut)}
                    total={sliderValue === totalString ? getTotalVolumesBy("Volume") : Math.round(currentVolume.Volume)}/>}

            <Block>
                <Burger>
                    <BurgerItem>Наличный
                        расчет <Capsule>{sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment === 0), "Volume").toFixed(2) : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 0), "Volume").toFixed(2)} л.</Capsule>
                        {/*<BurgerAdditional>10.10.2021. 21:58</BurgerAdditional>*/}
                        <BurgerAdditional>{moment(station.Status.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                    </BurgerItem>
                </Burger>
            </Block>
            <Block>
                <Burger>
                    <BurgerItem>Безналичный
                        расчет <Capsule>{sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && (el.Payment === 1 || el.Payment === 5)), "Volume").toFixed(2) : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && (el.Payment === 1 || el.Payment === 5)), "Volume").toFixed(2)} л.</Capsule>
                        {/*<BurgerAdditional>10.10.2021. 21:58</BurgerAdditional>*/}
                        <BurgerAdditional>{moment(station.Status.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                    </BurgerItem>
                </Burger>
            </Block>
            <Block>
                <Burger>
                    <BurgerItem>Использованные
                        купоны <Capsule>{sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment === 2), "Volume").toFixed(2) : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 2), "Volume").toFixed(2)} л.</Capsule>
                        <BurgerAdditional>{moment(station.Status.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                    </BurgerItem>
                </Burger>
            </Block>
            <Block>
                <Burger>
                    <BurgerItem>Технический
                        отпуск <Capsule>{sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment === 3), "Volume").toFixed(2) : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 3), "Volume").toFixed(2)} л.</Capsule>
                        <BurgerAdditional>{moment(station.Status.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                    </BurgerItem>
                </Burger>
            </Block>
            <Block>
                <Burger>
                    <BurgerItem>Топливные
                        карты <Capsule>{sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment === 4), "Volume").toFixed(2) : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 4), "Volume").toFixed(2)} л.</Capsule>
                        <BurgerAdditional>{moment(station.Status.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                    </BurgerItem>
                </Burger>
            </Block>
            <Block>
                <Burger>
                    <BurgerItem>СБП <Capsule>{sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && el.Payment === 6), "Volume").toFixed(2) : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 6), "Volume").toFixed(2)} л.</Capsule>
                        <BurgerAdditional>{moment(station.Status.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                    </BurgerItem>
                </Burger>
            </Block>
            {/*<Block>*/}
            {/*    <Burger>*/}
            {/*        <BurgerItem>Оплата вне*/}
            {/*            системы <Capsule>{_.sumBy(data.filter((el: any) => el.Fuel === sliderValue && el.Payment === 5), "Volume").toFixed(2)} л.</Capsule>*/}
            {/*            <BurgerAdditional>{moment(station.Status.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>*/}
            {/*        </BurgerItem>*/}
            {/*    </Burger>*/}
            {/*</Block>*/}
            <Block>
                <Burger>
                    <BurgerItem>Итого <Capsule>{sliderValue === totalString ? _.sumBy(data.filter((el: any) => fuels.includes(el.Fuel) && [0,
                        1,
                        2,
                        3,
                        4,
                        6,
                        5
                    ].includes(el.Payment)), "Volume").toFixed(2) : _.sumBy(data.filter((el: any) => el.Fuel === sliderValue && [0,
                        1,
                        2,
                        3,
                        4,
                        6,
                        5
                    ].includes(el.Payment)), "Volume").toFixed(2)} л.</Capsule>
                        <BurgerAdditional>{moment(station.Status.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                    </BurgerItem>
                </Burger>
            </Block>
        </StyledFuel>
    );
}

const mapStateToProps = (store: any) => {
    return {
        station: store.pageReducer.stations.find((el: any) => el.Id === store.pageReducer.selectedStation)
    }
}
export default connect(mapStateToProps, null)(Fuel);
