import React from 'react';
import light_logo from "../../images/LightLogo.png";
import blue_logo from "../../images/BlueLogo.png";
import styled, {useTheme} from "styled-components";

const LogoImg = styled.img`
  max-width: 200px;
  margin-bottom: 80px;
`;

function Logo() {
    const theme: any = useTheme();
    return (
        <LogoImg src={theme.name === "dark_theme" ? light_logo : blue_logo} alt={"logo"}/>
    );
}

export default Logo;