import React from 'react';
import styled, {css} from "styled-components";

const StyledJournalMenuSvg = styled.svg(({theme}: any) => css`
  circle {
    fill: ${theme.name === "light_theme" ? "#CAECFF" : "#102835"};
  }
`);

function JournalMenuSvg(props:any) {
    return (
        <StyledJournalMenuSvg {...props} width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="42" cy="42" r="42" fill="#CAECFF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M26.6875 31.5C26.6875 26.9091 30.4091 23.1875 35 23.1875H49C53.5909 23.1875 57.3125 26.9091 57.3125 31.5V52.5C57.3125 57.0909 53.5909 60.8125 49 60.8125H35C30.4091 60.8125 26.6875 57.0909 26.6875 52.5V31.5ZM35 31.9375C34.2751 31.9375 33.6875 32.5251 33.6875 33.25C33.6875 33.9749 34.2751 34.5625 35 34.5625H49C49.7249 34.5625 50.3125 33.9749 50.3125 33.25C50.3125 32.5251 49.7249 31.9375 49 31.9375H35ZM33.6875 42C33.6875 41.2751 34.2751 40.6875 35 40.6875H49C49.7249 40.6875 50.3125 41.2751 50.3125 42C50.3125 42.7249 49.7249 43.3125 49 43.3125H35C34.2751 43.3125 33.6875 42.7249 33.6875 42ZM35 49.4375C34.2751 49.4375 33.6875 50.0251 33.6875 50.75C33.6875 51.4749 34.2751 52.0625 35 52.0625H42C42.7249 52.0625 43.3125 51.4749 43.3125 50.75C43.3125 50.0251 42.7249 49.4375 42 49.4375H35Z" fill="#3A99AD"/>
        </StyledJournalMenuSvg>
    );
}

export default JournalMenuSvg;