import React from 'react';
import styled, {css} from "styled-components";
import Capsule from './Capsule';
import FuelBackArrowSvg from "../../svg-components/FuelBackArrowSvg";
import {StyledTextSlider} from "../styled/StyledTextSlider";

const Back = styled(FuelBackArrowSvg)(({theme: {textslider}, disabled}) => css`
    height: 20px;
    opacity: ${disabled ? .3 : 1};

    path {
        fill: ${textslider.arrowColor};
    }
`);
const Next = styled(FuelBackArrowSvg)(({theme: {textslider}, disabled}) => css`
    height: 20px;
    transform: rotate(180deg);
    opacity: ${disabled ? .3 : 1};

    path {
        fill: ${textslider.arrowColor};
    }
`);
const AdditionalStyledCapsule = styled(Capsule)(({theme: {textslider}}) => css`
    padding: 4px 28px;
    background: ${textslider.background};
    max-width: 200px;
    min-height: 21px;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 7px;
    color: ${textslider.color};
`);

const StyledCapsule = styled(Capsule)(({theme: {textslider}}) => css`
    background: ${textslider.background};
    max-width: 200px;
    min-height: 41px;
    font-size: 22px;
    font-weight: bold;
    color: ${textslider.color};
`);

export const Content = styled.div(({theme: {textslider}}) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
`);

type TextSliderProps = {
    values: { id: number, title: string }[],
    value: number,
    displayValue?: any,
    additional?: any,
    onChange: (values: any) => any
}

function TextSlider({values, onChange, value, additional, displayValue}: TextSliderProps) {
    const changeValue = (to: number) => {
        let nextIndex = values.findIndex(el => el.id === value) + to;
        if (nextIndex < 0) {
            return;
        }
        if (nextIndex > values.length - 1) {
            return;
        }
        onChange(values[nextIndex].id);
    }

    return (
        <StyledTextSlider>
            <Back disabled={values.length <= 1 || values.findIndex(el => el.id === value) === 0}
                  onClick={() => changeValue(-1)}/>
            <Content>
                {additional && <AdditionalStyledCapsule>{additional}</AdditionalStyledCapsule>}
                <StyledCapsule>{displayValue || values.find(el => el.id === value)?.title}</StyledCapsule>
            </Content>
            <Next disabled={values.length <= 1 || values.findIndex(el => el.id === value) === values.length - 1}
                  onClick={() => changeValue(1)}/>
        </StyledTextSlider>
    );
}

export default TextSlider;