import React from 'react';

function CollectorSvg(props: any) {
    return (
        <svg {...props} width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="37" height="25" rx="5.5" fill="white" stroke="#357AE4"/>
            <path
                d="M19.0002 6.33337C17.2535 6.33337 15.8335 7.75337 15.8335 9.50004C15.8335 11.2134 17.1735 12.6 18.9202 12.66C18.9735 12.6534 19.0268 12.6534 19.0668 12.66C19.0802 12.66 19.0868 12.66 19.1002 12.66C19.1068 12.66 19.1068 12.66 19.1135 12.66C20.8202 12.6 22.1602 11.2134 22.1668 9.50004C22.1668 7.75337 20.7468 6.33337 19.0002 6.33337Z"
                fill="#1C74FF"/>
            <path
                d="M22.3866 14.4333C20.5266 13.1933 17.4933 13.1933 15.62 14.4333C14.7733 15 14.3066 15.7666 14.3066 16.5866C14.3066 17.4066 14.7733 18.1666 15.6133 18.7266C16.5466 19.3533 17.7733 19.6666 19 19.6666C20.2266 19.6666 21.4533 19.3533 22.3866 18.7266C23.2266 18.16 23.6933 17.4 23.6933 16.5733C23.6866 15.7533 23.2266 14.9933 22.3866 14.4333Z"
                fill="#1C74FF"/>
        </svg>
    );
}

export default CollectorSvg;