import React from 'react';

function ArrowDownSvg(props) {
    return (
        <svg {...props} width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6.5 5L12 1" stroke="white" strokeWidth="2"/>
        </svg>
    );
}

export default ArrowDownSvg;