import axios from "axios";
import {api} from "../../config";
import {LOGIN_SUCCESS, LOGOUT_SUCCESS} from "../actionTypes";
import history from "../../utils/history";
import {message} from "antd";

export function getUser(hideError = false) {
    return (dispatch) => {
        axios(`${api}/user`).then(({data}) => {
            // console.log(data);
            dispatch({type: LOGIN_SUCCESS, payload: data})
        }).catch(({response: {data}}) => {
            !hideError && message.error(data);
        })
    }
}

export function loginAction({login, password}) {
    return (dispatch) => {
        axios(`${api}/auth?a123=${login}&a321=${password}`).then(({data}) => {
            dispatch({type: LOGIN_SUCCESS, payload: data})
            history.push("/")
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }
}

export function signupAction({name, login, password}) {
    return (dispatch) => {
        axios.post(`${api}/reg`, {
            login: login,
            password: password,
            name: name
        }).then(({data}) => {
            dispatch({type: LOGIN_SUCCESS, payload: data})
            history.push("/")
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }
}

export function logoutAction() {
    return (dispatch) => {
        axios(`${api}/exit`).then(() => {
            history.push("/login");
            dispatch({type: LOGOUT_SUCCESS})
        })
    }
}
