import React from 'react';

function AddressSvg(props: any) {
    return (
        <svg {...props} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.66683 6.00026C7.66683 5.07941 6.92067 4.33325 6.0005 4.33325C5.07965 4.33325 4.3335 5.07941 4.3335 6.00026C4.3335 6.92043 5.07965 7.66659 6.0005 7.66659C6.92067 7.66659 7.66683 6.92043 7.66683 6.00026Z"
                  stroke="#0D6BFF" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.99967 13C5.20069 13 1 9.59892 1 6.04219C1 3.25776 3.23807 1 5.99967 1C8.76128 1 11 3.25776 11 6.04219C11 9.59892 6.79866 13 5.99967 13Z"
                  stroke="#0D6BFF" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default AddressSvg;