import axios from "axios";
import {api} from "../../config";
import {CHANGE_STATION, CHANGE_THEME, GET_STATIONS_SUCCESS} from "../actionTypes";
import history from "../../utils/history";
import getTimeZone from "../../utils/getTimeZone";

export function getStationsAction() {
    return (dispatch) => {
        axios(`${api}/user/stations?zone=${getTimeZone()}`).then(({data}) => {
            if (data.length === 0) {
                history.push("/");
                // message.error("Вам необходимо добавить станцию!")
            } else {
                history.location.pathname === "/login" && history.push("/");
            }
            dispatch({type: GET_STATIONS_SUCCESS, payload: data})
        }).catch(err => {
            (!["/login", "/signup"].includes(history.location.pathname) && history.push("/login"))
        })
    }
}

export function changeSelectedId(Id) {
    return (dispatch) => {
        localStorage.setItem("selected_terminal_id", Id);
        dispatch({type: CHANGE_STATION, payload: Id})
        history.push("/dashboard")
    }
}

export function changeThemeAction(theme) {
    return (dispatch) => {
        localStorage.setItem("azs_theme", theme);
        dispatch({type: CHANGE_THEME, payload: theme})
    }
}