import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import Select from "../components/DataView/Select";
import {Form, Input, message, Select as AntSelect, Switch} from "antd";
import store from "../redux/store";
import {changeThemeAction} from "../redux/actions/pageActions";
import {useSelector} from "react-redux";
import {Button} from "../components/Form/Button";
import axios from "axios";
import {api} from "../config";
import {getUser} from "../redux/actions/loginActions";

const {Option} = AntSelect;

const Themes = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
`;

const Block = styled.div(({theme: {settings}}) => css`
  background: ${settings.background};
  padding: 18px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`);

const StyledSettings = styled.div(({theme: {options, settings}}) => css`
  color: ${options.color};

  .ant-switch {
    min-width: 20px;
    height: 11px;

    .ant-switch-handle {
      top: -2px;
      width: 15px;
      height: 15px;
      left: 0;

      &:before {
        background: #F7F7F7;
      }
    }

    &.ant-switch-checked {
      background-color: #B8D3FF;

      .ant-switch-handle {
        left: calc(100% - 14px);

        &:before {
          background: #0D6BFF;
        }
      }
    }
  }

  h2 {
    font-weight: 600;
    font-size: 12px;
    color: #66749D;
  }

  .ant-form {
    margin-top: 20px;


    .ant-input, .ant-input-password {
      background: ${settings.inputBackground};
      color: ${settings.inputColor};

      svg {
        fill: #d3d3d3;
      }

      &:not([id="Password"]) {
        border: none;
        border-radius: 8px;
        padding: 13px 18px;
      }
    }

    ${Block}.default {

      .ant-row {
        margin-bottom: 16px;
      }

      label {
        color: #6E7896;
      }
    }

    ${Block}:not(.default) {
      .ant-row {
        padding-bottom: 10px;
        padding-top: 5px;
        flex-direction: row;
        align-items: center;
        margin: 0;

        &:not(:last-child) {
          border-bottom: 1px solid ${settings.borderColor};
        }
      }

      .ant-form-item-control, .ant-form-item-label {
        flex: 0 0 auto;
        padding: 0;
      }

      .ant-form-item-label {
        flex: 1 1 auto;
      }
    }

    label {
      color: ${settings.blockColor};
    }
  }

  .ant-select {
    width: fit-content;
  }
`);

function Settings() {

    const {theme: defaultTheme, userData} = useSelector(({pageReducer: {theme}, loginReducer: {userData}}: any) => {
        return {theme, userData}
    })
    const [theme, setTheme] = useState(defaultTheme);

    useEffect(() => {
        store.dispatch(changeThemeAction(theme))
    }, [theme]);

    const onFinish = (fields: any) => {
        axios.post(`${api}/user`, {
            ...userData,
            Email: fields.Email,
            Phone: fields.Phone,
            Password: fields.Password,
            Settings: {
                EmailDeviceFail: fields.EmailDeviceFail,
                EmailOffline: fields.EmailOffline,
                EmailDailyReport: fields.EmailDailyReport,
                ReportHTML: fields.ReportHTML,
                ReportPDF: fields.ReportPDF,
                ReportExcel: fields.ReportExcel
                // SMSDeviceFail: fields.SMSDeviceFail,
                // SMSOffline: fields.SMSOffline
            }
        }).then(() => {
            store.dispatch(getUser())
            message.success("Информация успешно сохранена!")
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    return (
        <StyledSettings>
            <Themes>
                ТЕМА:
                <Select value={theme} onChange={setTheme}>
                    <Option value={"dark_theme"}>Тёмная</Option>
                    <Option value={"light_theme"}>Светлая</Option>
                </Select>
            </Themes>
            <Form onFinish={onFinish} initialValues={{...userData, ...userData.Settings, Password: ""}}
                  layout={"vertical"}>
                <Block className={"default"}>
                    <Form.Item label={"Email"} name={"Email"} rules={[{
                        type: 'email',
                        message: 'Введите валидный адрес почты!',
                    }]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={"Телефон"} name={"Phone"}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={"Пароль"} name={"Password"}>
                        <Input.Password/>
                    </Form.Item>
                </Block>
                <Block>
                    <h2>Отправлять письмо</h2>
                    <Form.Item valuePropName={"checked"} label={"Поломки устройств"} name={"EmailDeviceFail"}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item valuePropName={"checked"} label={"Отключения робота"} name={"EmailOffline"}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item valuePropName={"checked"} label={"Ежедневный отчет"} name={"EmailDailyReport"}>
                        <Switch/>
                    </Form.Item>
                </Block>
                <Block>
                    <h2>Формат отчетов</h2>
                    <Form.Item valuePropName={"checked"} label={"HTML"} name={"ReportHTML"}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item valuePropName={"checked"} label={"PDF"} name={"ReportPDF"}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item valuePropName={"checked"} label={"Excel"} name={"ReportExcel"}>
                        <Switch disabled/>
                    </Form.Item>
                </Block>
                {/*<Block>*/}
                {/*    <h2>Отправлять sms</h2>*/}
                {/*    <Form.Item valuePropName={"checked"} label={"Поломки устройств"} name={"SMSDeviceFail"}>*/}
                {/*        <Switch disabled/>*/}
                {/*    </Form.Item>*/}
                {/*    <Form.Item valuePropName={"checked"} label={"Отключения робота"} name={"SMSOffline"}>*/}
                {/*        <Switch disabled/>*/}
                {/*    </Form.Item>*/}
                {/*</Block>*/}

                <Form.Item>
                    <Button htmlType={"submit"}>Сохранить</Button>
                </Form.Item>
            </Form>
        </StyledSettings>
    );
}

export default Settings;