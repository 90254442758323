import React from 'react';
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

export const StyledMainMenuItem = styled(Link)(({theme: {mainmenu}}) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: ${mainmenu.item_background};
  border-radius: 6px;
  color: ${mainmenu.item_color};
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 28px;
  overflow: hidden;

  &:hover {
    color: ${mainmenu.active_color};

    svg {
      path {
          
      }
    }
  }

  svg {
    margin-bottom: 10px;

    path {
        //fill: ${mainmenu.item_color};
    }
  }
`);

type MainMenuItemProps = {
    children: any,
    to: string
}

function MainMenuItem({children, to, ...props}: MainMenuItemProps) {
    return (
        <StyledMainMenuItem {...props} to={to}>
            {children}
        </StyledMainMenuItem>
    );
}

export default MainMenuItem;