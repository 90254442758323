import React, {useState} from 'react';
import {message, Modal} from "antd";
import Input from "../Form/Input";
import {Button} from "../Form/Button";
import styled, {css} from "styled-components";
import axios from "axios";
import {api} from "../../config";
import InputNumber from "../Form/InputNumber";

const ModalTop = styled.div`
  max-width: 214px;
`;

const CreateModal = styled.div(({theme: {vouchers}}) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${vouchers.modalBackground};
  padding: 30px;
  border-radius: 8px;

  h1 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 21px;
    font-weight: 700;
    text-align: center;
    color: ${vouchers.additionalColor};
  }

  ${Button} {
    margin-top: 34px;
  }

  ${Input} {
    padding: 0 18px;
    border-radius: 4px;
    border: 1px solid ${vouchers.modalBorderColor};
    background: ${vouchers.modalInputBg};
    color: ${vouchers.modalColor};

    input {
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    input, span {
      font-size: 34px;
      background: ${vouchers.modalInputBg};
      text-align: center;
      color: ${vouchers.modalColor};
    }
  }
`);

function CouponModal({createVisible, getVouchers, station, setCreateVisible, parentClass}: any) {
    // const [couponValue, setCouponValue] = useState<any>();
    const [sumValue, setSumValue] = useState<number>(0);

    const createVoucher = () => {
        axios.put(`${api}/user/stations/${station.Id}/vouchers/${Number((sumValue || 0) * 100).toFixed(0)}`).then(() => {
            setSumValue(0);
            // setCouponValue(null);
            setCreateVisible(false);
            getVouchers();
            message.success("Купон успешно создан!")
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    return (
        <Modal getContainer={`.${parentClass}`}
               closable={false}
               visible={createVisible}
               onCancel={() => setCreateVisible(false)} footer={null}>
            <CreateModal>
                <ModalTop>
                    <h1>Укажите нужную сумму</h1>
                    <InputNumber
                        min={0}
                        defaultValue={100}
                        value={sumValue}
                        formatter={(value) => `${Number(value).toFixed(2)} ₽`}
                        parser={(value) => Number(value?.replace(/₽\s?|(,*)/g, '') || 0).toFixed(2)}
                        onChange={(value: any) => setSumValue(value)}
                        // onChange={(value) => {
                        //     console.log(value);
                        //     setVolumeValue(value)
                    />
                    {/*<Input*/}
                    {/*    value={couponValue}*/}
                    {/*    onChange={({target: {value}}) => setCouponValue(parseInt(value) || 0)}*/}
                    {/*    suffix={'₽'}/>*/}
                </ModalTop>
                <Button onClick={createVoucher}> Создать технический купон на сдачу</Button>
            </CreateModal>
        </Modal>
    );
}

export default CouponModal;