import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import {BurgerAdditional} from "../components/DataView/BurgerAdditional";
import RoundBlock, {StyledRoundBlock} from "../components/DataView/RoundBlock";
import TitledDatePicker, {StyledTitledDatePicker} from "../components/DataView/TitledDatePicker";
import axios from "axios";
import {api} from "../config";
import moment from "moment";
import {connect} from "react-redux";
import {message, Select as AntSelect} from "antd";
import Select from "../components/DataView/Select";
import getTimeZone from "../utils/getTimeZone";

const {Option} = AntSelect;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const JournalList = styled.div(({theme: {journal}}) => css`
  ${BurgerAdditional} {
    top: 12px;
    bottom: auto;
  }

  ${StyledRoundBlock} {
    padding: 22px 12px 12px 12px;
    color: ${journal.blockColor};
  }
`);

const StyledJournal = styled.div`
  ${StyledTitledDatePicker} {
    margin-bottom: 15px;
  }

  ${StyledRoundBlock} {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

type JournalProps = {
    station?: any
}

function Journal({station}: JournalProps) {
    const [dates, setDates] = useState([moment(), moment()]);
    const [data, setData] = useState([]);
    const [selectValue, setSelectValue] = useState(0);
    // const [selectValue2, setSelectValue2] = useState("type1");
    const [request] = useState("def");

    const getJournal = () => {
        axios(`${api}/user/stations/${station.Id}/events/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}?zone=${getTimeZone()}`).then(({data}) => {
            setData(data);
        }).catch(({response: {data: error}}) => {
            message.error(error)
        })
    }

    useEffect(() => {
        getJournal();
        // eslint-disable-next-line
    }, [dates])

    const onRequestChange = () => {
        axios(`${api}/user/stations/${station.Id}/events/${dates[0].format("YYYY-MM-DD")}/${dates[1].format("YYYY-MM-DD")}/email?zone=${getTimeZone()}`).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    return (
        <StyledJournal>
            <Top>
                <Select value={selectValue} onChange={setSelectValue} style={{minWidth: 95}}>
                    <Option value={0}>Записи</Option>
                    <Option value={1}>Платежи</Option>
                    <Option value={3}>Поломки</Option>
                    <Option value={2}>Купоны</Option>
                    <Option value={4}>Тех.отпуск</Option>
                </Select>
                <Select style={{marginLeft: "auto"}} value={request} onChange={onRequestChange}>
                    <Option value={"def"} disabled>Экспорт</Option>
                    <Option value={"test"}>На почту</Option>
                    {/*<Option value={"test2"}>Скачать</Option>*/}
                </Select>
                {/*<Select value={selectValue2} onChange={setSelectValue2}>*/}
                {/*    <Option value={"type1"}>Выгрузка</Option>*/}
                {/*    <Option value={"type2"}>Загрузка</Option>*/}
                {/*</Select>*/}
            </Top>
            <TitledDatePicker values={dates} onChange={values => {
                setDates(values)
            }}/>
            <JournalList>
                {data.filter((el: any) => selectValue === 0 ? true : selectValue === 1 ? [1, 2].includes(el.Type) : el.Type === selectValue).map((el: any, index) => (
                    <RoundBlock key={index}>
                        {el.Text}
                        <BurgerAdditional>{moment(el.Date).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                    </RoundBlock>
                ))}
            </JournalList>
        </StyledJournal>
    );
}

const mapStateToProps = (store: any) => {
    return {
        station: store.pageReducer.stations.find((el: any) => el.Id === store.pageReducer.selectedStation)
    }
}

export default connect(mapStateToProps, null)(Journal);