import {initialState} from "../initialState";
import {CHANGE_STATION, CHANGE_THEME, GET_STATIONS_SUCCESS} from "../actionTypes";


export function pageReducer(state = initialState, action) {
    switch (action.type) {
        case GET_STATIONS_SUCCESS: {
            const localValue = localStorage.getItem("selected_terminal_id");
            const stations = action.payload;

            if (localValue && stations.find((station) => station.Id === localValue) !== undefined) {
                return {...state, stations: stations, selectedStation: localValue, loading: false}
            } else {
                return {...state, stations: stations, selectedStation: stations[0]?.Id, loading: false}
            }
        }
        case CHANGE_STATION:
            return {...state, selectedStation: action.payload}
        case CHANGE_THEME:
            return {...state, theme: action.payload}
        default:
            return state;
    }
}