import React from 'react';
import styled, {css} from "styled-components";

const StyledOptionsMenuSvg = styled.svg(({theme}: any) => css`
  circle {
    fill: ${theme.name === "light_theme" ? "#E4E4E4" : "#292929"};
  }
`);


function OptionsMenuSvg(props: any) {
    return (
        <StyledOptionsMenuSvg {...props} width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="42" cy="42" r="42" fill="#E4E4E4"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M56.7041 44.765C57.3301 45.0975 57.8129 45.6225 58.1527 46.1475C58.8144 47.2325 58.7608 48.5625 58.117 49.735L56.8651 51.835C56.2033 52.955 54.9693 53.655 53.6996 53.655C53.0736 53.655 52.3761 53.48 51.8038 53.13C51.3388 52.8325 50.8023 52.7275 50.23 52.7275C48.4595 52.7275 46.9751 54.18 46.9214 55.9125C46.9214 57.925 45.2761 59.5 43.2194 59.5H40.7871C38.7125 59.5 37.0672 57.925 37.0672 55.9125C37.0314 54.18 35.547 52.7275 33.7765 52.7275C33.1863 52.7275 32.6498 52.8325 32.2027 53.13C31.6304 53.48 30.915 53.655 30.3069 53.655C29.0193 53.655 27.7853 52.955 27.1235 51.835L25.8895 49.735C25.2278 48.5975 25.192 47.2325 25.8538 46.1475C26.1399 45.6225 26.6764 45.0975 27.2845 44.765C27.7853 44.52 28.1072 44.1175 28.4112 43.645C29.3054 42.14 28.7689 40.1625 27.2487 39.27C25.4782 38.2725 24.9059 36.05 25.9253 34.3175L27.1235 32.2525C28.1608 30.52 30.3785 29.9075 32.1669 30.9225C33.7228 31.7625 35.7438 31.2025 36.6559 29.715C36.942 29.225 37.103 28.7 37.0672 28.175C37.0314 27.4925 37.2282 26.845 37.568 26.32C38.2297 25.235 39.4279 24.535 40.7335 24.5H43.2551C44.5786 24.5 45.7768 25.235 46.4385 26.32C46.7605 26.845 46.9751 27.4925 46.9214 28.175C46.8856 28.7 47.0466 29.225 47.3328 29.715C48.2449 31.2025 50.2658 31.7625 51.8396 30.9225C53.6101 29.9075 55.8457 30.52 56.8651 32.2525L58.0633 34.3175C59.1006 36.05 58.5283 38.2725 56.7399 39.27C55.2197 40.1625 54.6832 42.14 55.5953 43.645C55.8814 44.1175 56.2033 44.52 56.7041 44.765ZM36.9423 42.0174C36.9423 44.7649 39.2136 46.9524 42.0214 46.9524C44.8292 46.9524 47.0469 44.7649 47.0469 42.0174C47.0469 39.2699 44.8292 37.0649 42.0214 37.0649C39.2136 37.0649 36.9423 39.2699 36.9423 42.0174Z"
                  fill="#6A6A6A"/>
        </StyledOptionsMenuSvg>
    );
}

export default OptionsMenuSvg;