import React from 'react';
import styled, {css, useTheme} from "styled-components";
import LightLogoSvg from "../images/LightLogoSvg";
import BlueLogoSvg from "../images/BlueLogoSvg";
import AddressSvg from "../images/AddressSvg";
import PlusSvg from "../images/PlusSVG";
import {useSelector} from "react-redux";
import store from "../redux/store";
import {changeSelectedId, getStationsAction} from "../redux/actions/pageActions";
import moment from "moment";
import {Form, Input, message, Modal, Tooltip} from "antd";
import axios from "axios";
import {api} from "../config";
import history from "../utils/history";
import GreenStatus from "../svg-components/GreenStatus";
import RedStatus from "../svg-components/RedStatus";

const Header = styled.div(({theme: {terminalList}}: any) => css`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 28px;
    font-weight: 700;
    font-size: 22px;
    color: ${terminalList.textColor};
  }

  svg {
    width: 100%;
    height: 32px;
  }
`);

const Terminal = styled.div(({theme: {terminalList}}: any) => css`
  display: flex;
  flex-direction: column;
  padding: 14px;
  background: ${terminalList.accentColor1};
  border-radius: 8px;
  color: ${terminalList.textColor};

`);

const Terminals = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: calc(25px + 60px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  ${Terminal} {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
  user-select: none
`;

const Id = styled.div`
  font-weight: 500;
  font-size: 8px;
  color: #8C96B4;
`;

const PopoverContent = styled.div`
  overflow-wrap: anywhere;
  display: flex;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  overflow: hidden;
`;

const Address = styled.div(({theme: {terminalList}}: any) => css`
  display: flex;
  align-items: center;
  background: ${terminalList.accentColor2};
  border-radius: 56px;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 6px;

  svg {
    margin-right: 8px;
  }
`);

const Date = styled.div`
  font-weight: 500;
  font-size: 7px;
  line-height: 9px;
  color: #8C96B4;

  opacity: 0.8;
`;

const ErrorHeader = styled.div(({theme: {terminalList}}: any) => css`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
`);

const LastError = styled.div(({theme: {terminalList}}: any) => css`
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  background: ${terminalList.accentColor2};
  border-radius: 8px;
  margin-bottom: 6px;

  p {
    color: ${terminalList.errorColor};
    font-size: 12px;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }
`);

const Statuses = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 14px;
`;

const Status = styled.div(({theme: {terminalList}}: any) => css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${terminalList.accentColor2};
  border-radius: 8px;
  font-weight: 600;
  font-size: 10px;
  padding: 10px 8px;

`);

const StatusText = styled.div(({theme: {terminalList}}: any) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 56px;
  padding: 6px 24px;
  margin-top: 8px;
  width: 100%;

  &.green {
    color: ${terminalList.greenColor};
    background: ${terminalList.greenBackground};
  }

  &.yellow {
    color: ${terminalList.yellowColor};
    background: ${terminalList.yellowBackground};
  }

  &.red {
    color: #FF5151;
    background: ${terminalList.redBackground};
  }
`);

const StatusDetail = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 8px;
`;

const StatusDetailItem = styled.div(({theme: {terminalList}}: any) => css`
  display: flex;
  width: calc(50% - 5px);
  font-size: 10px;
  align-items: center;
  padding: 12px 8px;
  border-radius: 8px;
  background: ${terminalList.accentColor2};

  svg {
    flex: 0 0 auto;
    margin-right: 6px;
  }
`);

const AddButton = styled.div(({theme: {terminalList}}: any) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${terminalList.accentColor1};
  padding: 20px;
  width: calc(100% - 40px);
  position: absolute;
  bottom: 25px;
  left: 20px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  color: ${terminalList.textColor};

  svg {
    margin-right: 10px;
  }
`);
const StyledDashboardList = styled.div(({theme: {terminalList}}: any) => css`
  display: flex;
  flex-direction: column;
  padding: 18px 20px 25px;
  background: ${terminalList.accentColor2};
  min-height: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;

  h2 {
    color: ${terminalList.h2Color};
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 12px;
  }
`);

function DashboardList() {
    const theme: any = useTheme();

    const stations: any = useSelector(({pageReducer: {stations}}: any) => stations);
    const handleFinish = ({Id, Password}: any) => {
        axios.post(`${api}/user/stations/${Id}`, {
            Password: Password || ""
        }).then(({data}) => {
            message.success("Станция добавлена!");
            store.dispatch(getStationsAction())
        }).catch(({response: {data}}) => {
            message.error(data);
        })
    }

    const [formRef] = Form.useForm();

    return (
        <StyledDashboardList>
            <Header>
                {theme.name === "dark_theme" ? <LightLogoSvg onClick={() => history.push("/menu")}/> :
                    <BlueLogoSvg onClick={() => history.push("/menu")}/>}
                <h1>Доступные терминалы</h1>
            </Header>
            <Terminals>
                {stations?.map((station: any, index: number) => <Terminal key={index} onClick={() => {
                    store.dispatch(changeSelectedId(station.Id))
                }}>
                    <Top>
                        <Tooltip trigger={['hover']} title={<PopoverContent>
                            {station.Name}
                        </PopoverContent>}>
                            <Name>{station.Name}</Name>
                        </Tooltip>
                        <Id>{station.Id}</Id>
                    </Top>
                    {station.Address && <Address><AddressSvg/> {station.Address}</Address>}
                    {station.Status.LastError && <LastError onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation()
                    }}>
                        <ErrorHeader>Ошибка <Date>{moment(station.Status.LastErrorDate).format("DD.MM.YYYY HH:mm")}</Date></ErrorHeader>
                        <p>{station.Status.LastError}</p>
                    </LastError>}
                    <Statuses>
                        <Status>
                            Статус терминала
                            <StatusText
                                className={station.Blocked ? "red" : (station.Status.Online ? (station.Status.Locked ? "yellow" : "green") : "red")}>
                                {station.Blocked ? "Черный список" : (station.Status.Online ? (station.Status.Locked ? "Заблокирован" : "Онлайн") : "Оффлайн")}
                            </StatusText>
                        </Status>
                        <Status>
                            Статус оборудования
                            <StatusText className={station?.Status?.Devices?.every((el: any) => !!el.Ok) ?
                                "green" : station?.Status?.Devices?.every((el: any) => !el.Ok) ?
                                    "red" : station?.Status?.Devices?.some((el: any) => !el.Ok) ?
                                        "yellow" : ""}>
                                {station?.Status?.Devices?.every((el: any) => !!el.Ok) ?
                                    "Отлично" : station?.Status?.Devices?.every((el: any) => !el.Ok) ?
                                        "Авария" : station?.Status?.Devices?.some((el: any) => !el.Ok) ?
                                            "Внимание" : "-"}

                            </StatusText>
                        </Status>
                    </Statuses>
                    <h2>Оборудование</h2>
                    <StatusDetail>
                        {station?.Status?.Devices?.map((device: any, index: number) => <StatusDetailItem
                            key={index}>
                            {device.Ok ? <GreenStatus/> : <RedStatus/>}
                            {/*<CircleSvg color={device.Ok ? "#35CF00" : "#FF5151"}/>*/}
                            {device.Name}
                        </StatusDetailItem>)}

                    </StatusDetail>
                </Terminal>)}
            </Terminals>
            <AddButton onClick={() => {
                new (Modal as any).confirm({
                    title: "Добавление станции",
                    content: <Form form={formRef} onFinish={handleFinish}>
                        <Form.Item name={"Id"}>
                            <Input placeholder={"Id станции"}/>
                        </Form.Item>
                        <Form.Item name={"Password"}>
                            <Input.Password placeholder={"Пароль"}/>
                        </Form.Item>
                    </Form>,
                    okText: "Добавить",
                    cancelText: "Отмена",
                    onOk: () => formRef.submit()
                })

            }}>
                <PlusSvg/>
                Добавить
            </AddButton>
        </StyledDashboardList>
    );
}

export default DashboardList;