import React, {useEffect, useState} from 'react';
import {message, Modal, Space} from "antd";
import Input from "../Form/Input";
import {Button} from "../Form/Button";
import styled, {css} from "styled-components";
import axios from "axios";
import {api} from "../../config";
import {RelayType} from "../../types";
import moment from "moment";

const ModalTop = styled.div`
  width: 100%;
`;

const CreateModal = styled.div(({theme: {vouchers}}) => css`
  display: flex;
  flex-direction: column;
  background: ${vouchers.modalBackground};
  padding: 30px;
  border-radius: 8px;

  @media screen and (max-width: 500px) {
    .ant-space {
      flex-direction: column;
      width: 100%;
      .ant-space-item{
        width: 100%;
      }

      .ant-btn {
        width: 100%;
        margin: 0;
      }
    }
  }

  .ant-select {
    width: 100%;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 21px;
    font-weight: 700;
    text-align: center;
    color: ${vouchers.additionalColor};
  }

  p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 21px;
    font-weight: 700;
    color: ${vouchers.additionalColor};
  }

  ${Button} {
    margin-top: 34px;
  }

  ${Input} {
    padding: 0 18px;
    border-radius: 4px;
    border: 1px solid ${vouchers.modalBorderColor};
    background: ${vouchers.modalInputBg};
    color: ${vouchers.modalColor};

    input {
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    input, span {
      font-size: 34px;
      background: ${vouchers.modalInputBg};
      text-align: center;
      color: ${vouchers.modalColor};
    }
  }
`);

function RelayModal({changeStatus, visible, station, setVisible, parentClass}: any) {
    const [relayData, setRelayData] = useState<RelayType | null>(null)

    const [loading, setLoading] = useState<boolean>(false);

    const [notConnected, setNotConnected] = useState<boolean>(true);

    const getRelay = () => {
        axios(`${api}/user/stations/${station.Id}/relay`).then(({data}) => {
            // console.log(data);
            setRelayData(data)
            setNotConnected(false)
            changeStatus(true)
        }).catch(({response}) => {
            changeStatus(false)
            // message.error(response?.data || "Error")
            setNotConnected(true)
        })
    }

    useEffect(() => {
        getRelay()
        const interval = setInterval(() => {
            if (visible === true)
                getRelay()
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [visible])

    const switchRelay = () => {
        setLoading(true)
        axios.post(`${api}/user/stations/${station.Id}/relay/power_${relayData?.POWER === "OFF" ? "on" : "off"}`).catch(({response}) => {
            message.error(response?.data || "Error")
        }).finally(() => {
            setLoading(false);
            getRelay();
        })
    }

    return (
        <Modal getContainer={`.${parentClass}`}
               closable={false}
               visible={visible}
               onCancel={() => setVisible(false)} footer={null}>
            <CreateModal>
                <ModalTop>
                    <h1>Данные реле</h1>
                    {notConnected ? <p style={{color: '#E82121', textAlign: 'center'}}>Реле не подключено</p> :  <>
                        <p>Обновлено: {relayData ? moment(relayData?.Received).format("DD.MM.YYYY HH:mm") : "-"}</p>
                        <p>Время работы: {relayData ? relayData.Uptime : "-"}</p>
                        <p>Состояние: {relayData ? relayData.POWER : "-"}</p>
                    </>}
                </ModalTop>
                <Space style={{justifyContent: "space-between"}}>
                    <Button loading={loading} disabled={relayData?.POWER !== "OFF"} style={{minWidth: 180}}
                            onClick={switchRelay}>Включить</Button>
                    <Button loading={loading} disabled={relayData?.POWER === "OFF"} style={{minWidth: 180}}
                            onClick={switchRelay}>Выключить</Button>
                </Space>
            </CreateModal>
        </Modal>
    );
}

export default RelayModal;