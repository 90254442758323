import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {StyledCapsule} from "../components/DataView/Capsule";
import {BurgerAdditional} from "../components/DataView/BurgerAdditional";
import {StyledRoundBlock} from "../components/DataView/RoundBlock";
import {StyledTitledDatePicker} from "../components/DataView/TitledDatePicker";
import axios from "axios";
import {api} from "../config";
import {connect} from "react-redux";
import {message} from "antd";
import getTimeZone from "../utils/getTimeZone";
import {Button} from "../components/Form/Button";
import VoucherList from "../components/List/VoucherList";
import CouponModal from "../components/Modals/CouponModal";

const Create = styled.div`
  display: flex;
  margin: 0 20px 14px 20px;
`;

const StyledVouchers = styled.div`
  .adm-swipe-action {
    background: none;
  }

  ${StyledTitledDatePicker} {
    max-width: 110px;
    margin-bottom: 15px;
  }

  .ant-modal-content {
    background: none;

    .ant-modal-body {
      padding: 0;
    }
  }

  ${StyledRoundBlock} {
    justify-content: flex-start;

    ${StyledCapsule} {
      padding: 8px 15px;
      max-width: 150px;
      max-height: 30px;
      font-size: 14px;
    }

    ${BurgerAdditional} {
      bottom: 16px;
    }

    & > svg {
      margin-right: 8px;
      flex: 0 0 auto;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;
type VouchersProps = {
    station?: any
}

function Vouchers({station}: VouchersProps) {
    const [data, setData] = useState([]);


    const getVouchers = () => {
        axios(`${api}/user/stations/${station.Id}/vouchers?zone=${getTimeZone()}`).then(({data}) => {
            setData(data);
        }).catch(({response: {data: error}}) => {
            message.error(error);
        })
    }

    const deleteVoucher = (id: number) => {
        axios.delete(`${api}/user/stations/${station.Id}/vouchers/${id}`).then(() => {
            message.warning("Купон успешно удален!");
            getVouchers();
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    useEffect(() => {
        getVouchers();

        const interval = setInterval(() => {
            getVouchers()
        }, 4000)
        return () => {
            clearInterval(interval)
        }

        // eslint-disable-next-line
    }, [])

    const [createVisible, setCreateVisible] = useState<boolean>(false);

    return (
        <StyledVouchers>
            {/*<TitledDatePicker values={dates} onChange={values => {*/}
            {/*    setDates(values)*/}
            {/*}}/>*/}
            <CouponModal createVisible={createVisible} getVouchers={getVouchers} station={station}
                         setCreateVisible={setCreateVisible} parentClass={StyledVouchers.styledComponentId}/>
            <Create>
                <Button onClick={() => setCreateVisible(true)}>Создать технический купон на сдачу</Button>
            </Create>
            <VoucherList deleteVoucher={deleteVoucher} data={data}/>
        </StyledVouchers>
    );
}

const mapStateToProps = (store: any) => {
    return {
        station: store.pageReducer.stations.find((el: any) => el.Id === store.pageReducer.selectedStation)
    }
}

export default connect(mapStateToProps, null)(Vouchers);
