import React from 'react';
import styled, {css} from "styled-components";

const StyledCashMenuSvg = styled.svg(({theme}: any) => css`
  circle {
    fill: ${theme.name === "light_theme" ? "#DDFFE8" : "#132318"};
  }
`);
function CashMenuSvg(props:any) {
    return (
        <StyledCashMenuSvg {...props} width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="42" cy="42" r="42" fill="#DDFFE8"/>
            <path
                d="M29.75 29.8558C29.75 29.7759 29.7911 29.4909 30.3351 29.0315C30.872 28.5782 31.7405 28.1003 32.9489 27.6687C35.3524 26.8104 38.7743 26.25 42.628 26.25C46.4818 26.25 49.9037 26.8104 52.3071 27.6687C53.5156 28.1003 54.3841 28.5782 54.921 29.0315C55.465 29.4909 55.5061 29.7759 55.5061 29.8558C55.5061 29.9358 55.465 30.2208 54.921 30.6802C54.3841 31.1335 53.5156 31.6114 52.3071 32.043C49.9037 32.9013 46.4818 33.4617 42.628 33.4617C38.7743 33.4617 35.3524 32.9013 32.9489 32.043C31.7405 31.6114 30.872 31.1335 30.3351 30.6802C29.7911 30.2208 29.75 29.9358 29.75 29.8558Z"
                fill="#37D500"/>
            <path
                d="M53.3467 34.9536C53.8858 34.7611 54.4016 34.5491 54.8847 34.3167C55.1676 34.1807 55.5061 34.3816 55.5061 34.6955V38.0978C55.5061 38.1777 55.465 38.4628 54.921 38.9221C54.3841 39.3754 53.5156 39.8533 52.3071 40.2849C49.9037 41.1433 46.4818 41.7036 42.628 41.7036C38.7743 41.7036 35.3524 41.1433 32.9489 40.2849C31.7405 39.8533 30.872 39.3754 30.3351 38.9221C29.7911 38.4628 29.75 38.1777 29.75 38.0978V34.6955C29.75 34.3816 30.0884 34.1807 30.3714 34.3167C30.8545 34.5491 31.3703 34.7611 31.9094 34.9536C34.7262 35.9596 38.5159 36.5524 42.628 36.5524C46.7401 36.5524 50.5299 35.9596 53.3467 34.9536Z"
                fill="#37D500"/>
            <path
                d="M53.3467 43.1956C53.8858 43.003 54.4016 42.791 54.8847 42.5587C55.1676 42.4226 55.5061 42.6235 55.5061 42.9375V46.3397C55.5061 46.4197 55.465 46.7047 54.921 47.1641C54.3841 47.6174 53.5156 48.0953 52.3071 48.5268C49.9037 49.3852 46.4818 49.9456 42.628 49.9456C38.7743 49.9456 35.3524 49.3852 32.9489 48.5268C31.7405 48.0953 30.872 47.6174 30.3351 47.1641C29.7911 46.7047 29.75 46.4197 29.75 46.3397V42.9375C29.75 42.6235 30.0884 42.4226 30.3714 42.5587C30.8545 42.791 31.3703 43.003 31.9094 43.1956C34.7262 44.2016 38.5159 44.7944 42.628 44.7944C46.7401 44.7944 50.5299 44.2016 53.3467 43.1956Z"
                fill="#37D500"/>
            <path
                d="M53.3467 51.4375C53.8858 51.245 54.4016 51.033 54.8847 50.8006C55.1676 50.6645 55.5061 50.8654 55.5061 51.1794V54.5817C55.5061 54.6616 55.465 54.9466 54.921 55.406C54.3841 55.8593 53.5156 56.3372 52.3071 56.7688C49.9037 57.6272 46.4818 58.1875 42.628 58.1875C38.7743 58.1875 35.3524 57.6272 32.9489 56.7688C31.7405 56.3372 30.872 55.8593 30.3351 55.406C29.7911 54.9466 29.75 54.6616 29.75 54.5817V51.1794C29.75 50.8654 30.0884 50.6645 30.3714 50.8006C30.8545 51.033 31.3703 51.245 31.9094 51.4375C34.7262 52.4435 38.5159 53.0363 42.628 53.0363C46.7401 53.0363 50.5299 52.4435 53.3467 51.4375Z"
                fill="#37D500"/>
        </StyledCashMenuSvg>
    );
}

export default CashMenuSvg;