import React from 'react';

function CashboxSvg(props) {
    return (
        <svg {...props} width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.717773 2.82948C0.717773 2.77584 0.745322 2.58458 1.11037 2.27635C1.47061 1.97217 2.05337 1.65152 2.86424 1.36192C4.47695 0.785953 6.77307 0.409958 9.35892 0.409958C11.9448 0.409958 14.2409 0.785953 15.8536 1.36192C16.6645 1.65152 17.2472 1.97217 17.6075 2.27635C17.9725 2.58458 18.0001 2.77584 18.0001 2.82948C18.0001 2.88312 17.9725 3.07437 17.6075 3.38261C17.2472 3.68679 16.6645 4.00744 15.8536 4.29704C14.2409 4.873 11.9448 5.249 9.35892 5.249C6.77307 5.249 4.47695 4.873 2.86424 4.29704C2.05337 4.00744 1.47061 3.68679 1.11037 3.38261C0.745322 3.07437 0.717773 2.88312 0.717773 2.82948Z"
                fill="#9CABC0"/>
            <path
                d="M16.5511 6.25009C16.9363 6.11254 17.3037 5.96018 17.6458 5.79231C17.8068 5.71327 18.0001 5.82786 18.0001 6.00727V8.35981C18.0001 8.41345 17.9725 8.60471 17.6075 8.91295C17.2472 9.21712 16.6645 9.53777 15.8536 9.82737C14.2409 10.4033 11.9448 10.7793 9.35892 10.7793C6.77307 10.7793 4.47695 10.4033 2.86424 9.82737C2.05337 9.53777 1.47061 9.21712 1.11037 8.91295C0.745322 8.60471 0.717773 8.41345 0.717773 8.35981V6.00727C0.717773 5.82786 0.911015 5.71327 1.07208 5.79231C1.41418 5.96018 1.78157 6.11254 2.16672 6.25009C4.05679 6.92511 6.59971 7.32287 9.35892 7.32287C12.1181 7.32287 14.6611 6.92511 16.5511 6.25009Z"
                fill="#9CABC0"/>
            <path
                d="M16.5511 11.7804C16.9363 11.6429 17.3037 11.4905 17.6458 11.3226C17.8068 11.2436 18.0001 11.3582 18.0001 11.5376V13.8901C18.0001 13.9438 17.9725 14.135 17.6075 14.4433C17.2472 14.7475 16.6645 15.0681 15.8536 15.3577C14.2409 15.9337 11.9448 16.3097 9.35892 16.3097C6.77307 16.3097 4.47695 15.9337 2.86424 15.3577C2.05337 15.0681 1.47061 14.7475 1.11037 14.4433C0.745322 14.135 0.717773 13.9438 0.717773 13.8901V11.5376C0.717773 11.3582 0.911016 11.2436 1.07208 11.3226C1.41418 11.4905 1.78157 11.6429 2.16672 11.7804C4.05679 12.4554 6.59971 12.8532 9.35892 12.8532C12.1181 12.8532 14.6611 12.4554 16.5511 11.7804Z"
                fill="#9CABC0"/>
            <path
                d="M16.5511 17.3108C16.9363 17.1732 17.3037 17.0208 17.6458 16.853C17.8068 16.7739 18.0001 16.8885 18.0001 17.0679V19.4205C18.0001 19.4741 17.9725 19.6654 17.6075 19.9736C17.2472 20.2778 16.6645 20.5984 15.8536 20.888C14.2409 21.464 11.9448 21.84 9.35892 21.84C6.77307 21.84 4.47695 21.464 2.86424 20.888C2.05337 20.5984 1.47061 20.2778 1.11037 19.9736C0.745322 19.6654 0.717773 19.4741 0.717773 19.4205V17.0679C0.717773 16.8885 0.911016 16.7739 1.07208 16.853C1.41418 17.0208 1.78157 17.1732 2.16672 17.3108C4.05679 17.9858 6.59971 18.3835 9.35892 18.3835C12.1181 18.3835 14.6611 17.9858 16.5511 17.3108Z"
                fill="#9CABC0"/>
        </svg>
    );
}

export default CashboxSvg;