import React from 'react';
import styled, {css} from "styled-components";
import MainMenuItem, {StyledMainMenuItem} from "../components/Nav/MainMenuItem";
import MainMenuSvg from "../svg-components/MainMenuSvg";
import FuelMenuSvg from "../svg-components/FuelMenuSvg";
import CashMenuSvg from "../svg-components/CashMenuSvg";
import ReportMenuSvg from "../svg-components/ReportMenuSvg";
import CouponMenuSvg from "../svg-components/CouponMenuSvg";
import OptionsMenuSvg from "../svg-components/OptionsMenuSvg";
import ProductsMenuSvg from "../svg-components/ProductsMenuSvg";
import JournalMenuSvg from "../svg-components/JournalMenuSvg";
const Content = styled.div`
  flex: 1 1 auto;
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0;
  }
`;
const StyledMainMenu = styled.div(({theme: {mainmenu}}) => css`
  display: flex;
  background: ${mainmenu.background};
  height: 100%;
  padding: 25px 16px 25px 16px;

  ${StyledMainMenuItem} {
    flex: 1 1 calc(50% - 8px);
  }
`);

function MainMenu() {
    return (
        <StyledMainMenu>
            <Content>
            <MainMenuItem to={"/dashboard"}>
                <MainMenuSvg/>
                Главная
            </MainMenuItem>
            <MainMenuItem to={"/fuel"}>
                <FuelMenuSvg/>
                Топливо
            </MainMenuItem>
            <MainMenuItem to={"/cash"}>
                <CashMenuSvg/>
                Касса
            </MainMenuItem>
            <MainMenuItem to={"/reports"}>
                <ReportMenuSvg/>
                Отчеты
            </MainMenuItem>
            <MainMenuItem to={"/vouchers"}>
                <CouponMenuSvg/>
                Купоны<br/> на сдачу
            </MainMenuItem>
            <MainMenuItem to={"/journal"}>
                <JournalMenuSvg/>
                Журнал
            </MainMenuItem>
            <MainMenuItem to={"/options"}>
                <OptionsMenuSvg/>
                Настройки
            </MainMenuItem>
            <MainMenuItem to={"#"}>
                <ProductsMenuSvg/>
                Товары
            </MainMenuItem>
            </Content>
        </StyledMainMenu>
    );
}

export default MainMenu;