import React from 'react';

function StorageLineSvg(props) {
    return (
        <svg {...props} width="15" height="4" viewBox="0 0 15 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2H15" stroke={props.color || "#78C6FF"} strokeWidth="3"/>
        </svg>
    );
}

export default StorageLineSvg;