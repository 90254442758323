import React from 'react';

function BlueLogoSvg(props:any) {
    return (
        <svg {...props} width="64" height="18" viewBox="0 0 64 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M44.3316 5.41437C43.9862 5.41195 43.8273 5.03514 43.8105 4.8518C44.4289 4.38452 45.7451 3.39401 46.0628 3.17017C46.3804 2.94634 46.657 2.82964 47.0646 2.70412L48.988 2.26924C48.8796 2.52025 48.9869 2.96637 49.0542 3.15805C48.9494 3.17599 48.525 3.25599 47.729 3.40869C47.0907 3.53115 46.4555 3.86297 46.3012 3.96298C46.1469 4.063 45.6721 4.45027 44.3316 5.41437Z"
                fill="#005AF7" stroke="#005AF7" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M49.3133 3.44987C48.9673 2.80136 49.0791 2.18063 49.1779 1.9624L51.7534 1.22448L53.0124 1.69588C54.2546 2.16728 53.6566 2.48157 55.1424 3.28171C55.5471 3.49961 56.3084 3.93474 56.759 4.0228L56.0582 5.68195L55.2013 5.22152C55.0602 6.05517 53.9629 6.7736 53.6566 7.05816C53.4116 7.2858 53.1251 7.28528 53.0124 7.25656C52.7526 7.06831 52.1371 6.61891 51.7533 6.32737C51.3696 6.03583 50.9034 5.58434 50.7183 5.39503C50.3565 4.80991 50.2913 4.0559 50.3039 3.75203C50.2584 3.29193 49.6245 3.35888 49.3133 3.44987Z"
                fill="#005AF7" stroke="#F6F7F9" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M51.4588 5.49819C51.0229 5.12589 51.1332 4.58685 51.2428 4.36387L51.7419 4.26109L52.1498 4.29558L53.8139 5.12944L54.4391 5.51914C54.3464 5.96979 53.6879 6.45791 53.5061 6.57794C53.3243 6.69798 53.1339 6.71926 53.0614 6.7149C52.7088 6.46445 51.8947 5.87049 51.4588 5.49819Z"
                fill="#F6F7F9"/>
            <path
                d="M5.19109 7.65999C6.08048 7.65999 6.7662 7.8626 7.24824 8.26782C7.73706 8.66605 7.98148 9.22496 7.98148 9.94457C7.98148 10.5594 7.8389 11.0973 7.55375 11.5585C7.27539 12.0126 6.87483 12.3619 6.35205 12.6064C5.83607 12.851 5.22503 12.9732 4.51895 12.9732H2.93026L2.54328 14.9958H0.883301L2.30905 7.65999H5.19109ZM4.59024 11.5899C5.14017 11.5899 5.5645 11.4606 5.86322 11.2021C6.16195 10.9436 6.31132 10.5734 6.31132 10.0913C6.31132 9.74196 6.1959 9.47997 5.96506 9.30531C5.74102 9.13065 5.41513 9.04332 4.98741 9.04332H3.69406L3.19505 11.5899H4.59024Z"
                fill="#005AF7"/>
            <path d="M10.4605 7.21984H12.0491L10.5952 14.9609H9.00648L10.4605 7.21984Z" fill="#005AF7"/>
            <path
                d="M18.6766 11.7576C18.6766 12.0161 18.6461 12.3025 18.585 12.6169H14.3892C14.4435 13.3854 14.9154 13.7697 15.8048 13.7697C16.0831 13.7697 16.3479 13.7243 16.5991 13.6334C16.8571 13.5426 17.0812 13.4134 17.2713 13.2457L17.9332 14.3251C17.6277 14.5766 17.278 14.7653 16.8843 14.891C16.4905 15.0168 16.0763 15.0796 15.6418 15.0796C15.0783 15.0796 14.5827 14.9783 14.155 14.7757C13.734 14.5661 13.4082 14.2692 13.1773 13.885C12.9533 13.5007 12.8413 13.0571 12.8413 12.554C12.8413 11.9322 12.977 11.3733 13.2486 10.8773C13.527 10.3742 13.9106 9.983 14.3994 9.70354C14.8882 9.41709 15.4449 9.27387 16.0696 9.27387C16.5991 9.27387 17.0574 9.37517 17.4444 9.57778C17.8382 9.78039 18.1403 10.0703 18.3507 10.4476C18.568 10.8249 18.6766 11.2615 18.6766 11.7576ZM15.9983 10.5C15.6249 10.5 15.3092 10.6048 15.0512 10.8144C14.7932 11.017 14.6099 11.3 14.5012 11.6632H17.2203C17.2203 11.3 17.1083 11.017 16.8843 10.8144C16.667 10.6048 16.3717 10.5 15.9983 10.5Z"
                fill="#005AF7"/>
            <path
                d="M23.3946 12.1768L24.9222 14.9958H23.2419L22.3457 13.2667L20.7672 14.9958H18.9545L21.6939 12.1034L20.2376 9.35771H21.8874L22.7531 11.0135L24.2806 9.35771H26.0323L23.3946 12.1768Z"
                fill="#005AF7"/>
            <path
                d="M30.8668 7.65999C31.7562 7.65999 32.4419 7.8626 32.924 8.26782C33.4128 8.66605 33.6572 9.22496 33.6572 9.94457C33.6572 10.5594 33.5146 11.0973 33.2295 11.5585C32.9511 12.0126 32.5506 12.3619 32.0278 12.6064C31.5118 12.851 30.9008 12.9732 30.1947 12.9732H28.606L28.219 14.9958H26.559L27.9848 7.65999H30.8668ZM30.266 11.5899C30.8159 11.5899 31.2402 11.4606 31.539 11.2021C31.8377 10.9436 31.9871 10.5734 31.9871 10.0913C31.9871 9.74196 31.8716 9.47997 31.6408 9.30531C31.4168 9.13065 31.0909 9.04332 30.6632 9.04332H29.3698L28.8708 11.5899H30.266Z"
                fill="#005AF7"/>
            <path
                d="M37.3813 15.0796C36.8313 15.0796 36.3459 14.9748 35.925 14.7653C35.5108 14.5557 35.1883 14.2622 34.9575 13.885C34.7335 13.5007 34.6214 13.0571 34.6214 12.554C34.6214 11.9322 34.764 11.3733 35.0492 10.8773C35.3343 10.3812 35.7281 9.98998 36.2305 9.70354C36.7329 9.41709 37.2998 9.27387 37.9312 9.27387C38.4879 9.27387 38.9734 9.37867 39.3875 9.58826C39.8016 9.79786 40.1207 10.0948 40.3448 10.479C40.5756 10.8563 40.691 11.2965 40.691 11.7995C40.691 12.4213 40.5485 12.9802 40.2633 13.4762C39.9849 13.9723 39.5912 14.3635 39.082 14.65C38.5796 14.9364 38.0127 15.0796 37.3813 15.0796ZM37.4831 13.7278C37.7886 13.7278 38.0636 13.6509 38.308 13.4972C38.5524 13.3365 38.7425 13.1164 38.8783 12.837C39.0141 12.5575 39.082 12.2361 39.082 11.8728C39.082 11.4886 38.9734 11.1847 38.7561 10.9611C38.5388 10.7375 38.2333 10.6258 37.8395 10.6258C37.5272 10.6258 37.2489 10.7061 37.0045 10.8668C36.76 11.0205 36.57 11.2371 36.4342 11.5165C36.2984 11.796 36.2305 12.1174 36.2305 12.4807C36.2305 12.8649 36.3391 13.1688 36.5564 13.3924C36.7804 13.616 37.0893 13.7278 37.4831 13.7278Z"
                fill="#005AF7"/>
            <path d="M42.9854 9.35771H44.5639L43.4743 14.9958H41.8958L42.9854 9.35771Z" fill="#005AF7"/>
            <path
                d="M49.939 9.27387C50.5501 9.27387 51.0355 9.44155 51.3953 9.7769C51.7552 10.1053 51.9351 10.5768 51.9351 11.1917C51.9351 11.4222 51.9113 11.6493 51.8638 11.8728L51.2528 14.9958H49.6641L50.2547 11.9776C50.2819 11.8589 50.2955 11.7296 50.2955 11.5899C50.2955 11.2965 50.2174 11.0729 50.0612 10.9192C49.9051 10.7655 49.6709 10.6886 49.3586 10.6886C48.9512 10.6886 48.6117 10.8109 48.3402 11.0554C48.0754 11.293 47.8955 11.6528 47.8004 12.1348L47.2505 14.9958H45.6618L46.7515 9.35771H48.2587L48.1365 9.96553C48.6253 9.50442 49.2262 9.27387 49.939 9.27387Z"
                fill="#005AF7"/>
            <path
                d="M55.5749 13.0047C55.5613 13.0605 55.5545 13.1374 55.5545 13.2352C55.5545 13.5985 55.748 13.7802 56.135 13.7802C56.3455 13.7802 56.5593 13.7138 56.7766 13.581L57.0108 14.7443C56.6578 14.9679 56.2029 15.0796 55.6462 15.0796C55.1302 15.0796 54.716 14.9399 54.4037 14.6605C54.0914 14.381 53.9353 13.9967 53.9353 13.5077C53.9353 13.368 53.9522 13.2038 53.9862 13.0151L54.4241 10.741H53.5788L53.8232 9.48346H54.6685L54.9333 8.11062H56.522L56.2572 9.48346H57.6218L57.3774 10.741H56.0128L55.5749 13.0047Z"
                fill="#005AF7"/>
            <path
                d="M10.5952 14.9609H9.00648C8.87133 16.8472 9.1429 17.7205 10.5952 18C10.5952 18 39.6087 18 57.0108 18C57.9535 18 59.2104 17.7555 60.1615 17.3363C61.1127 16.9171 62.0152 16.2534 62.6414 14.891C63.2675 13.5286 63.3046 11.2301 62.6414 9.48346C61.9782 7.73684 60.5352 5.73871 56.8298 4.16675L56.1882 5.63391C59.8241 7.17094 60.9429 8.88263 61.4864 10.4197C62.2169 12.4855 61.5893 14.0177 60.7407 15.0447C59.892 16.0717 58.0589 16.428 56.3104 16.5328C54.562 16.6376 12.3546 16.5328 11.3494 16.5328C10.4329 16.5328 10.365 15.7643 10.5952 14.9609Z"
                fill="#005AF7"/>
            <path
                d="M43.9633 6.29995C43.9628 6.2768 43.9206 6.27169 43.9148 6.29408C43.7204 7.03365 43.1623 7.12346 43.1006 7.6464C43.0624 7.96958 43.338 8.26854 43.701 8.31244C44.064 8.35633 44.4014 8.1314 44.4396 7.80832C44.5013 7.28538 43.98 7.06505 43.9633 6.29995ZM43.7631 7.11934C43.7533 7.14167 43.7429 7.16457 43.732 7.18838C43.6663 7.33333 43.5917 7.49771 43.5684 7.69535C43.5557 7.80248 43.4798 7.83183 43.4131 7.82377C43.3948 7.82149 43.3772 7.81607 43.3611 7.80779C43.345 7.79952 43.3309 7.78856 43.3194 7.77555C43.3079 7.76254 43.2994 7.74772 43.2942 7.73195C43.2891 7.71618 43.2875 7.69976 43.2894 7.68363C43.317 7.45 43.462 7.3127 43.59 7.19172C43.6303 7.15385 43.6681 7.11795 43.6997 7.0815C43.7141 7.06478 43.7472 7.06963 43.7601 7.08945C43.7661 7.09877 43.7673 7.10986 43.7631 7.11934Z"
                fill="#005AF7"/>
        </svg>
    );
}

export default BlueLogoSvg;