import React from 'react';

function BackArrowSvg(props) {
    return (
        <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="4.57143" fill="#6E758E"/>
            <path d="M15.0531 16L19.7731 11.28C20.0214 11.0302 20.1608 10.6922 20.1608 10.34C20.1608 9.98775 20.0214 9.64981 19.7731 9.4C19.6491 9.27503 19.5017 9.17583 19.3392 9.10814C19.1767 9.04045 19.0024 9.0056 18.8264 9.0056C18.6504 9.0056 18.4761 9.04045 18.3137 9.10814C18.1512 9.17583 18.0037 9.27503 17.8798 9.4L12.2264 15.0533C12.1015 15.1773 12.0023 15.3248 11.9346 15.4872C11.8669 15.6497 11.832 15.824 11.832 16C11.832 16.176 11.8669 16.3503 11.9346 16.5128C12.0023 16.6752 12.1015 16.8227 12.2264 16.9467L17.8798 22.6667C18.0043 22.7902 18.1521 22.888 18.3146 22.9544C18.477 23.0207 18.651 23.0543 18.8264 23.0533C19.0019 23.0543 19.1759 23.0207 19.3383 22.9544C19.5008 22.888 19.6485 22.7902 19.7731 22.6667C20.0214 22.4168 20.1608 22.0789 20.1608 21.7267C20.1608 21.3744 20.0214 21.0365 19.7731 20.7867L15.0531 16Z" fill="white"/>
        </svg>
    );
}

export default BackArrowSvg;