const palette = {
    color1: "#25292D",
    color2: "#f5f5fa",
    color3: "#EFEFEF",
    color4: "#0D6BFF",
    color5: "#000000",
    color6: "#0400D9",
    color7: "#FFFFFF",
    color8: "#989898",
    color9: "#E7EDFB",
    color10: "#004AD9",
    color11: "#7B7B7B",
    color12: "#AAFAC5",
    color13: "#50421b",
    color14: "#ffc8c8",
    color15: "#00950F",
    color16: "#ff8383",
    color17: "#8C96B4",
    color18: "#767676",
    color19: "#BBD0F0",
    color20: "#FFEBEB",
    color21: "#4B4B4B",
    color22: "#4E4E4E",
    color23: "#F0F0F0",
    color24: "#D2D6FB",
    color25: "#2B3F6C",
    color26: "#0B0B0B",
    color27: "#299D00",
    color28: "#FFFAEE",
    color29: "#F18A11",
    color30: "#E82121",
    color31: "#ECF3FF",
    color32: "#79AEFF",
    color33: "#FFD584",
    color34: "#956200"
}

export const light_theme = {
    name: "light_theme",
    background: palette.color1,
    login: {
        background: palette.color2,
        support_color: palette.color10,
        support_color2: palette.color4,
        link_color: palette.color4,
        color: palette.color5,
        borderColor: palette.color19,
        boxShadow: "5px 10px 15px rgba(145, 145, 145, 0.25)"
    },
    form: {
        input: {
            background: palette.color7,
            color: palette.color5,
            boxShadow: "5px 10px 15px rgba(145, 145, 145, 0.25)"
        },
        button: {
            background: palette.color10,
            color: palette.color7
        }
    },
    layout: {
        content: {
            background: "linear-gradient(174.6deg, #F2F2FB -7.13%, #E3E3EE 48.63%, #F2F2FB 98.88%)"
        }
    },
    tridot: {
        color: palette.color7
    },
    menu: {
        background: palette.color1,
        color: palette.color8,
        active_color: "#64BDFF"
    },
    dashboard: {
        color: palette.color5
    },
    dashboard_header_content: {
        color: palette.color7
    },
    capsule: {
        background: palette.color2,
        color: palette.color5,
        redBackground: palette.color20,
        yellowBackground: palette.color33,
        greenBackground: palette.color12,
        greenColor: palette.color15,
        redColor: palette.color30,
        yellowColor: palette.color34
    },
    header: {
        color: palette.color5
    },
    fuel_level: {
        boxShadow: "2px 3px 9px #929292"
    },
    cash: {
        titleColor: palette.color5,
        capsuleBackground: palette.color9
    },
    textslider: {
        background: palette.color9,
        color: palette.color4,
        arrowColor: palette.color5
    },
    titled_date_picker: {
        color: palette.color5,
        activeColor: palette.color6
    },
    mainmenu: {
        background: "linear-gradient(174.6deg, #F2F2FB -7.13%, #E3E3EE 48.63%, #F2F2FB 98.88%)",
        item_background: "#F5F5FA",
        item_color: palette.color5,
        active_color: palette.color10,
        boxShadow: "-15px -15px 26px #FFFFFF, 10px 15px 17px rgba(145, 145, 145, 0.25)"
    },
    vouchers: {
        modalBorderColor: palette.color32,
        modalBackground: palette.color7,
        modalColor: palette.color26,
        modalInputBg: palette.color31,
        svgBackground: palette.color24,
        svgFill: palette.color25,
        additionalColor: palette.color26,
        greenColor: palette.color27,
        redColor: palette.color30
    },
    terminals: {
        background: palette.color2,
        terminal_background: palette.color1,
        boxShadow: "5px 10px 15px rgba(0, 0, 0, 0.69)",
        color: palette.color5
    },
    round_block: {
        background: palette.color7,
        capsuleBackground: palette.color2,
        color: palette.color5,
        boxShadow: "5px 10px 15px rgba(145, 145, 145, 0.25)"
    },
    progress_line: {
        background: "#EDEFF5",
        boxShadow: "2px 3px 7px rgba(0, 0, 0, 0.25)",
        backgroundInner: "linear-gradient(90deg, #83BBFF 0%, #357BF5 57.91%)",
        backgroundRedInner: "linear-gradient(90deg,#ff8383 0%,#ff5858 57.91%)",
        status_color: palette.color11,
        active_color: palette.color6,
        active_red_color: palette.color16,
        title_color: palette.color5
    },
    reports: {
        areaTextColor: palette.color5,
        count_color: palette.color6,
        tableColor: palette.color21
    },
    select: {
        color: palette.color5
    },
    options: {
        color: palette.color5,
        buttonBackground: palette.color7,
        buttonColor: palette.color5
    },
    burger: {
        color: palette.color5,
        background: palette.color7,
        border: "0.5px solid #939393",
        additional_color: palette.color17,
        boxShadow: "none",
    },
    terminalList: {
        accentColor1: palette.color7,
        accentColor2: palette.color2,
        textColor: palette.color5,
        greenColor: palette.color15,
        greenBackground: palette.color12,
        yellowBackground: palette.color33,
        yellowColor: palette.color34,
        redBackground: palette.color14,
        h2Color: palette.color17,
        errorColor: palette.color18,
    },
    journal: {
        blockColor: palette.color22
    },
    settings: {
        background: palette.color7,
        inputBackground: palette.color2,
        inputColor: palette.color18,
        borderColor: palette.color23,
        blockColor: palette.color22
    },
    status: {
        blockBackground: palette.color7,
        innerBlockBackground: palette.color2,
        innerBlockColor: palette.color26
    }
}