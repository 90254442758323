import React from 'react';
import styled, {css} from "styled-components";
import {Form} from "antd";
import store from "../redux/store";
import {signupAction} from "../redux/actions/loginActions";
import Input from "../components/Form/Input";
import {Button} from "../components/Form/Button";
import Logo from "../components/Nav/Logo";
import {Link} from "react-router-dom";


const Signin = styled(Link)(({theme: {login}}) => css`
  color: ${login.color};
`);

const StyledRegistration = styled.div(({theme: {login}}) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${login.background};
  height: 100%;
  padding: 36px 16px 28px 16px;

  .ant-form {
    width: 100%;
  }
`);


function Registration() {

    return (
        <StyledRegistration>
            <Logo/>
            <Form layout={"vertical"}
                  onFinish={({name, login, password}) => store.dispatch(signupAction({name, login, password}))}>
                <Form.Item name={"name"}>
                    <Input bordered={false} placeholder={"Имя"}/>
                </Form.Item>
                <Form.Item name={"login"}>
                    <Input bordered={false} placeholder={"Логин"}/>
                </Form.Item>
                <Form.Item name={"password"}>
                    <Input.Password bordered={false} placeholder={"Пароль"}/>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={"submit"}>Регистрация</Button>
                </Form.Item>
            </Form>
            <Signin to={"login"}>
                Войти
            </Signin>
        </StyledRegistration>
    );
}

export default Registration;