import React from 'react';
import styled, {css} from "styled-components";
import BackArrowSvg from "../../images/BackArrowSVG";
import history from "../../utils/history";

const Content = styled.div(({theme: {header}}) => css`
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  color: ${header.color};
  overflow: hidden;
`);

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 16px;
  svg{
    flex: 1 0 auto;
  }
`;

type HeaderProps = {
    children?: any,
    back?: any
}

function Header({back, children}: HeaderProps) {
    return (
        <StyledHeader>
            {back !== undefined && <BackArrowSvg onClick={() => history.push(back)}/>}
            <Content>
                {children}
            </Content>
        </StyledHeader>
    );
}

export default Header;