import React from 'react';
import styled, {css} from "styled-components";

const StyledVoucherSvg = styled.svg(({theme: {vouchers}}) => css`
  circle {
    fill: ${vouchers.svgBackground};
  }

  path {
    fill: ${vouchers.svgFill};
  }
`);

function VoucherSvg(props: any) {
    return (
        <StyledVoucherSvg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#111535"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18 15.2491C15.3766 15.2491 13.25 17.3758 13.25 19.9991L13.25 21.2222C13.25 21.7877 13.6443 22.1971 14.0708 22.3441C14.758 22.5811 15.25 23.2336 15.25 23.9991C15.25 24.7646 14.758 25.4172 14.0708 25.6541C13.6443 25.8012 13.25 26.2105 13.25 26.7761L13.25 27.9991C13.25 30.6225 15.3766 32.7491 18 32.7491H30C32.6234 32.7491 34.75 30.6225 34.75 27.9991V26.7761C34.75 26.2105 34.3557 25.8012 33.9292 25.6541C33.242 25.4172 32.75 24.7646 32.75 23.9991C32.75 23.2336 33.242 22.5811 33.9292 22.3441C34.3557 22.1971 34.75 21.7877 34.75 21.2222L34.75 19.9991C34.75 17.3758 32.6233 15.2491 30 15.2491L18 15.2491ZM21.2386 22.3575C21.5477 22.6666 22.0489 22.6666 22.358 22.3575C22.6671 22.0484 22.6671 21.5472 22.358 21.2381C22.0489 20.929 21.5477 20.929 21.2386 21.2381C20.9295 21.5472 20.9295 22.0484 21.2386 22.3575ZM26.7609 26.7603C26.4518 27.0694 25.9506 27.0694 25.6415 26.7603C25.3324 26.4511 25.3324 25.95 25.6415 25.6409C25.9506 25.3317 26.4518 25.3317 26.7609 25.6409C27.07 25.95 27.07 26.4511 26.7609 26.7603ZM26.7688 22.2908C27.0617 21.9979 27.0617 21.523 26.7688 21.2301C26.4759 20.9372 26.001 20.9372 25.7081 21.2301L21.2305 25.7077C20.9376 26.0006 20.9376 26.4755 21.2305 26.7684C21.5234 27.0613 21.9983 27.0613 22.2912 26.7684L26.7688 22.2908Z"
                  fill="#9CBDF0"/>
        </StyledVoucherSvg>
    );
}

export default VoucherSvg;