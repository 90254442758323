import React from 'react';

function ReportSvg(props) {
    return (
        <svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.25 6C0.25 2.82436 2.82436 0.25 6 0.25H16C19.1756 0.25 21.75 2.82436 21.75 6V16C21.75 19.1756 19.1756 21.75 16 21.75H6C2.82436 21.75 0.25 19.1756 0.25 16V6ZM6.25 16C6.25 16.4142 6.58579 16.75 7 16.75C7.41421 16.75 7.75 16.4142 7.75 16V13C7.75 12.5858 7.41421 12.25 7 12.25C6.58579 12.25 6.25 12.5858 6.25 13L6.25 16ZM11 16.75C10.5858 16.75 10.25 16.4142 10.25 16L10.25 6C10.25 5.58579 10.5858 5.25 11 5.25C11.4142 5.25 11.75 5.58579 11.75 6V16C11.75 16.4142 11.4142 16.75 11 16.75ZM14.25 16C14.25 16.4142 14.5858 16.75 15 16.75C15.4142 16.75 15.75 16.4142 15.75 16V9C15.75 8.58579 15.4142 8.25 15 8.25C14.5858 8.25 14.25 8.58579 14.25 9L14.25 16Z"
                  fill="#9CABC0"/>
        </svg>
    );
}

export default ReportSvg;