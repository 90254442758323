import React from 'react';

function PhoneSvg(props:any) {
    return (
        <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.68766 7.31501C9.34705 9.97366 9.95035 6.89789 11.6436 8.58996C13.276 10.2219 14.2143 10.5489 12.146 12.6166C11.8869 12.8248 10.2409 15.3296 4.45624 9.54653C-1.32915 3.76275 1.17423 2.11504 1.38248 1.85605C3.45575 -0.217355 3.77708 0.726337 5.4095 2.3583C7.10275 4.05108 4.02828 4.65636 6.68766 7.31501Z"
                  stroke="#64BDFF" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default PhoneSvg;