export const initialState = {
    loginReducer: {
        loading: true,
        userData: {},
    },
    pageReducer: {
        stations: [],
        loading: true,
        theme: localStorage.getItem("azs_theme") || "dark_theme"
    }
}