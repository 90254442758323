import React, {useEffect, useMemo, useState} from 'react';
import styled, {css} from "styled-components";
import Burger, {BurgerItem, StyledBurger} from "../components/DataView/Burger";
import Capsule from "../components/DataView/Capsule";
import {BurgerAdditional} from "../components/DataView/BurgerAdditional";
import StorageLineSvg from "../images/StorageLineSVG";
import FuelLevel from "../components/DataView/FuelLevel";
import {connect} from "react-redux";
import moment from "moment";
import TextSlider from "../components/DataView/TextSlider";
import * as _ from "lodash";
import {ruCurrencyFormat} from "../utils/ruCurrencyFormat";

const Block = styled.div(({theme: {dashboard}}) => css`
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-bottom: 37px;
    }

    h1 {
        color: ${dashboard.color};
        margin-bottom: 15px;
        font-weight: bold;
        font-size: 14px;
    }
`);

const VolumeValue = styled.div(({theme: {capsule, textslider}}) => css`
    display: flex;
    background: ${capsule.background};
    border-radius: 37px;
    padding: 5px 17px;
    color: ${capsule.color};
    font-size: 12px;
    font-weight: 600;
    margin-left: auto;

    &.price {
        background: ${textslider.background};
        margin: 6px auto 0 auto;
        width: auto;
        font-weight: 700;

        span {
            color: ${textslider.color};
            font-weight: 700;
            margin-left: 5px;
        }
    }
`);

const CapacityGroup = styled.div(({theme: {dashboard}}) => css`
    display: flex;

    ${StyledBurger} {
        width: 100%;
        margin-right: 34px;

        svg {
            border-radius: 37px;
        }
    }

`);

const VolumeGroup = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 600;

    svg {
        margin-right: 7px;
        flex: 0 0 auto;
    }
;
`;
const StyledDashboard = styled.div`
    .line0 {
        path {
            stroke: #36CF00;
        }
    }

    .line1 {
        box-shadow: 0 0 20px #979797;

        path {
            stroke: #2E3448;
        }
    }

    .line2 {
        path {
            stroke: #78C6FF;
        }
    }

    .line3 {
        path {
            stroke: #850000;
        }
    }
`;

type DashboardProps = {
    station?: any
}

function Dashboard({station}: DashboardProps) {

    const Volumes = useMemo(() => {
        return _.sortBy(station.Status?.Volumes.map((el: any, index: number) => ({...el, id: index})), "Fuel")
    }, [station])

    const [selectedVolume, setSelectedVolume] = useState<any>(Volumes?.[0]);

    const fuels = useMemo(() => {
        return Volumes.map(el => ({id: el.id, title: el.Fuel}))
    }, [Volumes])

    if (station?.Status === undefined) {
        return (<div>
            У вас не выбрана станция
        </div>)
    }

    return (
        <StyledDashboard>
            <Block>
                <h1>СОСТОЯНИЕ</h1>
                <Burger>
                    <BurgerItem>Статус<br/> терминала:
                        <Capsule
                            className={station.Blocked ? "red" : (station.Status.Online ? (station.Status.Locked ? "yellow" : "green") : "red")}>
                            <span>{station.Blocked ? "Черный список" : (station.Status.Online ? (station.Status.Locked ? "Заблокирован" : "Онлайн") : "Оффлайн")}</span>
                        </Capsule>
                        <BurgerAdditional>{moment(station.Status?.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional></BurgerItem>
                    <BurgerItem>Статус<br/> оборудования: <Capsule
                        className={station?.Status?.Devices?.every((el: any) => el.Ok === true) ? "green" : station?.Status?.Devices?.every((el: any) => el.Ok === false) ? "red" : "yellow"}>{station?.Status?.Devices?.every((el: any) => el.Ok === true) ?
                        <span>Отлично</span> : station?.Status?.Devices?.every((el: any) => el.Ok === false) ?
                            <span>Ошибка</span> : <span>Внимание</span>}</Capsule>
                        <BurgerAdditional>{moment(station.Status?.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional></BurgerItem>
                    {station.Status?.LastMessage &&
                        <BurgerItem>Последняя операция: <Capsule>{station.Status?.LastMessage}</Capsule>
                            <BurgerAdditional>{moment(station.Status?.LastMessageDate).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                        </BurgerItem>}
                    {station.Status?.LastError &&
                        <BurgerItem>Последняя ошибка: <Capsule>{station.Status?.LastError}</Capsule>
                            <BurgerAdditional>{moment(station.Status?.LastErrorDate).format("DD.MM.YYYY HH:mm")}</BurgerAdditional>
                        </BurgerItem>}

                </Burger>
            </Block>

            <Block>
                <h1>ДВИЖЕНИЕ ДЕНЕГ</h1>
                <Burger>
                    <BurgerItem>Итого
                        за<br/> смену: <Capsule>{ruCurrencyFormat(station.Status?.TotalShift)} ₽</Capsule>
                        <BurgerAdditional>{moment(station.Status?.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional></BurgerItem>
                    <BurgerItem>Безналичный<br/>расчет <Capsule>{ruCurrencyFormat(station.Status?.TotalCard)} ₽</Capsule>
                        <BurgerAdditional>{moment(station.Status?.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional></BurgerItem>
                    <BurgerItem>Сумма
                        в<br/> кассете: <Capsule>{ruCurrencyFormat(station.Status?.TotalCash)} ₽</Capsule>
                        <BurgerAdditional>{moment(station.Status?.Updated).format("DD.MM.YYYY HH:mm")}</BurgerAdditional></BurgerItem>
                    <BurgerItem>Последняя<br/> инкассация: <Capsule>{ruCurrencyFormat(station.Status?.LastCheckout)} ₽</Capsule>
                        <BurgerAdditional>{moment(station.Status?.LastCheckoutDate).format("DD.MM.YYYY HH:mm")}</BurgerAdditional></BurgerItem>
                </Burger>
            </Block>

            {selectedVolume !== undefined && <Block>
                <TextSlider value={selectedVolume.id}
                            onChange={value => {
                                setSelectedVolume(Volumes?.find((el: any) => el.id === value))
                            }}
                            values={fuels}/>
                <VolumeValue
                    className={"price"}>ЦЕНА: <span>{ruCurrencyFormat(selectedVolume.Price)} ₽/л.</span></VolumeValue>
            </Block>}

            {selectedVolume !== undefined && <Block>
                <h1>ЕМКОСТЬ</h1>
                <CapacityGroup>
                    <Burger>
                        <BurgerItem><VolumeGroup><StorageLineSvg className={"line0"}/> Общий
                            объем <VolumeValue>{Math.round(selectedVolume.Volume)} л.</VolumeValue></VolumeGroup></BurgerItem>
                        <BurgerItem><VolumeGroup><StorageLineSvg className={"line1"}/> К
                            приему <VolumeValue>{Math.round(selectedVolume.Volume) - Math.round(selectedVolume.VolumeOut) - Math.round(selectedVolume.VolumeMin)} л.</VolumeValue></VolumeGroup></BurgerItem>
                        <BurgerItem><VolumeGroup><StorageLineSvg className={"line2"}/> К
                            отпуску <VolumeValue>{Math.round(selectedVolume.VolumeOut)} л.</VolumeValue></VolumeGroup></BurgerItem>
                        <BurgerItem><VolumeGroup><StorageLineSvg className={"line3"}/> Мертвый
                            остаток <VolumeValue>{Math.round(selectedVolume.VolumeMin)} л.</VolumeValue></VolumeGroup></BurgerItem>
                    </Burger>

                    <FuelLevel volume={Math.round(selectedVolume.Volume)}
                               values={[Math.round(selectedVolume.VolumeOut), Math.round(selectedVolume.VolumeMin)]}/>
                </CapacityGroup>
            </Block>}
        </StyledDashboard>
    )
};

const mapStateToProps = (store: any) => {
    return {
        station: store.pageReducer.stations.find((el: any) => el.Id === store.pageReducer.selectedStation)
    }
}

export default connect(mapStateToProps, null)(Dashboard);