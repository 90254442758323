import React from 'react';
import styled, {css} from "styled-components";

const StyledMainMenuSvg = styled.svg(({theme}: any) => css`
  circle {
    fill: ${theme.name === "light_theme" ? "#D5DFFF" : "#131723"};
  }
`);

function MainMenuSvg(props:any) {
    return (
        <StyledMainMenuSvg {...props} width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="42" cy="42" r="42" fill="#D5DFFF"/>
            <path d="M25.375 40.1422C25.375 37.9999 26.356 35.9755 28.0375 34.648L37.6625 27.0493C40.2057 25.0416 43.7943 25.0416 46.3375 27.0494L55.9625 34.648C57.644 35.9755 58.625 37.9999 58.625 40.1422V51.625C58.625 55.491 55.491 58.625 51.625 58.625H49C48.0335 58.625 47.25 57.8415 47.25 56.875V51.625C47.25 49.692 45.683 48.125 43.75 48.125H40.25C38.317 48.125 36.75 49.692 36.75 51.625V56.875C36.75 57.8415 35.9665 58.625 35 58.625H32.375C28.509 58.625 25.375 55.491 25.375 51.625L25.375 40.1422Z" fill="#6A738F" stroke="#6A738F" strokeWidth="2.625"/>
        </StyledMainMenuSvg>
    );
}

export default MainMenuSvg;