import React from 'react';
import styled, {css} from "styled-components";
import {Form} from "antd";
import Input from "../components/Form/Input";
import {Button} from "../components/Form/Button";
import store from "../redux/store";
import {loginAction} from "../redux/actions/loginActions";
import Logo from "../components/Nav/Logo";
import {Link} from "react-router-dom";
import PhoneSvg from "../images/PhoneSvg";

const Signup = styled(Link)(({theme}) => css`
  color: ${theme.login.color};
`);

const Support = styled.div(({theme}) => css`
  color: ${theme.login.support_color};
  font-size: 13px;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: ${theme.login.link_color};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 14px;
    border: 1px solid ${theme.login.borderColor};
    border-radius: 4px;
    svg{
      margin-right: 8px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
      color: ${theme.login.support_color2}
    }
  }
`);

const StyledLoginPage = styled.div(({theme}) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.login.background};
  min-height: 100%;
  padding: 36px 16px 28px 16px;

  .ant-form {
    width: 100%;
  }
`);

function LoginPage() {
    return (
        <StyledLoginPage>
            <Logo/>
            <Form layout={"vertical"} onFinish={({login, password}) => store.dispatch(loginAction({login, password}))}>
                <Form.Item name={"login"}>
                    <Input bordered={false} placeholder={"Логин"}/>
                </Form.Item>
                <Form.Item name={"password"}>
                    <Input.Password bordered={false} placeholder={"Пароль"}/>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={"submit"}>Войти</Button>
                </Form.Item>
            </Form>
            <Signup to={"signup"}>
                Регистрация
            </Signup>
            <Support>
                <a href="tel:+73512002225">Техническая поддержка</a>
                <a href="tel:+73512002225"><PhoneSvg/> 8 (351) 200-22-25</a>
            </Support>
        </StyledLoginPage>
    );
}

export default LoginPage;